import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import allActions from '../../../redux/actions';
import MyDrawer from './DrawerMain';
import SettingsDrawer from './DrawerSettings';
import MyToolbar from './Toolbar';
import Loader from '../Loader';
import './ToolbarAndDrawer.css';
import { authClient } from '../../../auth';
import listItemsImported from './ListItems';
import { BrowserView, isBrowser, isMobileOnly } from 'react-device-detect';
import Alert from '@material-ui/lab/Alert';
import { Popover } from '@material-ui/core';

const history = createBrowserHistory();

const checkAtleastOneFav = favTabs => {
  if (
    favTabs.filter(item => item.category !== 3).length - 1 === 1 &&
    isBrowser
  ) {
    return false;
  }
  if (
    favTabs.filter(item => item.category !== 2 && item.category !== 5).length -
      1 ===
      1 &&
    isMobileOnly
  ) {
    return false;
  }
  return true;
};

const checkMaxThreeForMobile = (favTabs, changeFav) => {
  if (
    favTabs.filter(item => item.category !== 2 && item.category !== 5).length -
      1 ===
      3 &&
    isMobileOnly &&
    changeFav
  ) {
    return false;
  }
  return true;
};

const getRole = roles => {
  const rolesPriority = [
    'CBIS Admin',
    'CBIS Manager',
    'CBIS Coordinator',
    'CBIS Supervisor',
    'CBIS Buyer',
  ];
  const role = roles.sort(
    (a, b) =>
      rolesPriority.findIndex(el => el === a) -
      rolesPriority.findIndex(el => el === b),
  )[0];
  return role;
};

function AppBarInteraction({ classes, variant }) {
  const currentSetting = useSelector(state => state.currentSetting);
  const utils = useSelector(state => state.utils);
  const [drawer, setDrawer] = useState(false);
  const [settingsDrawer, setSettingsDrawer] = useState(false);
  const [title, setTitle] = useState('Home');
  const [showAlert, setshowAlert] = useState(true);
  const [userName, setUserName] = useState(null);
  const dispatch = useDispatch();
  const [listItems, setListItems] = useState(listItemsImported);
  const oktaToken = 'okta-token-storage';
  const userRole = getRole(
    JSON.parse(window.localStorage.getItem(oktaToken)).idToken.claims
      .Cattle_Buying_Information_System,
  );
  const [atleastOneError, setAtleastOneError] = useState(false);
  const [maxThreeTabsWarning, setMaxThreeTabsWarning] = useState(false);

  const handleClick = (obj, action, page, changeFav) => {
    const favorites = JSON.parse(localStorage.getItem('favorites'));
    const favTabs = favorites.filter(
      ele =>
        ele.user.toLowerCase() ===
        JSON.parse(
          window.localStorage.getItem(oktaToken),
        ).idToken.claims.email.toLowerCase(),
    )[0].favTabs;
    const temp = [...listItems];
    temp.map(ele => {
      if (ele.id === obj.id) {
        if (action === 'expansion') {
          ele.expanded = !ele.expanded;
        } else if (action === 'star') {
          if (ele.addedToFavorites) {
            if (checkAtleastOneFav(favTabs)) {
              ele.addedToFavorites = !ele.addedToFavorites;
              //change favorites when clicked
              changeFav &&
                changeFavorites(ele.addedToFavorites, 'star', ele.id);
            } else {
              setAtleastOneError(true);
              setTimeout(() => {
                setAtleastOneError(false);
              }, 3000);
            }
          } else {
            if (checkMaxThreeForMobile(favTabs, changeFav)) {
              ele.addedToFavorites = !ele.addedToFavorites;
              //change favorites when clicked
              changeFav &&
                changeFavorites(ele.addedToFavorites, 'star', ele.id);
            } else {
              setMaxThreeTabsWarning(true);
              setTimeout(() => {
                setMaxThreeTabsWarning(false);
              }, 3000);
            }
          }
        } else if (action === 'starPage') {
          const arr = ele.pages.filter(element => element.id === page.id);
          if (arr[0].addedToFavorites) {
            if (checkAtleastOneFav(favTabs)) {
              arr[0].addedToFavorites = !arr[0].addedToFavorites;
              changeFav &&
                changeFavorites(
                  arr[0].addedToFavorites,
                  'starPage',
                  ele.id,
                  arr[0].id,
                );
            } else {
              setAtleastOneError(true);
              setTimeout(() => {
                setAtleastOneError(false);
              }, 3000);
            }
          } else {
            if (checkMaxThreeForMobile(favTabs, changeFav)) {
              arr[0].addedToFavorites = !arr[0].addedToFavorites;
              changeFav &&
                changeFavorites(
                  arr[0].addedToFavorites,
                  'starPage',
                  ele.id,
                  arr[0].id,
                );
            } else {
              setMaxThreeTabsWarning(true);
              setTimeout(() => {
                setMaxThreeTabsWarning(false);
              }, 3000);
            }
          }
          // Additional check if any of the pages is already added to favorites then main category should be added to favorites else should be removed
          const favPages = ele.pages.filter(
            element => element.addedToFavorites === true,
          );
          if (favPages.length > 0) {
            ele.addedToFavorites = true;
          } else {
            ele.addedToFavorites = false;
            changeFav && changeFavorites(ele.addedToFavorites, 'star', ele.id);
          }
        }
      }
      return true;
    });
    setListItems(temp);
  };

  const changeFavorites = (action, type, catId, subCatId) => {
    const favorites = JSON.parse(localStorage.getItem('favorites'));
    if (
      favorites.some(
        ele =>
          ele.user.toLowerCase() ===
          JSON.parse(
            window.localStorage.getItem(oktaToken),
          ).idToken.claims.email.toLowerCase(),
      )
    ) {
      const favTabArr = favorites.filter(
        ele =>
          ele.user.toLowerCase() ===
          JSON.parse(
            window.localStorage.getItem(oktaToken),
          ).idToken.claims.email.toLowerCase(),
      )[0].favTabs;
      if (action) {
        if (type === 'star') {
          favTabArr.push({ category: catId });
        }
        if (type === 'starPage') {
          if (favTabArr.some(ele => ele.category === catId)) {
            favTabArr
              .filter(ele => ele.category === catId)[0]
              .subCategory.push(subCatId);
          } else {
            favTabArr.push({ category: catId, subCategory: [subCatId] });
          }
        }
      } else {
        if (type === 'star') {
          favTabArr.splice(
            favTabArr.findIndex(item => item.category === catId),
            1,
          );
        }
        if (type === 'starPage') {
          const subCatArr = favTabArr.filter(ele => ele.category === catId)[0]
            .subCategory;
          subCatArr.splice(
            subCatArr.findIndex(ele => ele === subCatId),
            1,
          );
        }
      }
      favorites.splice(
        favorites.findIndex(
          ele =>
            ele.user.toLowerCase() ===
            JSON.parse(
              window.localStorage.getItem(oktaToken),
            ).idToken.claims.email.toLowerCase(),
        ),
        1,
      );
      const favObj = {
        user: JSON.parse(window.localStorage.getItem(oktaToken)).idToken.claims
          .email,
        favTabs: [],
      };
      favObj.favTabs = favTabArr;
      favorites.push(favObj);
      localStorage.setItem('favorites', JSON.stringify(favorites));
    }
  };

  const toggleMainDrawer = () => {
    setDrawer(!drawer);
    dispatch(
      allActions.settingActions.setSetting({
        MainDrawerOpen: !drawer,
      }),
    );
  };

  const onItemClick = titled => () => {
    setTitle(titled);
    setDrawer(variant === 'temporary' ? false : drawer);
    if (titled === 'SETTING') {
      setSettingsDrawer(true);
    } else if (titled === 'MAIN') {
      setSettingsDrawer(false);
    } else {
      toggleMainDrawer();
    }
  };

  const onSettingsItemClick = titled => () => {
    setTitle('SETTINGS');
    setSettingsDrawer(variant === 'temporary' ? false : settingsDrawer);
    setSettingsDrawer(!settingsDrawer);
  };

  useEffect(() => {
    if (
      window.localStorage.getItem('redirect_url') == window.location.pathname
    ) {
      window.localStorage.removeItem('redirect_url');
    }
    authClient.token.getUserInfo().then(res => {
      setUserName(res.name);
    });
  }, []);

  useEffect(() => {
    dispatch(allActions.settingActions.getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (drawer !== currentSetting.MainDrawerOpen) {
      setDrawer(!drawer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSetting.MainDrawerOpen]);

  useEffect(() => {
    if (drawer !== currentSetting.MainDrawerOpen) {
      dispatch(
        allActions.settingActions.setSetting({
          MainDrawerOpen: false,
        }),
      );
    }
    setHamburgerMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setHamburgerMenu = () => {
    try {
      const role = userRole;
      if (localStorage.getItem('favorites') === null) {
        const favorites = [];
        const favObj = {
          user: JSON.parse(window.localStorage.getItem(oktaToken)).idToken
            .claims.email,
          favTabs: [],
        };
        favObj.favTabs.push({ category: 1 }, { category: 4, subCategory: [1] });
        console.log(role.toLowerCase());
        if (
          role.toLowerCase().includes('admin') ||
          role.toLowerCase().includes('manager')
        ) {
          favObj.favTabs.push({ category: 5 });
        }
        favorites.push(favObj);
        localStorage.setItem('favorites', JSON.stringify(favorites));
        setHamburgerMenu();
      } else {
        const favorites = JSON.parse(localStorage.getItem('favorites'));
        if (
          JSON.parse(localStorage.getItem('favorites')).some(
            ele =>
              ele.user.toLowerCase() ===
              JSON.parse(
                window.localStorage.getItem(oktaToken),
              ).idToken.claims.email.toLowerCase(),
          )
        ) {
          const tabsList = favorites.filter(
            ele =>
              ele.user.toLowerCase() ===
              JSON.parse(
                window.localStorage.getItem(oktaToken),
              ).idToken.claims.email.toLowerCase(),
          )[0].favTabs;
          for (let i = 0; i < tabsList.length; i++) {
            listItems.map(listItem => {
              if (listItem.id === tabsList[i].category) {
                handleClick(listItem, 'star', null, false);
                if (
                  Object.prototype.hasOwnProperty.call(
                    tabsList[i],
                    'subCategory',
                  )
                ) {
                  if (tabsList[i].subCategory.length > 0) {
                    tabsList[i].subCategory.forEach(number =>
                      handleClick(
                        listItem,
                        'starPage',
                        listItem.pages[number - 1],
                        false,
                      ),
                    );
                  }
                }
              }
              return listItem;
            });
          }
        } else {
          const favObj = {
            user: JSON.parse(window.localStorage.getItem(oktaToken)).idToken
              .claims.email,
            favTabs: [],
          };
          favObj.favTabs.push(
            { category: 1 },
            { category: 4, subCategory: [1] },
          );
          if (
            role.toLowerCase().includes('admin') ||
            role.toLowerCase().includes('manager')
          ) {
            favObj.favTabs.push({ category: 5 });
          }
          favorites.push(favObj);
          localStorage.setItem('favorites', JSON.stringify(favorites));
          setHamburgerMenu();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAlert = show => {
    try {
      setshowAlert(show);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <BrowserView>
        <SweetAlert
          show={showAlert}
          type="info"
          showCancel={false}
          showConfirm={false}
          cancel
          title={'Please wait'}
          onConfirm={() => {
            console.log('confirmed');
          }}
        >
          Loading...
        </SweetAlert>
      </BrowserView>
      <Popover
        id={0}
        open={atleastOneError}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Alert severity="warning">You must have at least one favorite.</Alert>
      </Popover>
      <Popover
        id={0}
        open={maxThreeTabsWarning}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity="warning">You may only select three pages.</Alert>
      </Popover>
      <div className="app-bar">
        <Loader dispatch={dispatch} loader={utils}></Loader>
        <MyToolbar
          title={title}
          userName={userName}
          onMenuClick={() => toggleMainDrawer()}
        />
        <MyDrawer
          open={currentSetting.MainDrawerOpen}
          onClose={() => toggleMainDrawer()}
          onItemClick={onItemClick}
          variant={variant}
          history={history}
          role={userRole}
          handleAlert={handleAlert}
          handleClick={(obj, action, page, changeFav) =>
            handleClick(obj, action, page, changeFav)
          }
          listItems={listItems}
        />
        <SettingsDrawer
          open={settingsDrawer}
          onClose={() => setSettingsDrawer(!settingsDrawer)}
          onItemClick={onSettingsItemClick}
          variant={variant}
          linkFlags={currentSetting}
          dispatch={dispatch}
          history={history}
        />
      </div>
    </>
  );
}

export default AppBarInteraction;
