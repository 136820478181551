import React from 'react';

export default function CheckBox({
  id,
  type,
  key,
  name,
  handleClick,
  isChecked,
}) {
  return (
    <input
      id={id}
      key={key}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
    />
  );
}
