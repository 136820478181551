import React, { useState, useCallback, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { BrowserView } from 'react-device-detect';
import withAlert from '../components/Desktop/AdvPayment/withAlert';
import AdvPayWeekPicker from '../components/Desktop/AdvPayment/AdvPayWeekPicker';
import AdvPaySearchBox from '../components/Desktop/AdvPayment/SearchBox';
import AdvPayTurnInList from '../components/Desktop/AdvPayment/TurnInList';
import AdvPayTurnInDetails from '../components/Desktop/AdvPayment/TurnInDetails';
import ApproveButton from '../components/Desktop/AdvPayment/ApproveButton';
import { useStyles } from '../components/Desktop/AdvPayment/Styles';
import { getTurnInList } from '../services/api/advancedPayment';
import { getSaturday } from '../utils/helperFunctions';

export default withAlert(function AdvPayment(props) {
  const { showAlert, hideAlert } = props;
  const [arrivalWeek, setArrivalWeek] = useState(getSaturday());
  const [searchQuery, setSearchQuery] = useState('');
  const [turnIns, setTurnIns] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTab, selectTab] = useState(0);
  const classes = useStyles();
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const fetchData = useCallback(async () => {
    deselectAll();
    let data = [];
    try {
      showAlert('info', 'Please wait', 'Loading Turn Ins...', {
        showConfirm: false,
      });
      // yyyy-mm-dd format is required
      const dateString = formatDate(arrivalWeek);//arrivalWeek.toISOString().split('T')[0];
      const response = await getTurnInList(dateString);
      if (response.status === 200) {
        data = response.data;
        hideAlert();
      } else if (response.status === 202 || response.status === 204) {
        // There are no turn ins for the selected week ending.
        data = [];
        hideAlert();
      } else {
        // Something happened and the API request failed
        data = [];
        const errorMessage = 'An error occurred while getting turn ins';
        showAlert('danger', 'Error', errorMessage);
        console.error(errorMessage + ':', response);
      }
    } catch (err) {
      // Something happened and the API request failed
      data = [];
      const errorMessage = 'An error occurred while getting turn ins';
      showAlert('danger', 'Error', errorMessage);
      console.error(errorMessage + ':', err);
    } finally {
      setSelectedRow(null);
      setTurnIns(data);
    }

    // Disable this warning. Adding hideAlert/showAlert would cause an infinite
    // update loop, even though they do not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalWeek]);

  const handleChange = e => {
    setSearchQuery(e.target.value);
    deselectAll();
  };

  const deselectAll = () => {
    setIsCheck([]);
    setIsCheckAll(false);
    setSelectedRow(null);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <BrowserView viewClassName="browser-view">
      <Container maxWidth="xl" className={classes.advPayContainer}>
        <Grid container spacing={1}>
          <Grid container item spacing={1} xs={12}>
            <Grid item>
              <AdvPayWeekPicker
                selectedWeek={arrivalWeek}
                setWeek={setArrivalWeek}
              />
            </Grid>
            <Grid item>
              <AdvPaySearchBox
                value={searchQuery}
                onChange={e => handleChange(e)}
              />
            </Grid>
            <Grid item>
              <ApproveButton
                list={turnIns}
                setIsCheck={setIsCheck}
                isCheck={isCheck}
                showAlert={showAlert}
                hideAlert={hideAlert}
                setList={setTurnIns}
                deselectAll={deselectAll}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <AdvPayTurnInList
              list={turnIns}
              setList={setTurnIns}
              selectedTab={selectedTab}
              searchQuery={searchQuery}
              selectRow={setSelectedRow}
              selectTab={selectTab}
              fetchData={fetchData}
              showAlert={showAlert}
              hideAlert={hideAlert}
              isCheck={isCheck}
              setIsCheck={setIsCheck}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
              arrivalWeek={arrivalWeek}
              setArrivalWeek={setArrivalWeek}
              deselectAll={deselectAll}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <AdvPayTurnInDetails selectedItem={selectedRow} />
          </Grid>
        </Grid>
      </Container>
    </BrowserView>
  );
});
