import React from 'react';
import clsx from 'clsx';
import { Router } from 'react-router-dom';
import allActions from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import { Label } from 'reactstrap';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Divider } from '@material-ui/core';
import { settingsDrawerStyles as styles, compStyles as style } from './Styles';
import './ToolbarAndDrawer.css';

const SettingsDrawer = withStyles(styles)(
  ({
    classes,
    variant,
    open,
    onClose,
    onItemClick,
    linkFlags,
    dispatch,
    history,
  }) => (
    <Router history={history}>
      <Drawer
        variant={variant}
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          className={clsx({
            [classes.toolbarMargin]: variant === 'persistent',
          })}
        />
        <List style={style.list}>
          <ListItem style={style.settingsListItem}>
            <span
              className={'setting-back-arrow'}
              onClick={onItemClick('MAIN')}
            ></span>
            <Label style={style.settingsLabel}>SETTING</Label>
          </ListItem>
          <Label className={classes.homeTitle} style={style.homeTitle}>
            HOME SCREEN
          </Label>
          <div style={style.checkboxDiv}>
            <Label className={classes.drawerTitles}>1. Quick Links</Label>
            <input
              type="checkbox"
              id="dtn_insights"
              value="DTN Insights"
              className={classes.checkbox}
              style={{ marginTop: 10 }}
              checked={linkFlags.DTNInsights}
              onChange={event =>
                dispatch(
                  allActions.settingActions.setSetting({
                    DTNInsights: event.target.checked,
                  }),
                )
              }
            />
            <label for="vehicle1" className={classes.label}>
              DTN Insights
            </label>
            <br></br>
            <input
              type="checkbox"
              id="usda_reports"
              name="vehicle1"
              value="USDA Reports"
              className={classes.checkbox}
              checked={linkFlags.USDAReports}
              onChange={event =>
                dispatch(
                  allActions.settingActions.setSetting({
                    USDAReports: event.target.checked,
                  }),
                )
              }
            />
            <label for="vehicle1" className={classes.label}>
              USDA Reports
            </label>
            <br></br>
            <input
              type="checkbox"
              id="ag_news"
              name="vehicle1"
              value="Ag News"
              className={classes.checkbox}
              checked={linkFlags.AgNews}
              onChange={event =>
                dispatch(
                  allActions.settingActions.setSetting({
                    AgNews: event.target.checked,
                  }),
                )
              }
            />
            <label for="vehicle1" className={classes.label}>
              Ag News
            </label>
            <br></br>
            <input
              type="checkbox"
              id="weather"
              name="vehicle1"
              value="Weather"
              className={classes.checkbox}
              checked={linkFlags.Weather}
              onChange={event =>
                dispatch(
                  allActions.settingActions.setSetting({
                    Weather: event.target.checked,
                  }),
                )
              }
            />
            <label for="vehicle1" className={classes.label}>
              Weather
            </label>
            <br></br>
            <input
              type="checkbox"
              id="upcoming"
              name="vehicle1"
              value="Upcoming"
              className={classes.checkbox}
              checked={linkFlags.Upcoming}
              onChange={event =>
                dispatch(
                  allActions.settingActions.setSetting({
                    Upcoming: event.target.checked,
                  }),
                )
              }
            />
            <label for="vehicle1" className={classes.label}>
              Upcoming
            </label>
            <br></br>
            <Label className={classes.drawerTitles} style={{ marginTop: 10 }}>
              2. Set Weather Location
            </Label>
            <form>
              <input
                type="text"
                className={'search-in-drawer'}
                placeholder="Search for a City"
                name="search"
                style={{ textAlign: 'center', marginTop: 2, width: '80%' }}
              />
            </form>
          </div>
        </List>
        <div style={style.footerDivider}>
          <Divider style={style.dividerLine} />
          <Label style={style.footerLabel}>Tyson CBIS Version 2.0.1</Label>
        </div>
      </Drawer>
    </Router>
  ),
);

export default withStyles(styles)(SettingsDrawer);
