import React, { useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import ContractsSubSectionTitle from './ContractsGroupTitle';
import {
  getAdditionalInfo,
  getSpecsInfo,
} from '../../../services/api/contractManagement';
import { Grid, TextareaAutosize } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import trimObject from '../../../utils/trimObject';
import { AdditionalInfoStyles as styles } from './Styles';
import './Contracts.css';
import { setAccessRole } from '../../../redux/actions/accessRole';
import { connect } from 'react-redux';

const AdditionalInfo = props => {
  const [alternateBuyer, setAlternateBuyer] = useState('');
  const [primProdEmail, setPrimProdEmail] = useState('');
  const [signatureMethod, setSignatureMethod] = useState(props.signatureMethod);
  const regularText = 'text-regular';
  const [contractSpecs, setContractSpecs] = useState([]);
  const [specStatus, setSpecStatus] = useState('Loading...');
  const contractTypes = [
    { type: 'S', name: 'STEER/HEIFER' },
    { type: 'CM', name: 'CARCASS MERIT' },
    { type: 'N', name: 'NATURAL' },
    { type: 'NH', name: 'NHTC' },
  ];

  useEffect(() => {
    contractAdditonalInfo(props.selectedContractor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function contractAdditonalInfo(contractNumber) {
    if (typeof contractNumber === 'string') {
      const dataInfo = await getAdditionalInfo(contractNumber);
      if (dataInfo.length > 0) {
        dataInfo.forEach(record => {
          const parsedData = trimObject(record);
          contractSpecsInfo(parsedData.CONTRACT_TYPE); //get contract spec based on its type

          if (parsedData.signatureMethod) {
            setSignatureMethod(parsedData.signatureMethod);
          }
          if (parsedData.PRIM_PROD_EMAIL) {
            props.setDisableElectronic(false);
          } else {
            setSignatureMethod('M');
            props.setDisableElectronic(true);
          }
          parsedData.currentUser = 1234; //(current buyerNumber from buyer dropdown)
          parsedData.comments = parsedData.SPEC_COMMENT;
          parsedData.contractSpecs = contractSpecs;
          parsedData.email = '';

          const currSpecOptions = props.specOptions;
          currSpecOptions[
            parseInt(parsedData.OPTION_TYPE, 36) - 10 // gets numeric value of letter ex. A=0, B=1
          ].optionChkd = true;
          currSpecOptions[parseInt(parsedData.OPTION_TYPE, 36) - 10].value =
            parsedData.OPTION_VALUE;
          parsedData.contractSpecs = currSpecOptions;
          parsedData.contractType = parsedData.CONTRACT_TYPE;
          props.setSpecOptions(currSpecOptions);
          props.setDetails(dataInfo);
          setPrimProdEmail(parsedData.PRIM_PROD_EMAIL);
        });
      } else {
        setSpecStatus('No data found');
      }
    }
  }
  async function contractSpecsInfo(contractType) {
    let isOld = false;
    if (props.value === 3) {
      // this is set to true when on the Completed tab
      isOld = true;
    }
    if (typeof contractType === 'string') {
      const res = await getSpecsInfo(contractType.trim(), isOld);
      if (
        res.status === 200 &&
        Array.isArray(res.data) &&
        res.data.length > 0
      ) {
        setSpecStatus('success');
        const arr = res.data;
        //adding an object of contract type at the start of the array to display above the options in the UI
        arr.unshift({
          PARM_ID: 'CNTRCTTYPE',
          PARM_REFERENCE: contractType.trim(),
          PARM_CONTROL: '',
          PARM_VARIABLE: contractType.trim(),
          PARM_TEXT: contractTypes.filter(
            e => e.type === contractType.trim(),
          )[0].name,
        });
        setContractSpecs(arr);
      } else {
        setSpecStatus('Unable to fetch contract specification options.');
      }
    }
  }
  const data = props.details;

  //function to check & return if we have certain object having that particular properties
  const getObject = (inArr, obj, e, action) => {
    const newObj = inArr.filter(
      ele =>
        ele.PARM_ID.trim() === 'SPECVAR' &&
        ele.PARM_VARIABLE.trim() === obj &&
        ele.PARM_CONTROL.trim() === e.PARM_CONTROL.trim(),
    );
    if (action === 'checkExistance') {
      return newObj.length > 0; // returning true/false based on the condition
    } else {
      return newObj[0]; // returning complete obj
    }
  };
  const orderTextArray = function (inArr, paramVal) {
    const resArr = [];
    let line = [],
      c = 0;
    if (inArr.length && paramVal === 'CNTRCTTYPE') {
      inArr.forEach(e => {
        if (e.PARM_ID.trim() === paramVal) {
          resArr.push(e);
        }
      });
    }
    if (inArr.length && paramVal !== 'CNTRCTTYPE') {
      const length = inArr.length;
      for (let i = 0; i < length; i++) {
        const e = inArr[i];
        c = i + 1;
        if (e.PARM_ID.trim() === 'SPECOPTION') {
          var newArr = e.PARM_TEXT.trim().split(/{([^}]+)}/g); // get the array of the strings we have in curly braces
          if (newArr.length > 1) {
            // adding input field when in place of curly braces text
            // eslint-disable-next-line no-loop-func
            newArr.forEach(obj => {
              if (getObject(inArr, obj, e, 'checkExistance')) {
                const newObj = getObject(inArr, obj, e, 'getObj');
                if (props.details?.constructor === Array) {
                  var tupu = props.details.filter(detail => {
                    return detail.OPTION_VARIABLE.includes(obj);
                  });
                  let optionValue = '';
                  let optionType = '';
                  tupu.forEach(e => (optionValue = e.OPTION_VALUE));
                  tupu.forEach(e => (optionType = e.OPTION_TYPE));
                  line.push(
                    <input
                      disabled={props.tab}
                      type="text"
                      className={'input-native-contract-specs'}
                      defaultValue={
                        newObj.PARM_CONTROL.trim() === optionType
                          ? optionValue
                          : ''
                      }
                      name="search"
                      id={newObj.PARM_VARIABLE.trim()}
                    />,
                  );
                }
              } else {
                line.push(`${obj} `); // adding plain text
              }
            });
          } else {
            // adding plain text
            if (e.PARM_TEXT.trim().includes('<br/>')) {
              const splitText = e.PARM_TEXT.trim()
                .split('<br/>')
                .filter(Boolean);
              if (splitText.length > 1) {
                splitText.forEach(e => {
                  line.push(e);
                  line.push(<br />);
                });
              } else {
                line.push(splitText);
                line.push(<br />);
              }
            } else {
              line.push(`${e.PARM_TEXT}`);
            }
          }
        }
        if (c < length && line.length) {
          if (
            e.PARM_CONTROL.trim() !== inArr[c].PARM_CONTROL.trim() ||
            e.PARM_ID.trim() !== inArr[c].PARM_ID.trim()
          ) {
            resArr.push(line);
            line = [];
          }
          if (c > length) {
            resArr.push(line);
          }
        }
      }
    }
    return resArr;
  };

  const [roles, setRoles] = useState([]);
  useEffect(() => {
    if (props.accessRole.length === 0) {
      const buyers = JSON.parse(window.localStorage.getItem('buyers'));
      props.setAccessRole(buyers);
    }
  });

  useEffect(() => {
    setRoles(props.accessRole);
  }, [props.accessRole]);

  function handleChange(event, field) {
    if (event.target.id === 'emailOnFile' || event.target.id === 'newEmail') {
      if (event.target.id === 'emailOnFile') {
        props.setDisableElectronic(true);
        props.handleChange(props.email, field);
      }
      if (event.target.id === 'newEmail') {
        props.setDisableElectronic(false);
        props.handleChange(event.target.value, field);
      }
    } else {
      props.handleChange(event.target.value, field);
    }
  }

  function handleChangeBuyer(event, field) {
    setAlternateBuyer(event.target.value);
    if (event.target.id === 'emailbuyerOnFile') {
      props.handleChangeBuyer('', true, field);
    } else {
      if (event.target.id === 'newbuyerEmail') {
        props.handleChangeBuyer('', false, field);
      } else {
        props.handleChangeBuyer(event.target.value, false, field);
      }
    }
  }

  function handleChangeSpecOption(event, index) {
    console.log(event.target.checked, index);
    props.handleChangeSpecOption(event.target.checked, index);
  }

  function handleChangeSignature(event, field) {
    setSignatureMethod(event.target.value);
    if (field === 'signatureMethod') {
      props.handleChangeSignature(event.target.value, field);
    }
  }

  const nativeContractRadioOptions = orderTextArray(
    contractSpecs,
    'CNTRCTTYPE',
  );
  const nativeContractSpecOptns = orderTextArray(contractSpecs, 'SPECVAR');
  const editField = 'editField-temp';
  return (
    <div
      className={isBrowser ? 'divr' : {}}
      style={{ justifyContent: 'center', width: '95%' }}
    >
      <Row style={isBrowser ? styles.rowMargin : {}}>
        <Col>
          <ContractsSubSectionTitle
            title={'4. Feedlot Location'}
            floatRight={false}
            required={true}
          />
          <Row>
            <Col>
              <textarea
                disabled={props.tab}
                type="text"
                className={editField}
                name="search"
                style={styles.textArea}
                defaultValue={
                  props.details?.FEED_LOT_DESC
                    ? props.details.FEED_LOT_DESC
                    : ''
                }
                onBlur={event => handleChange(event, 'feedlot')}
              />
            </Col>
          </Row>
          <ContractsSubSectionTitle
            title={'5. Specification Options'}
            floatRight={false}
            required={true}
          />
          {specStatus === 'success' ? (
            <>
              <Row>
                {nativeContractRadioOptions.map(option => {
                  return (
                    <Col>
                      <label
                        className={regularText}
                        htmlFor={option.PARM_REFERENCE}
                        style={styles.leftFive}
                      >
                        {option.PARM_TEXT}
                      </label>
                    </Col>
                  );
                })}
              </Row>
              <Row style={{ marginBottom: 30 }}>
                {nativeContractSpecOptns.map((arr, index) => {
                  return (
                    <div className="checkbox" style={{ marginTop: 5 }}>
                      <input
                        disabled={props.tab}
                        type="checkbox"
                        id={index}
                        defaultChecked={
                          parseInt(props.details.OPTION_TYPE, 36) - 10 === index
                        }
                        name="NativeContarctSpecOptns"
                        style={styles.input}
                        onChange={event => handleChangeSpecOption(event, index)}
                      />
                      <label
                        className={regularText}
                        htmlFor={index}
                        style={isBrowser ? styles.label : styles.labelSm}
                      >
                        <span style={styles.leftFive}>{arr}</span>
                      </label>
                    </div>
                  );
                })}
              </Row>
            </>
          ) : (
            <Grid container>
              <Grid item>
                <Alert
                  severity={specStatus === 'Loading...' ? 'info' : 'warning'}
                  style={{ marginBottom: 20 }}
                >
                  {specStatus}
                </Alert>
              </Grid>
            </Grid>
          )}
          <ContractsSubSectionTitle
            title={'6. Spec Comments'}
            floatRight={false}
            required={true}
          />
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <TextareaAutosize
                disabled={props.tab}
                type="text"
                className={editField}
                name="search"
                style={styles.textArea}
                defaultValue={
                  props.details?.SPEC_COMMENT
                    ? props.details.SPEC_COMMENT.trim()
                    : ''
                }
                onBlur={event => handleChange(event, 'comments')}
              />
            </Col>
          </Row>
          <ContractsSubSectionTitle
            title={'7. Signature Method'}
            floatRight={false}
            required={true}
          />
          <Row style={styles.leftFive}>
            <Col xs="6" sm="4">
              <input
                disabled={props.disableElectronic}
                type="radio"
                checked={signatureMethod === 'E' ? true : false}
                id="electronic"
                value="E"
                name="signatureMethod"
                onClick={event =>
                  handleChangeSignature(event, 'signatureMethod')
                }
              />
              <label
                className={regularText}
                htmlFor="electronic"
                style={isBrowser ? styles.leftFive : styles.labelSm}
              >
                Electronic
              </label>
            </Col>
            <Col xs="6" sm="4">
              <input
                disabled={props.tab}
                type="radio"
                checked={signatureMethod === 'M' ? true : false}
                id="manual"
                value="M"
                name="signatureMethod"
                onClick={event =>
                  handleChangeSignature(event, 'signatureMethod')
                }
              />
              <label
                className={regularText}
                htmlFor="manual"
                style={isBrowser ? styles.leftFive : styles.labelSm}
              >
                Manual
              </label>
            </Col>
          </Row>
          <ContractsSubSectionTitle
            title={'8. Supplier Email Information'}
            floatRight={false}
            required={true}
          />
          <Row style={styles.leftFive}>
            <Col>
              <input
                key={Math.random()}
                disabled={props.tab}
                type="radio"
                defaultChecked={
                  primProdEmail !== '' || primProdEmail !== ' ' ? true : false
                }
                id="emailOnFile"
                value="emailOnFile"
                name="supplierEmail"
                onClick={event => handleChange(event, 'email')}
              />
              <label
                className={regularText}
                htmlFor="emailOnFile"
                style={isBrowser ? styles.leftFive : styles.labelSm}
              >
                Supplier Email on File: {primProdEmail ? primProdEmail : ''}
              </label>
            </Col>
          </Row>
          <Row style={styles.leftFive}>
            <Col>
              <input
                key={Math.random()}
                disabled={props.tab}
                type="radio"
                defaultChecked={primProdEmail ? false : true}
                id="newEmail"
                value="newEmail"
                name="supplierEmail"
              />
              <label
                className={regularText}
                htmlFor="newEmail"
                style={isBrowser ? styles.leftFive : styles.labelSm}
              >
                Alternate Supplier Email for Receiving Contract
              </label>
              <input
                disabled={props.tab}
                type="text"
                className={editField}
                name="search"
                id="newEmail"
                style={{ textAlign: 'left', marginTop: 2, width: '100%' }}
                onBlur={event => handleChange(event, 'email')}
              />
            </Col>
          </Row>
          <ContractsSubSectionTitle
            title={'9. Buyer Information'}
            floatRight={false}
            required={true}
          />
          <Row style={styles.leftFive}>
            <Col>
              <input
                disabled={props.tab}
                type="radio"
                defaultChecked={true}
                id="emailbuyerOnFile"
                value="emailbuyerOnFile"
                name="buyerEmail"
                onClick={event =>
                  handleChangeBuyer(event, 'alternateBuyerEmail')
                }
              />
              <label
                className={regularText}
                htmlFor="emailbuyerOnFile"
                style={isBrowser ? styles.leftFive : styles.labelSm}
              >
                Buyer on File: {''}
                {props.details?.EMAIL_ADDRESS
                  ? props.details.EMAIL_ADDRESS
                  : ''}
              </label>
            </Col>
          </Row>
          <Row style={styles.leftFive}>
            <Col>
              <input
                disabled={props.tab}
                type="radio"
                defaultChecked={false}
                id="newbuyerEmail"
                value="newbuyerEmail"
                name="buyerEmail"
                onClick={event =>
                  handleChangeBuyer(event, 'alternateBuyerEmail')
                }
              />
              <label
                className={regularText}
                htmlFor="newbuyerEmail"
                style={isBrowser ? styles.leftFive : styles.labelSm}
              >
                Alternate Buyer
              </label>
              <select
                disabled={props.tab}
                className="search_categories"
                style={{ marginTop: 2, width: '100%' }}
                onChange={event =>
                  handleChangeBuyer(event, 'alternateBuyerEmail')
                }
                value={alternateBuyer}
              >
                <option value="">Select alternate buyer</option>
                {roles.map((item, index) => {
                  return (
                    <option key={index} value={item.EMAIL_ADDRESS}>
                      {`${item.BUYER_NAME} (${item.BUYER_NUMBER})`}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = state => ({
  accessRole: state.accessRole.accessRole,
});
const mapDispatchToProps = dispatch => ({
  setAccessRole: roles => dispatch(setAccessRole(roles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
