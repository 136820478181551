import React, { useState, useEffect, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'react-device-detect';

const defaultMaterialTheme = createTheme({
  typography: {
    fontSize: 13,
    fontFamily: 'Proxima Nova',
  },
});

const HarvestedCalendar = props => {
  const [selectedToDate, handleToDateChange] = useState(props.dateTo);
  const [dateFromLess, setDateFromLower] = useState(false);
  const [nextSaturday, setNextSat] = useState(new Date());
  const [nextToSaturday, setToNextSat] = useState(new Date());
  const [selectedFromDate, handleFromDateChange] = useState(props.dateFrom);
  const [pickerOpen, setPickerOpen] = useState(false);

  function getNextSaturday() {
    var date = new Date();
    var lastday = date.getDate() - (date.getDay() - 1) + 5 - 21;
    var sDay = new Date(date.setDate(lastday));
    var dd = String(sDay.getDate()).padStart(2, '0');
    var mm = String(sDay.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = sDay.getFullYear();
    const formatedFromDate = new Date(
      `${yyyy}-${mm}-${dd}T00:00:00`,
    ).toString();
    setNextSat(formatedFromDate);
    FromDateChanged(formatedFromDate);

    var nNextSaturday = sDay.getDate() - (sDay.getDay() - 1) + 5;
    var toSaturday = nNextSaturday + 7;
    var NsDay = new Date(date.setDate(toSaturday));
    var ndd = String(NsDay.getDate()).padStart(2, '0');
    var nmm = String(NsDay.getMonth() + 1).padStart(2, '0'); //January is 0!
    var nyyyy = NsDay.getFullYear();
    const formatedToDate = new Date(
      `${nyyyy}-${nmm}-${ndd}T00:00:00`,
    ).toString();
    setToNextSat(formatedToDate);
    ToDateChanged(nextToSaturday);
  }

  function disableDates(date) {
    if (date.getDay() === 6) {
      return false;
    }
    return true;
  }
  function disableDatesTo(date) {
    const curr = new Date(date.toString());
    const from = new Date(selectedFromDate);

    if (date.getDay() === 6) {
      if (from > curr) {
        // disables dates older than "from" date
        return true;
      }
      return false;
    }
    return true;
  }

  function FromDateChanged(date) {
    const formatDate = new Date(date);
    const currentToDate = new Date(selectedToDate);
    props.fromDate(
      `${formatDate.getFullYear()}-${String(formatDate.getMonth() + 1).padStart(
        2,
        '0',
      )}-${String(formatDate.getDate()).padStart(2, '0')}`,
    );

    if (formatDate > currentToDate) {
      handleToDateChange(date);
    }
    handleFromDateChange(date);
  }

  function ToDateChanged(date) {
    const formatDate = new Date(date);
    props.toDate(
      `${formatDate.getFullYear()}-${String(formatDate.getMonth() + 1).padStart(
        2,
        '0',
      )}-${String(formatDate.getDate()).padStart(2, '0')}`,
    );
    if (new Date(date).getTime() < new Date(selectedFromDate).getTime()) {
      setDateFromLower(true);
    } else {
      setDateFromLower(false);
    }
    handleToDateChange(date);
  }

  useEffect(() => {
    if (!props.click) {
      getNextSaturday();
    } else {
      FromDateChanged(`${props.dateFrom} 00:00`);
      ToDateChanged(`${props.dateTo} 00:00`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSaturday]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <Grid
          container
          item
          xs={isMobile ? 10 : 9}
          md={10}
          style={{ marginTop: 5 }}
        >
          <Grid item>
            <label className={'title-bold'} style={{ marginRight: 10 }}>
              From :
            </label>{' '}
          </Grid>
          <Grid item>
            <div
              style={{ display: 'flex', cursor: 'pointer' }}
              className="input-bkgrd"
              onClick={e => {
                if (!pickerOpen) {
                  setPickerOpen('from');
                }
              }}
            >
              <i className="pi pi-calendar" style={{ padding: 8 }}></i>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                  open={pickerOpen === 'from'}
                  onClose={() => {
                    setPickerOpen(null);
                  }}
                  maxDate={`${new Date().getFullYear() + 10}-12-31`}
                  minDate={`${new Date().getFullYear() - 10}-12-31`}
                  style={{ paddingLeft: 5 }}
                  format="MM/dd/yyyy"
                  value={selectedFromDate}
                  onChange={e => {
                    props.resetDisplay();
                    FromDateChanged(e);
                  }}
                  shouldDisableDate={disableDates}
                />
              </ThemeProvider>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={isMobile ? 10 : 9}
          md={10}
          style={{ marginTop: 3 }}
        >
          <Grid item>
            <label
              className={'title-bold'}
              style={
                isMobile
                  ? { marginRight: 30, marginTop: 5 }
                  : { marginRight: 30 }
              }
            >
              To :
            </label>
          </Grid>
          <Grid item>
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                ...(isMobile && { marginTop: 5 }),
              }}
              className="input-bkgrd"
              onClick={e => {
                if (!pickerOpen) {
                  setPickerOpen('to');
                }
              }}
            >
              <i className="pi pi-calendar" style={{ padding: 8 }}></i>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                  open={pickerOpen === 'to'}
                  onClose={() => setPickerOpen(null)}
                  maxDate={`${new Date().getFullYear() + 10}-12-31`}
                  minDate={`${new Date().getFullYear() - 10}-12-31`}
                  style={{ paddingLeft: 5 }}
                  className="input-bkgrd"
                  format="MM/dd/yyyy"
                  value={selectedToDate}
                  onChange={e => {
                    props.resetDisplay();
                    ToDateChanged(e);
                  }}
                  shouldDisableDate={disableDatesTo}
                />
              </ThemeProvider>
            </div>
          </Grid>
        </Grid>
        {dateFromLess ? (
          <Grid item>
            <Alert severity="error">To Date is less than From Date</Alert>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export default HarvestedCalendar;
