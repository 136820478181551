import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';

const formatDate = function (input) {
  //This function was not working as it should, it always returned NULL
  // Converts yyyy-MM-dd to MM/dd/yyyy using REGEX
  // var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
  // var pattern = /(\d{4})(\d{2})(\d{2})/;
  // if (!input || !input.match(pattern)) {
  //   return null;
  // }
  // return input.replace(pattern, '$2/$3/$1');
  //---------------------------------------------------

  if (!input) {
    return '01/01/0000';
  } else {
    const YYYY = input.slice(0, 4);
    const MM = input.slice(5, 7);
    const DD = input.slice(8);
    return `${MM}/${DD}/${YYYY}`;
  }
};

const addCommas = function (number) {
  // adds commas when a number has 3 consecutive digits using REGEX
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parsePrefilledInfo = data => [
  {
    header: 'Supplier Info',
    column: 0,
    fields: [
      {
        name: 'Supplier ID',
        value: data.SUPNUMBER ? data.SUPNUMBER.trim() : '',
        textField: true,
      },
      {
        name: 'Supplier Name',
        value:
          data.SUPFIRSTNAME && data.SUPLASTNAME
            ? `${data.SUPFIRSTNAME.trim()} ${data.SUPLASTNAME.trim()}`
            : '',
        textField: true,
      },
      {
        name: 'Address 1',
        value: data.SUPADDR1 ? data.SUPADDR1.trim() : '',
        textField: true,
      },
      {
        name: 'Address 2',
        value: data.SUPADDR2 ? data.SUPADDR2.trim() : '',
        textField: true,
      },
      {
        name: 'City',
        value: data.SUPCITY ? data.SUPCITY.trim() : '',
        textField: true,
      },
      {
        name: 'State',
        value: data.SUPSTATE ? data.SUPSTATE.trim() : '',
        textField: true,
      },
      {
        name: 'Zip',
        value: data.SUPZIP
          ? data.SUPZIP.length > 5
            ? `${data.SUPZIP.slice(0, 5)}-${data.SUPZIP.slice(5)}`
            : data.SUPZIP
          : '',
        textField: true,
      },
      {
        name: 'Phone',
        value: data.SUP_WORK_PHONE_NUM
          ? data.SUP_WORK_PHONE_NUM.length > 5
            ? `${data.SUP_WORK_PHONE_NUM.slice(
                0,
                3,
              )}-${data.SUP_WORK_PHONE_NUM.slice(
                3,
                6,
              )}-${data.SUP_WORK_PHONE_NUM.slice(6)}`
            : data.SUP_WORK_PHONE_NUM
          : '',
        textField: true,
      },
      {
        name: 'Email',
        value: data.EMAIL_ADDRESS ? data.EMAIL_ADDRESS.trim() : '',
        textField: true,
      },
    ],
  },
  {
    header: 'Buyer Info',
    column: 0,
    fields: [
      {
        name: 'Buyer Number',
        value: data.BUYER_NUMBER ? data.BUYER_NUMBER.trim() : '',
        textField: true,
      },
      {
        name: 'Buyer Name',
        value: data.BUYER_NAME ? data.BUYER_NAME.trim() : '',
        textField: true,
      },
      {
        name: 'Email',
        value: data.EMAIL_ADDRESS ? data.EMAIL_ADDRESS.trim() : '',
        textField: true,
      },
    ],
  },
  {
    header: 'Contract Info',
    column: 0,
    fields: [
      {
        name: 'Contract Number',
        value: data.CONTRACT_NUMBER ? data.CONTRACT_NUMBER.trim() : '',
        textField: true,
      },
      {
        name: 'Plant Code',
        value: data.PLANT_CODE ? data.PLANT_CODE.trim() : '',
        textField: true,
      },
      {
        name: 'Contract Type',
        value:
          data.CONTRACT_TYPE === 'S'
            ? 'Native - Steer/Heifer'
            : data.CONTRACT_TYPE === 'CM'
            ? 'Native - Carcass'
            : data.CONTRACT_TYPE === 'N'
            ? 'Natural'
            : data.CONTRACT_TYPE === 'NH'
            ? 'Natural/NHTC'
            : 'Not Specified',
        textField: true,
      },
      {
        name: 'Futures Month/Year',
        value:
          (data.FUTURES_MONTH ? data.FUTURES_MONTH : '') +
          '/' +
          (data.FUTURES_MONTH ? data.FUTURES_YEAR : ''),
        textField: true,
      },
      {
        name: 'Delivery Period',
        value: data.DELIVERY_PERIOD ? data.DELIVERY_PERIOD.trim() : '',
        textField: true,
      },
      {
        name: 'Start Delivery Date',
        value: data.START_DELV_DATE
          ? formatDate(data.START_DELV_DATE)
          : '01/01/1990',
        textField: true,
      },
      {
        name: 'End Delivery Date',
        value: data.END_DELV_DATE
          ? formatDate(data.END_DELV_DATE)
          : '01/01/1990',
        textField: true,
      },
      {
        name: 'Sex',
        value: data.SEX_CODE ? data.SEX_CODE.trim() : '',
        textField: true,
      },
      {
        name: 'Head Contracted',
        value: data.CONTRACT_WEIGHT ? data.CONTRACT_HEAD : '',
        textField: true,
      },
      {
        name: isBrowser ? 'Live Weight Contracted' : 'Live Weight',
        value: data.CONTRACT_WEIGHT ? addCommas(data.CONTRACT_WEIGHT) : '',
        textField: true,
      },
      {
        name: 'Plant for Delivery',
        value: data.PLANT_CODE ? data.PLANT_CODE.trim() : '',
        //+ ' - ' + data.contractInfo.plant.name,
        textField: true,
      },
      {
        name: 'Basis',
        value: data.ACTUAL_BASIS_IND ? data.ACTUAL_BASIS_IND.trim() : '',
        textField: true,
      },
      {
        name: 'Dress Yield',
        value: data.DRESSED_YIELD ? data.DRESSED_YIELD + '%' : '',
        textField: true,
      },
      {
        name: 'Insured Price',
        value: data.INSURED_PRICE ? '$' + data.INSURED_PRICE : '',
        textField: true,
      },
      {
        name: 'Deferred',
        value: data.DEFERRED_IND ? data.DEFERRED_IND.trim() : '',
        textField: true,
      },
    ],
  },
];
parsePrefilledInfo.propTypes = {
  supplierInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  buyerInfo: PropTypes.shape({
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  contractInfo: PropTypes.shape({
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    contractType: PropTypes.string.isRequired,
    futures: PropTypes.string.isRequired,
    delPeriod: PropTypes.string.isRequired,
    delivery: PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }),
    sex: PropTypes.string.isRequired,
    headCount: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    plant: PropTypes.shape({
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    basis: PropTypes.string.isRequired,
    dressYield: PropTypes.string.isRequired,
    insuredPrice: PropTypes.number.isRequired,
    deferred: PropTypes.bool.isRequired,
  }),
};
export default parsePrefilledInfo;
