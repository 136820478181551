import React, { useState, useEffect } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { Label } from 'reactstrap';
import FilterModal from './FilterModal';
import ContractsBody from '../../Mobile/Contracts/ContractsBody';
import { useStyles, AntTab, AntTabs, styles } from './Styles';
import { filtericon, searchicon } from './';
import BuyerDropdown from './BuyerDropdown';

const FilterText = ({ toggleFilterModal, delPeriod, supplier }) => {
  let filterText = 'Please select at least one delivery period.';
  if (delPeriod.length) {
    const delPeriodText = delPeriod;
    const supplierText = supplier === 'all' ? 'All Suppliers' : supplier;
    filterText = `${delPeriodText}, ${supplierText}`;
  }

  return (
    <Link
      className="label-text"
      style={styles.filterLabelContainer}
      color="gray"
      onClick={toggleFilterModal}
    >
      Filters: {filterText}
    </Link>
  );
};

const filterContracts = (contracts, supplierNum, deliveryPeriods) => {
  return contracts.filter(({ SUPNUMBER, DELIVERY_PERIOD }) => {
    // Don't filter on supplier number if the user has selected all
    if (supplierNum === 'ALL') {
      return deliveryPeriods.includes(DELIVERY_PERIOD);
    }
    return (
      SUPNUMBER === supplierNum && deliveryPeriods.includes(DELIVERY_PERIOD)
    );
  });
};

const ContractList = props => {
  const classes = useStyles();
  const contractTabs = [
    {
      label: 'Approval Needed',
      length: props.approvalContracts?.length,
    },
    {
      label: 'Signature Pending',
      length: props.pendingContracts?.length,
    },
    {
      label: 'Completed',
      length: props.completedContracts?.length,
    },
    {
      label: 'Exceptions',
      length: props.exceptionContracts?.length,
    },
  ];

  const [filterModal, setFilterModal] = useState(false);
  const [contracts, setContracts] = useState(false);

  const toggleFilterModal = () => setFilterModal(!filterModal);

  // Set pending, approval, and exception lists
  useEffect(() => {
    const completedContracts = filterContracts(
      props.completedContracts,
      props.supplier,
      props.selectedDelPeriod,
    );
    setContracts({
      completedContracts,
      pendingContracts: props.pendingContracts,
      approvalContracts: props.approvalContracts,
      exceptionContracts: props.exceptionContracts,
    });
  }, [
    props.approvalContracts,
    props.completedContracts,
    props.pendingContracts,
    props.exceptionContracts,
    props.supplier,
    props.selectedDelPeriod,
  ]);

  const serachContract = (e, contractArray) => {
    if (!e.target.value) {
      setContracts(prevState => ({
        ...prevState,
        [contractArray]: props[contractArray],
      }));
      return;
    }
    const targetArrCopy = props[contractArray].slice();

    const filterArray = targetArrCopy.filter(contract =>
      contract.CONTRACT_NUMBER.startsWith(e.target.value.toUpperCase()),
    );
    setContracts(prevState => ({
      ...prevState,
      [contractArray]: filterArray,
    }));
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Typography className={'title'}> Buyer:</Typography>
        </Grid>
        <Grid item xs={10}>
          <BuyerDropdown buyerNum={props.buyer} setBuyerNum={props.setBuyer} />
        </Grid>
      </Grid>

      {props.buyer === '-1' ? (
        'Please select a buyer to continue.'
      ) : (
        <>
          <div className="search-contract-number-container">
            <img src={searchicon} alt="lens" style={styles.searchIcon} />
            <input
              className={'search-contract-number'}
              placeholder="Search Contract Number"
              onKeyUp={e => {
                serachContract(
                  e,
                  props.value === 2
                    ? 'completedContracts'
                    : props.value === 1
                      ? 'pendingContracts'
                      : props.value === 0
                        ? 'approvalContracts'
                        : 'exceptionContracts',
                );
              }}
            ></input>
          </div>
          <div className={classes.root}>
            <div className={classes.demo1}>
              <AntTabs
                value={props.value}
                variant="scrollable"
                onChange={props.handleChange}
                aria-label="ant example"
                className="text-regular"
              >
                {contractTabs.map((item, index) => (
                  <AntTab
                    className={
                      props.value === index
                        ? classes.antTabEnabled
                        : classes.antTabDisabled
                    }
                    label={
                      <>
                        <span className={classes.tabLabel}>{item.label}</span>
                        <div
                          className={item.length > 0 ? 'red-dot' : null}
                        ></div>
                      </>
                    }
                  />
                ))}
              </AntTabs>
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            {props.value === 2 ? (
              <Grid container direction="row" spacing={0}>
                <Grid item xs={1}>
                  <img src={filtericon} alt="filter" />
                </Grid>
                <Grid item xs={10}>
                  <FilterText
                    toggleFilterModal={toggleFilterModal}
                    delPeriod={props.selectedDelPeriod}
                    supplier={props.supplier}
                  />
                  <FilterModal
                    plantsArr={props.plantsArr}
                    filterModal={filterModal}
                    togglefilterModal={toggleFilterModal}
                    selectedDelPeriod={props.selectedDelPeriod}
                    setDelPeriod={props.setDelPeriod}
                    buyer={props.buyer}
                    supplier={props.supplier}
                    setSupplier={props.setSupplier}
                  />
                </Grid>
              </Grid>
            ) : (
              <div />
            )}
            <Grid item>
              <Label
                className={'label-completed-results'}
                style={{
                  fontSize: 14,
                }}
              >
                {props.value === 0 && contracts.approvalContracts
                  ? contracts.approvalContracts.length
                  : props.value === 1 && contracts.pendingContract
                    ? contracts.pendingContracts.length
                    : props.value === 2 && contracts.completedContracts
                      ? contracts.completedContracts.length
                      : props.value === 3 && contracts.exceptionContracts
                        ? contracts.exceptionContracts.length
                        : 0}{' '}
                results found
              </Label>
            </Grid>
            <ContractsBody
              array={
                props.value === 0 && contracts.approvalContracts
                  ? contracts.approvalContracts
                  : props.value === 1 && contracts.pendingContracts
                    ? contracts.pendingContracts
                    : props.value === 2 && contracts.completedContracts
                      ? contracts.completedContracts
                      : props.value === 3 && contracts.exceptionContracts
                        ? contracts.exceptionContracts
                        : []
              }
              value={props.value}
              clicked={props.selectedContractor}
              changeClicked={item => {
                props.changeClicked(item);
              }}
              toggleLoading={props.toggleLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContractList;
