import api from './../index';

export const getContracts = async buyerNumber => {
  const res = await api();
  return res
    .get(`contractManagement/getContracts/${buyerNumber}`)
    .then(({ data }) => data);
};

export const getContractDetails = async buyerNumber => {
  const res = await api();
  return res
    .get(`contractManagement/cattleContractDetails/${buyerNumber}`)
    .then(res => {
      return res.data;
    });
};

export const getAdditionalInfo = async contractNumber => {
  const res = await api();
  return res
    .get(`contractManagement/extractSpecOptions/${contractNumber}`)
    .then(res => {
      return res.data;
    });
};

export const getSpecsInfo = async (contractType, isOld) => {
  const res = await api();
  return res
    .get(
      `contractManagement/getContractSpecifications/${contractType}/${isOld}`,
    )
    .then(res => {
      return res;
    });
};

//Saving the Approval Needed second page details
export async function postAdditionalInfo(eventBody) {
  //console.log('Data sent to endpoint', eventBody);
  const res = await api();
  const body = JSON.stringify(eventBody);
  return res
    .post(`contractManagement/updateContract`, body)
    .then(res => {
      return res.data;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
}
