import React from 'react';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default ({
  sheetName,
  className,
  data,
  columns,
  fileName,
  display,
  source,
}) => {
  let dataCopy = [...data];
  if (source === 'summary') {
    columns.unshift({ header: 'BUYER', field: 'BUYER_NUMBER' });
    columns.unshift({ header: 'SUPPLIER', field: 'SUPPLIER_ID' });
  }
  if (source === 'daily') {
    // remove hidden column
    const index = columns.map(e => e.header).indexOf('HIDDEN');
    columns.splice(index, 1);
    columns.unshift({ header: 'BUYER', field: 'buyerNum' });
    columns.unshift({ header: 'SUPPLIER', field: 'supplierId' });
    columns.unshift({ header: 'HARVEST DATE', field: 'hvtdate' });
  } else {
    columns.unshift({ header: 'WEEK ENDING DATE', field: 'CURRENT_WEEK_END' });
  }

  const formatDataForExcel = () => {
    if (source === 'summary') {
      for (let index = 0; index < dataCopy.length; index++) {
        for (let j = 0; j < columns.length; j++) {
          const element = columns[j];
          if (element.field !== 'CURRENT_WEEK_END' && display !== 'Both') {
            if (element.field !== 'PLANT_CODE') {
              dataCopy[index][element.field] = parseFloat(
                dataCopy[index][element.field],
              );
            }
          }
          if (element.field === 'CURRENT_WEEK_END' && display !== 'Both') {
            if (dataCopy[index][element.field] !== 'Totals') {
              let date = new Date(dataCopy[index][element.field]);
              let formattedDate = `${
                date.getMonth() + 1
              }/${date.getDate()}/${date.getFullYear()}`;
              dataCopy[index][element.field] = formattedDate;
            }
          }
        }
      }
    } else {
      for (let index = 0; index < dataCopy.length; index++) {
        for (let j = 0; j < columns.length; j++) {
          const element = columns[j];
          if (
            element.field !== 'hvtdate' &&
            element.field !== 'paymeth' &&
            display !== 'Both'
          ) {
            if (dataCopy[index][element.field] !== 'Multi') {
              dataCopy[index][element.field] = parseFloat(
                dataCopy[index][element.field],
              );
            }
          }
          if (element.field === 'hvtdate' && display !== 'Both') {
            if (dataCopy[index][element.field] !== 'Multi') {
              let date = new Date(dataCopy[index][element.field]);
              let formattedDate = `${
                date.getMonth() + 1
              }/${date.getDate()}/${date.getFullYear()}`;
              dataCopy[index][element.field] = formattedDate;
            }
          }
        }
      }
    }
  };

  const excelColumns = columns.map(column => (
    <ExcelColumn
      key={column.field}
      label={column.header}
      value={column.field}
    />
  ));

  const exportButton = (
    <button
      disabled={data.length === 0 || display === 'Both' ? true : false}
      className={className}
      onClick={formatDataForExcel}
    >
      Export to Excel
    </button>
  );

  return (
    <ExcelFile element={exportButton} filename={fileName}>
      <ExcelSheet data={dataCopy} name={sheetName}>
        {excelColumns}
      </ExcelSheet>
    </ExcelFile>
  );
};
