import HomeIcon from '../images/Home.png';
import { ReactComponent as HomeSVG } from '../images/Home.svg';
import { ReactComponent as HarvestedSVG } from '../images/Harvested.svg';
import { ReactComponent as AdvPaymentSVG } from '../images/AdvPay.svg';
import ShowlistIcon from '../images/Showlist.png';
import PurchasedIcon from '../images/Purchased.png';
import HarvestedIcon from '../images/Harvested.png';
import ContactsIcon from '../images/Contacts.png';
import ContractsIcon from '../images/Contracts.png';
import AdvPaymentIcon from '../images/advpayment.png';
import UpcomingIcon from '../images/Upcoming.png';
import ChatIcon from '../images/Chat.png';
import config from '../config';

const tabsList = [
  {
    label: 'Home',
    url: 'Home',
    icon: HomeIcon,
    svg: true,
    svgIcon: HomeSVG,
    finished: true,
  },
  {
    label: 'Showlist',
    url: 'Showlist',
    icon: ShowlistIcon,
    svg: false,
    svgIcon: null,
    finished: false,
  },
  {
    label: 'Purchased',
    url: 'Purchased',
    icon: PurchasedIcon,
    svg: false,
    svgIcon: null,
    finished: false,
  },
  {
    label: 'Harvested',
    url: 'Harvested',
    icon: HarvestedIcon,
    svg: true,
    svgIcon: HarvestedSVG,
    finished: true,
  },
  {
    label: 'Contacts',
    url: 'Contacts',
    icon: ContactsIcon,
    svg: false,
    svgIcon: null,
    finished: false,
  },
  {
    label: 'Contracts',
    url: 'Contracts',
    icon: ContractsIcon,
    svg: false,
    svgIcon: null,
    finished: config.env !== 'prod',
  },
  {
    label: 'Adv. Payment',
    url: 'AdvPayment',
    icon: AdvPaymentIcon,
    svg: true,
    svgIcon: AdvPaymentSVG,
    finished: true,
  },
  {
    label: 'Upcoming',
    url: 'Upcoming',
    icon: UpcomingIcon,
    svg: false,
    svgIcon: null,
    finished: false,
  },
  {
    label: 'Chat',
    url: 'Chat',
    icon: ChatIcon,
    svg: false,
    svgIcon: null,
    finished: false,
  },
];
const tabListMobile = [
  {
    label: 'Home',
    url: 'Home',
    icon: HomeIcon,
    svg: true,
    svgIcon: HomeSVG,
    finished: true,
  },
  {
    label: 'Harvested',
    url: 'Harvested',
    icon: HarvestedIcon,
    svg: true,
    svgIcon: HarvestedSVG,
    finished: true,
  },
  {
    label: 'Contracts',
    url: 'Contracts',
    icon: ContractsIcon,
    svg: false,
    svgIcon: null,
    finished: config.env !== 'prod',
  },
];
export { tabsList, tabListMobile };
