export const GET_SETTING = 'GET_SETTING';
export const SET_SETTING = 'SET_SETTING';
export const GET_VERSION = 'GET_VERSION';
export const GET_LOADER = 'GET_LOADER';
export const GET_COMPLETED_CONTRACTS = 'GET_COMPLETED_CONTRACTS';
export const GET_PENDING_CONTRACTS = 'GET_PENDING_CONTRACTS';
export const GET_APPROVAL_CONTRACTS = 'GET_APPROVAL_CONTRACTS';
export const GET_EXCEPTION_CONTRACTS = 'GET_EXCEPTION_CONTRACTS';
export const ACCESS_ROLE = 'ACCESS_ROLE';
export const ACCESS_ROLE_SET = 'ACCESS_ROLE_SET';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_ROLE = 'GET_ROLE';
