import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  suppliers: [],
};

const suppliers = (state = INITIAL_STATE, action) => {
  if (action.type === actionTypes.GET_SUPPLIERS) {
    return {
      ...state,
      suppliers: action.payload,
    };
  } else {
    return state;
  }
};

export default suppliers;
