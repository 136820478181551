module.exports.api = {
  url: 'https://xxjom92e50.execute-api.us-east-1.amazonaws.com/dev',
};

module.exports.okta = {
  clientId: '0oas18ndswqCnOoto0h7',
  url: 'https://tyson-dev.oktapreview.com/',
  issuer: 'https://tyson-dev.oktapreview.com/oauth2/default',
  redirectUri: 'https://cattle-buying-info-system-dev.tyson.com/callback',
};

module.exports.version = { number: '1.0' };

module.exports.env = 'dev';
