import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import { Grid, Badge } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import allActions from '../../../redux/actions';
import { Row, Col, Label, Modal, ModalBody } from 'reactstrap';
import { Pdf, PrefilledInfo, AdditionalInfo } from './';
import { useStyles, styles } from './Styles';
import { ReactComponent as ContractsIcon } from '../../../images/Contracts.svg';
import { postAdditionalInfo } from '../../../services/api/contractManagement';
import './Contracts.css';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function ContractDetails(props) {
  const [validSubmit, setvalidSubmit] = useState(false);
  const [showAlertSubmit, setshowAlertSubmit] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [title, settitle] = useState('');
  const [isBlocking, setIsBlocking] = useState(false);
  const [disableElectronic, setDisableElectronic] = useState(true);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [contractDetails, setDetails] = useState({
    contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    contractType: 'S',
    email: ' ',
    buyerOnFile: true,
    feedlot: '',
    currentUser: 1234,
    comments: ' ',
    signatureMethod: 'E',
    contractSpecs: [
      { optionType: 'A', optionVar: 'AS', optionVal: '345', uom: 'PCT' },
      { optionType: 'B', optionVar: 'BS', optionVal: '456', uom: 'PCT' },
      { optionType: 'C', optionVar: 'CS', optionVal: '678', uom: 'CNT' },
    ],
  });
  const [specOptions, setSpecOptions] = useState([
    { optionType: 0, optionChkd: false },
    { optionType: 1, optionChkd: false, varInput: 'CHOICE_PCT', value: '' },
    { optionType: 2, optionChkd: false },
    { optionType: 3, optionChkd: false, varInput: 'OFFGRADE_PCT', value: '' },
    { optionType: 4, optionChkd: false },
    { optionType: 5, optionChkd: false },
  ]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectMessage = 'Select a contract to view details';

  function handleChange(event, field) {
    if (!isBlocking) {
      setIsBlocking(contractDetails[field] !== event);
    }
    setDetails({
      ...contractDetails,
      [field]: event,
      contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    });
  }

  const handleChangeSignature = (event, field) => {
    let newCopy = {};
    Object.assign(newCopy, contractDetails);
    newCopy = {
      ...newCopy,
      [field]: event,
      contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    };

    setDetails(newCopy);
  };

  function handleChangeBuyer(event, changed, field) {
    if (!isBlocking) {
      setIsBlocking(contractDetails[field] !== event);
    }
    setDetails({
      ...contractDetails,
      [field]: event,
      buyerOnFile: changed,
      contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    });
  }

  function handleChangeSpecOption(event, option) {
    const index = specOptions.findIndex(item => item.optionType === option);
    const newitem = specOptions[index];
    console.log(specOptions, specOptions[index]);
    newitem.optionChkd = event;
    specOptions.splice(index, 1, newitem);
  }

  function validate(isSave) {
    setvalidSubmit(false);
    if (contractDetails.feedlot === '') {
      setshowCancel(false);
      setshowAlert(true);
      settitle('Please enter a feedlot description');
      return false;
    }
    if (
      contractDetails.buyerOnFile === false &&
      contractDetails.alternateBuyerEmail === ''
    ) {
      setshowCancel(false);
      setshowAlert(true);
      settitle('An alternate buyer is not specified');
      return false;
    }
    let optChecked = false;
    let optValue = true;
    specOptions.forEach((item, index) => {
      if (item.optionChkd) {
        optChecked = true;
        if (item.varInput) {
          if (item.value === '') {
            optValue = false;
          }
        }
      }
    });
    if (!optChecked) {
      setshowCancel(true);
      setshowAlert(true);
      setvalidSubmit(true);
      settitle(
        'No Specification Option was selected.  Are you sure you want to continue?',
      );
      return false;
    }
    if (!optValue) {
      setshowCancel(false);
      setshowAlert(true);
      settitle('Please enter a value for the Spec Option Variable');
      return false;
    }
    if (contractDetails.comments === '') {
      setshowCancel(true);
      setshowAlert(true);
      if (!isSave) setvalidSubmit(true);
      settitle(
        'No comments have been entered. Are you sure you want to continue?',
      );
      return false;
    }
    if (
      validateEmail(contractDetails.PRIM_PROD_EMAIL) &&
      document.getElementById('newEmail').checked === false
    ) {
      return true;
    }
    if (document.getElementById('newEmail').checked) {
      if (validateEmail(contractDetails.email)) {
        return true;
      } else {
        setshowCancel(true);
        setshowAlert(true);
        if (!isSave) {
          setvalidSubmit(true);
        }
        settitle(`Alternate email is invalid`);
        return false;
      }
    }
  }

  function onConfirm() {
    if (validSubmit) {
      setshowAlertSubmit(true);
    }
    setshowAlert(false);
  }

  function validateEmail(email) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  function onConfirmSubmit() {
    postAdditionalInfo(contractDetails);
    props.toggle();
    setshowAlertSubmit(false);
  }

  useEffect(() => {
    if (isBlocking) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });
  const textRegular = 'text-regular';
  if (props.value === 0 && props.page === 1) {
    // Approval Needed - Prefilled Info
    return (
      <>
        <Label className="Contracts-right-title">
          {props.selectedContractor
            ? `Review Contract #${props.selectedContractor.CONTRACT_NUMBER}`
            : selectMessage}
        </Label>
        <PrefilledInfo
          selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
          toggleLoading={props.toggleLoading}
          setShowNextButton={val => setShowNextButton(val)}
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={val => setShowErrorMessage(val)}
        />

        {props.selectedContractor && showNextButton ? (
          <div style={styles.prefilledFooter}>
            <label
              style={{ marginLeft: 20, marginTop: 24 }}
              className={'btn-confrm-next'}
              onClick={() => props.setPage(2)}
            >
              Confirm and Next
              <div className={'arrow-confirm-next'}></div>
            </label>
          </div>
        ) : (
          showErrorMessage && (
            <Grid
              container
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <Alert
                  severity={'warning'}
                  style={{
                    marginBottom: 20,
                  }}
                >
                  Unexpected error retrieving contract details: Data not found
                </Alert>
              </Grid>
            </Grid>
          )
        )}
      </>
    );
  } else if (props.value === 0 && props.page === 2) {
    // Approval Needed - Additional Info
    return (
      <form
        onSubmit={event => {
          event.preventDefault();
          setIsBlocking(false);
        }}
      >
        <Prompt
          when={isBlocking}
          message={() =>
            'You have unsaved/unsubmitted changes are you sure you want to leave the page?'
          }
        />
        <Modal isOpen={props.modal} toggle={props.toggle} centered={true}>
          <ModalBody>
            <label className="text-success">
              Success! Contract {props.selectedContractor.CONTRACT_NUMBER} has
              been submitted for signature!
            </label>
          </ModalBody>
          <label className="btn-got-it" onClick={() => props.toggle()}>
            Got it!
          </label>
        </Modal>
        <Label className="Contracts-right-title">
          Complete Contract Specifications (
          <label style={{ color: '#FF3B30' }}>*</label> are required)
        </Label>

        <AdditionalInfo
          value={props.value}
          tab={props.value !== 0}
          selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
          details={contractDetails}
          setDetails={setDetails}
          disableElectronic={disableElectronic}
          setDisableElectronic={setDisableElectronic}
          signatureMethod={contractDetails.signatureMethod}
          handleChangeSignature={handleChangeSignature}
          handleChange={handleChange}
          handleChangeBuyer={handleChangeBuyer}
          handleChangeSpecOption={handleChangeSpecOption}
          setSpecOptions={setSpecOptions}
          specOptions={specOptions}
        />
        <br />
        <div style={styles.additionalInfoFooter}>
          <Row>
            <label
              style={{ marginRight: 20 }}
              className={'btn-back'}
              onClick={() => props.setPage(1)}
            >
              <div className={'arrow-back'}></div>Back
            </label>
            <button
              style={{ marginLeft: 20, marginRight: 20 }}
              className={'btn-save'}
              onClick={() => {
                if (validate(true)) {
                  postAdditionalInfo(contractDetails);
                }
              }}
            >
              Save
            </button>
            <button
              style={{ marginLeft: 20 }}
              className={'btn-submit'}
              onClick={() => {
                if (validate(false)) {
                  setshowAlertSubmit(true);
                }
              }}
            >
              Submit
            </button>
          </Row>
          <SweetAlert
            show={showAlert}
            showCancel={showCancel}
            type="warning"
            title={title}
            onConfirm={onConfirm}
            onCancel={() => setshowAlert(false)}
          ></SweetAlert>
          <SweetAlert
            show={showAlertSubmit}
            showCancel="true"
            type="warning"
            title="Please confirm you are ready to submit the contract for signature"
            onConfirm={onConfirmSubmit}
            onCancel={() => setshowAlertSubmit(false)}
          ></SweetAlert>
        </div>
      </form>
    );
  } else if (props.value === 1) {
    // Signature Pending
    return (
      <>
        <div
          className={'divr'}
          style={
            props.showSignaturePending
              ? { justifyContent: 'center' }
              : { justifyContent: 'flex-end' }
          }
        >
          {props.showSignaturePending ? (
            <Row style={{ width: '80%', margin: 20 }}>
              <Col xs={5} style={{ textAlign: 'end' }}>
                <label
                  className={textRegular}
                  style={{ marginLeft: 5, color: '#FF3B30' }}
                >
                  Signature pending on:
                </label>
              </Col>
              <Col>
                <input
                  type="radio"
                  id="buyer"
                  defaultChecked={true}
                  name="signature"
                  value="buyer"
                />
                <label
                  className={textRegular}
                  htmlFor="buyer"
                  style={{ marginLeft: 5 }}
                >
                  Buyer
                </label>
              </Col>
              <Col>
                <input
                  type="radio"
                  id="supplier"
                  disabled
                  name="signature"
                  value="supplier"
                />
                <label
                  className={textRegular}
                  htmlFor="supplier"
                  style={{ marginLeft: 5 }}
                >
                  Supplier
                </label>
              </Col>
              <Col>
                <input
                  type="radio"
                  name="signature"
                  disabled
                  id="both"
                  value="both"
                />
                <label
                  className={textRegular}
                  htmlFor="both"
                  style={{ marginLeft: 5 }}
                >
                  Both
                </label>
              </Col>
            </Row>
          ) : props.selectedContractor ? (
            <button
              className={'btn-signature-pdf'}
              onClick={() => props.setSignaturePending(true)}
            >
              <ContractsIcon
                fill="#fff"
                stroke="#65686B"
                className={classes.tabImage}
              />
              View Signature Pending
            </button>
          ) : null}
        </div>
        <div
          style={{ maxHeight: 600, overflowY: 'hidden', overflowX: 'hidden' }}
        >
          <Label className="Contracts-right-title">
            {props.selectedContractor
              ? `Contract #${props.selectedContractor.CONTRACT_NUMBER}`
              : selectMessage}
          </Label>
          {props.selectedContractor ? (
            <>
              <PrefilledInfo
                selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
                toggleLoading={props.toggleLoading}
                setShowNextButton={val => setShowNextButton(val)}
                showErrorMessage={showErrorMessage}
                setShowErrorMessage={val => setShowErrorMessage(val)}
              />
              <Label
                className="Contracts-right-title"
                style={{ marginTop: 30, marginBottom: 10 }}
              >
                {'Contract Specifications'}
              </Label>
              <AdditionalInfo
                value={props.value}
                tab={props.value !== 0}
                selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
                details={contractDetails}
                setDetails={setDetails}
                disableElectronic={disableElectronic}
                setDisableElectronic={setDisableElectronic}
                signatureMethod={contractDetails.signatureMethod}
                handleChangeSignature={handleChangeSignature}
                handleChange={handleChange}
                handleChangeBuyer={handleChangeBuyer}
                handleChangeSpecOption={handleChangeSpecOption}
                setSpecOptions={setSpecOptions}
                specOptions={specOptions}
              />
            </>
          ) : null}
        </div>
      </>
    );
  } else {
    return (
      <>
        {props.selectedContractor ? (
          props.selectedContractor.documentId === null ? (
            <Label className="Contracts-available">
              {'Handwritten contract, no image available'}
            </Label>
          ) : (
            <Pdf
              docId={
                props.selectedContractor
                  ? props.selectedContractor.documentId
                  : null
              }
              dispatch={dispatch}
              allActions={allActions}
            ></Pdf>
          )
        ) : null}
        <Grid container className={'statusBadge'} justify="flex-end">
          {props.value === 3 ? (
            <Badge
              badgeContent={props.selectedContractor.description}
              color="secondary"
            ></Badge>
          ) : (
            ''
          )}
        </Grid>
        <div
          style={{ maxHeight: 600, overflowY: 'hidden', overflowX: 'hidden' }}
        >
          <Label className="Contracts-right-title">
            {props.selectedContractor
              ? `Contract #${props.selectedContractor.CONTRACT_NUMBER}`
              : selectMessage}
          </Label>
          {props.selectedContractor ? (
            <>
              <PrefilledInfo
                selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
                toggleLoading={props.toggleLoading}
                setShowNextButton={val => setShowNextButton(val)}
                showErrorMessage={showErrorMessage}
                setShowErrorMessage={val => setShowErrorMessage(val)}
              />
              <Label
                className="Contracts-right-title"
                style={{ marginTop: 30, marginBottom: 10 }}
              >
                {'Contract Specifications'}
              </Label>
              <AdditionalInfo
                value={props.value}
                tab={props.value !== 0}
                selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
                details={contractDetails}
                setDetails={setDetails}
                disableElectronic={disableElectronic}
                setDisableElectronic={setDisableElectronic}
                signatureMethod={contractDetails.signatureMethod}
                handleChangeSignature={handleChangeSignature}
                handleChange={handleChange}
                handleChangeBuyer={handleChangeBuyer}
                handleChangeSpecOption={handleChangeSpecOption}
                setSpecOptions={setSpecOptions}
                specOptions={specOptions}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
