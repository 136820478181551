import React, { useState, useEffect, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Col, Button } from 'reactstrap';
import { Row } from 'primereact/row';
import { useStyles } from './Styles';
import ExcelExport from './ExcelExport';
import HarvestedDetailGrid from './HarvestedDetailGrid';
import { isBrowser, isMobile, BrowserView } from 'react-device-detect';
import { getWeeklySummary } from '../../../services/api/harvested';
import { useQuery } from '../../../utils/customHooks';
import { getUrlParam, setUrlParam } from '../../../utils/helperFunctions';
import { useHistory } from 'react-router-dom';


const HarvestedDataTable = ({
  viewType,
  detailClicked,
  errorHandle,
  loadingHandle,
  weeklySummaryFilter,
  display,
  setDetailClicked,
}) => {
  const history = useHistory();
  const query = useQuery();
  const [detailClick, setClicked] = useState(detailClicked);
  const [weekEnding, setWeekEnding] = useState('01/01/01');
  const [concise, setConcise] = useState(true);
  const [weeklySummary, setWeeklySummary] = useState([]);
  const [selectedSummary, setSelectedSummary] = useState();
  const [result, setResult] = useState('Loading..Please Wait.. ');
  const [height, setHeight] = useState(window.innerHeight);
  const [plantCode, setPlantCode] = useState('');
  useEffect(() => {
    if (viewType === 'Concise') {
      setConcise(true);
    } else {
      setConcise(false);
    }
  }, [viewType]);

  useEffect(() => {
    setClicked(detailClicked);
  }, [detailClicked]);

  useEffect(() => {
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    if (weeklySummary.length === 0) {
      loadingHandle('Loading... Please Wait...');
      getWeeklySummary(weeklySummaryFilter).then(res => {
        console.log('Weeky', res);
        loadingHandle('');
        if (res.status === 200 && !weeklySummary.length) {
          setWeeklySummary(res.data);
          setResult('');
        }
      });
    }
  }, []);

  const prime = display === 'Head' ? 'PRIME_CT' : 'PRIME_CT-PRIME_PCT';
  const cab = display === 'Head' ? 'CAB_CT' : 'CAB_CT-CAB_PCT';
  const cho = display === 'Head' ? 'CHOICE_CT' : 'CHOICE_CT-CHOICE_PCT';
  const sel = display === 'Head' ? 'SELECT_CT' : 'SELECT_CT-SELECT_PCT';
  const nor = display === 'Head' ? 'NOROLL_CT' : 'NOROLL_CT-NOROLL_PCT';
  const dkc = display === 'Head' ? 'DAR_CUT_CT' : 'DAR_CUT_CT-DAR_CUT_PCT';
  const com = display === 'Head' ? 'COMM_CT' : 'COMM_CT-COMM_PCT';
  const outs = display === 'Head' ? 'OUT_CT' : 'OUT_CT-OUT_PCT';
  const y1 = display === 'Head' ? 'Y1_CT' : 'Y1_CT-Y1_PCT';
  const y2 = display === 'Head' ? 'Y2_CT' : 'Y2_CT-Y2_PCT';
  const y3 = display === 'Head' ? 'Y3_CT' : 'Y3_CT-Y3_PCT';
  const y4 = display === 'Head' ? 'Y4_CT' : 'Y4_CT-Y4_PCT';
  const y5 = display === 'Head' ? 'Y5_CT' : 'Y5_CT-Y5_PCT';
  const w550dn = display === 'Head' ? 'W550DN_WGT' : 'W550DN_WGT-W550DN_PCT';
  const w735 = display === 'Head' ? 'W735_WGT' : 'W735_WGT-W735_PCT';
  const w950 = display === 'Head' ? 'W950_WGT' : 'W950_WGT-W950N_PCT';
  const w1000 = display === 'Head' ? 'W1000_WGT' : 'W1000_WGT-W1000_PCT';
  const w1050 =
    display === 'Head' ? 'W1050_WGT' : 'W1050_WGT-W1050_PCT';
  const w1075 = display === 'Head' ? 'W1075_WGT' : 'W1075_WGT-W1075_PCT';
  const w1100Up = display === 'Head' ? 'W1100UP_WGT' : 'W1100UP_WGT-W1100UP_PCT';

  const columns = concise
    ? [
        { header: 'HEAD', field: 'HEAD_CT' },
        //{ header: 'AVG LIVE WGT', field: 'LIVE_WEIGHT' },
        { header: 'DRS YLD%', field: 'DRESS_YIELD' },
        { header: 'PRM /CHO', field: 'prmCho' },
        { header: 'YLD GR 1/2', field: 'oneByTwo' },
        { header: 'YLD GR 4/5', field: 'fourByFive' },
      ]
    : [
        { header: 'PLT', field: 'PLANT_CODE' },
        { header: 'DRS YLD%', field: 'DRESS_YIELD' },
        { header: 'HEAD', field: 'HEAD_CT' },
        { header: 'AVG LIVE WGT', field: 'AVG_LIVE_WGT' },
        {
          header: 'PRM',
          field: display === '%' ? 'PRIME_PCT' : prime,
        },
        {
          header: 'CAB',
          field: display === '%' ? 'CAB_PCT' : cab,
        },
        {
          header: 'CHO',
          field: display === '%' ? 'CHOICE_PCT' : cho,
        },
        {
          header: 'SEL',
          field: display === '%' ? 'SELECT_PCT' : sel,
        },
        {
          header: 'NOR',
          field: display === '%' ? 'NOROLL_PCT' : nor,
        },
        {
          header: 'DKC',
          field: display === '%' ? 'DAR_CUT_PCT' : dkc,
        },
        {
          header: 'COM',
          field: display === '%' ? 'COMM_PCT' : com,
        },
        {
          header: 'OUTS',
          field: display === '%' ? 'OUT_PCT' : outs,
        },
        {
          header: '1',
          field: display === '%' ? 'Y1_PCT' : y1,
        },
        {
          header: '2',
          field: display === '%' ? 'Y2_PCT' : y2,
        },
        {
          header: '3',
          field: display === '%' ? 'Y3_PCT' : y3,
        },
        {
          header: '4',
          field: display === '%' ? 'Y4_PCT' : y4,
        },
        {
          header: '5',
          field: display === '%' ? 'Y5_PCT' : y5,
        },
        {
          header: '≤550',
          field: display === '%' ? 'W550DN_PCT' : w550dn,
        },
        {
          header: '735',
          field: display === '%' ? 'W735_PCT' : w735,
        },
        {
          header: '950',
          field: display === '%' ? 'W950N_PCT' : w950,
        },
        {
          header: '1000',
          field: display === '%' ? 'W1000_PCT' : w1000,
        },
        {
          header: '1050',
          field: display === '%' ? 'W1050_PCT' : w1050,
        },
        {
          header: '1075',
          field: display === '%' ? 'W1075_PCT' : w1075,
        },
        {
          header: '1100+',
          field: display === '%' ? 'W1100UP_PCT' : w1100Up,
        }
      ];

  const headers = [
    { title: 'LOT INFO', rowSpan: 1, colSpan: concise ? 5 : 4 },
    { title: 'GRADES', rowSpan: 1, colSpan: 8 },
    { title: 'YIELD GRADES', rowSpan: 1, colSpan: 5 },
    { title: 'WEIGHTS', rowSpan: 1, colSpan: 7 },
  ];

  const classes = useStyles();

  const setDetailClick = useCallback(
    value => {
      setClicked(value);
      setDetailClicked(value);
    },
    [setClicked, setDetailClicked],
  );

  const selectDetail = useCallback(
    detailed => {
      setPlantCode(detailed.plant);
      setWeekEnding(detailed.week);
      setSelectedSummary(detailed.summary);
      setDetailClick(true);
    },
    [setPlantCode, setWeekEnding, setDetailClick],
  );

  const detail = getUrlParam(query, 'detail');
  useEffect(() => {
    if (detail !== null) {
      // If there is a detail query param, we should display the detail grid
      selectDetail(detail);
    } else {
      // If there wasn't a detail query param, the UI should render the table
      setDetailClick(false);
    }
    // Ignore the linter error. We only want to update when the queryString changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.toString()]);

  const arrowTemplate = props => {
    if (props.CURRENT_WEEK_END !== 'Totals') {
      const handleSelectDetail = () => {
        const details = {
          plant: props.PLANT_CODE,
          week: props.CURRENT_WEEK_END,
          summary: props,
        };
        selectDetail(details);
        setUrlParam(query, history, '/Harvested', 'detail', details);
      };

      return (
        <Col style={{ padding: '0px' }}>
          <span className={'gridArrow-right'}></span>
          <Button className={classes.arrowButton} onClick={handleSelectDetail}>
            {' '}
            {'>'}{' '}
          </Button>
        </Col>
      );
    }
  };

  const dynamicHeaders = headers.map((col, i) => {
    return (
      <Column
        header={col.title}
        key={col.title}
        rowSpan={col.rowSpan}
        colSpan={col.colSpan}
        className={classes.gridTitle}
        style={{ minWidth: '90px' }}
      />
    );
  });
  const dynamicSubHeaders = columns.map((col, i) => {
    if (col.header !== 'WEEK ENDING') {
      return (
        <Column
          header={col.header}
          key={col.field}
          field={col.field}
          style={i % 2 ? { background: 'white' } : { background: '#eaeaea' }}
          className={classes.gridSubTitle}
          style={{ minWidth: '90px' }}
        />
      );
    } else {
      return null;
    }
  });
  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        headerStyle={{ width: '75px' }}
        key={col.field}
        field={col.field}
        style={i % 2 ? { background: 'white', minWidth: '90px' } : { background: '#eaeaea', minWidth: '90px' }}
        className={classes.fieldText}
      />
    );
  });

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="WEEK ENDING"
          field="weekEnding"
          key="weekEndingH"
          rowSpan={3}
          className={classes.gridWeekendTitle}
          style={{ minWidth: '90px', maxWidth: '90px' }}
        />
        {concise ? null : dynamicHeaders}
      </Row>
      <Row>{dynamicSubHeaders}</Row>
    </ColumnGroup>
  );

  const addValuethreeDigitsHead = (value1, value2, type) => {
    return `${[Number(value1) + Number(value2)]}`;
  };

  const addValuethreeDigitsPercent = (value1, value2) => {
    return `${(parseFloat(value1) + parseFloat(value2)).toFixed(1)}`;
  };

  const showValueInthreeDigitsHead = value => {
    return `${value}`;
  };

  const showValueInthreeDigitsPercent = value => {
    return `${parseFloat(value).toFixed(1)}`.substr(-4);
  };

  const sumInBothOptions = (value1, value2) => {
    const headSum = addValuethreeDigitsHead(value1[1], value2[1]);
    const percentSum = addValuethreeDigitsPercent(value1[0], value2[0]);
    return [headSum, <br />, `${percentSum}%`];
  };

  const bothOptions = (value1, value2) => {
    const tempVal = showValueInthreeDigitsHead(value1);
    const headVal = parseFloat(tempVal).toFixed(0);
    const percentVal = showValueInthreeDigitsPercent(value2);
    return [headVal, <br />, `${percentVal}%`];
  };
  
  function displayValues() {
    if (weeklySummary !== []) {
      weeklySummary.forEach((item, index) => {
        item.id = index;
        let date = item.CURRENT_WEEK_END;
        if (item.CURRENT_WEEK_END.length > 8) {
          if (item.CURRENT_WEEK_END.toString().includes('-')) {
            date = date.split('-');
          }
          if (item.CURRENT_WEEK_END.toString().includes('/')) {
            date = date.split('/');
          }
          item.CURRENT_WEEK_END = `${date[1]}/${date[2]}/${date[0].substr(-2)}`;
        }
      });
    }
    if (display === '%') {
      weeklySummary.forEach((item, index) => {
        item.id = index;
        columns.forEach(column => {
          if (
            column.field !== 'CURRENT_WEEK_END' &&
            column.field !== 'PLANT_CODE' &&
            column.field !== 'HEAD_CT' &&
            column.field !== 'DRESS_YIELD' &&
            column.field !== 'AVG_LIVE_WGT' &&
            item[column.field] !== undefined
          ) {
            item[column.field] = `${String(
              parseFloat(item[column.field]).toFixed(1),
            ).replace('%', '')}%`;
          }
        });

        item.prmCho = `${addValuethreeDigitsPercent(
          item.PRIME_PCT,
          item.CHOICE_PCT,
        )}`;
        item.oneByTwo = `${addValuethreeDigitsPercent(
          item.Y1_PCT,
          item.Y2_PCT,
        )}`;
        item.fourByFive = `${addValuethreeDigitsPercent(
          item.Y4_PCT,
          item.Y5_PCT,
        )}`;
      });
    } else if (display === 'Head') {
      weeklySummary.forEach((item, index) => {
        item.id = index;
        columns.forEach(column => {
          if (
            column.field !== 'CURRENT_WEEK_END' &&
            column.field !== 'PLANT_CODE' &&
            column.field !== 'DRESS_YIELD' &&
            column.field !== 'HEAD_CT' &&
            column.field !== 'AVG_LIVE_WGT' &&
            item[column.field] !== undefined
          ) {
            const tempVal = showValueInthreeDigitsHead(item[column.field]);
            item[column.field] = parseFloat(tempVal).toFixed(0);
          }
        });

        item.prmCho = addValuethreeDigitsHead(item.PRIME_CT, item.CHOICE_CT);
        item.oneByTwo = addValuethreeDigitsHead(item.Y1_CT, item.Y2_CT);
        item.fourByFive = addValuethreeDigitsHead(item.Y4_CT, item.Y5_CT);
      });
    } else {
      weeklySummary.forEach((item, index) => {
        item.id = index;
        if (!concise) {
          columns.forEach(column => {
            if (
              column.field !== 'CURRENT_WEEK_END' &&
              column.field !== 'PLANT_CODE' &&
              column.field !== 'DRESS_YIELD' &&
              column.field !== 'HEAD_CT' &&
              column.field !== 'AVG_LIVE_WGT' &&
              column.field !== 'dkc' &&
              column.field !== 'com'
            ) {
              const arrayColumns = column.field.split('-');
              item[column.field] = bothOptions(
                item[arrayColumns[0]],
                item[arrayColumns[1]],
              );
            }
          });
        } else {
          item.prmCho = sumInBothOptions(
            [item.PRIME_PCT, item.PRIME_CT],
            [item.CHOICE_PCT, item.CHOICE_CT],
          );
          item.oneByTwo = sumInBothOptions(
            [item.Y1_PCT, item.Y1_CT],
            [item.Y2_PCT, item.Y2_CT],
          );
          item.fourByFive = sumInBothOptions(
            [item.Y4_PCT, item.Y4_CT],
            [item.Y5_PCT, item.Y5_CT],
          );
        }
      });
    }
    if (weeklySummary.length > 0) {
      weeklySummary[0].CURRENT_WEEK_END = 'Totals';
    }
    return weeklySummary;
  }
  const rowClass = data => {
    console.log('in RowClass:', data);
    return {
      'row-accessories': data.id === 0,
    };
  };
  if (!detailClick) {
    return (
      <div
        className="datatable-style"
        style={
          isMobile
            ? {
                maxWidth: '80%',
                maxHeight: 0.6 * height,
                overflow: 'scroll',
              }
            : null
        }
      >
        {displayValues().length === 0 || display === 'Both' ? null : (
          <div className={classes.exportDiv}>
            <BrowserView>
              <ExcelExport
                className={classes.exportButton}
                columns={columns}
                data={displayValues()}
                sheetName="Harvested Data"
                fileName="History Daily Download"
                display={display}
                source={'summary'}
              />
            </BrowserView>
          </div>
        )}
        {result === '' && (
          <DataTable
            value={displayValues()}
            headerColumnGroup={headerGroup}
            autoLayout={true}
            selectionMode="single"
            onSelectionChange={e => console.log(e)}
            dataKey="id"
            rowClassName={rowClass}
            style={
              isMobile
                ? { marginBottom: 80, minWidth: '100%' }
                : { marginBottom: 10, maxWidth: '100%' }
            }
            scrollable={true}
            scrollHeight="345px"
          >
            <Column
              style={{ minWidth: '90px', maxWidth: '90px' }}
              header="WEEK ENDING"
              field="CURRENT_WEEK_END"
              key="weekEndingC"
              rowSpan={3}
              className={classes.weekText}
            />
            {dynamicColumns}
            <Column key="arrowId" body={arrowTemplate} style={{ minWidth: '90px' }}></Column>
          </DataTable>
        )}
      </div>
    );
  } else {
    return (
      <div
        style={
          isMobile
            ? {
                maxWidth: 0.95 * window.innerWidth,
                maxHeight: 0.7 * height,
                overflow: 'scroll',
              }
            : null
        }
      >
        <HarvestedDetailGrid
          display={display}
          weekEnding={weekEnding}
          detailClicked={detailClicked}
          plantCode={plantCode}
          filter={weeklySummaryFilter}
          errorHandle={errorHandle}
          loadingHandle={loadingHandle}
          selectedSummary={selectedSummary}
        />
      </div>
    );
  }
};
export default HarvestedDataTable;
