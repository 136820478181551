import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CheckFilledIcon from '@material-ui/icons/CheckCircle';
import CancelFilledIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import StatusIcon from './StatusIcon';
import { useStyles } from './Styles';
import { postUpdate } from '../../../services/api/advancedPayment';
import { getHarvestDate, getArrivalDate } from '../../../utils/helperFunctions';
import CheckBox from './Checkbox';

const headings = [
  '',
  'Plant',
  '',
  'Supplier',
  'Buyer',
  'Turn In',
  'Arrival',
  'Harvest',
  '',
  '',
];

const tabList = [
  { name: 'pending', label: 'Pending Approval', statusCodes: ['P'] },
  { name: 'approved', label: 'Approved', statusCodes: ['A', 'C'] },
  { name: 'declined', label: 'Declined', statusCodes: ['D'] },
  { name: 'all', label: 'All' },
];

const statusCodes = {
  pending: 'P',
  approved: 'P',
  approveFilled: 'P',
  approve: 'A',
  declined: 'D',
  declineFilled: 'P',
  decline: 'D',
};

var datetime = new Date();
const buttonTable = {
  approve: {
    tooltip: 'Approve',
    enabledColor: '#14C232',
    disabledColor: '#71c980',
    IconComponent: CheckIcon,
  },
  decline: {
    tooltip: 'Decline',
    enabledColor: '#FF3B30',
    disabledColor: '#fa8b84',
    IconComponent: CancelIcon,
  },
  approveFilled: {
    tooltip: 'Approved',
    enabledColor: '#14C232',
    disabledColor: '#71c980',
    IconComponent: CheckFilledIcon,
  },
  declineFilled: {
    tooltip: 'Declined',
    enabledColor: '#FF3B30',
    disabledColor: '#fa8b84',
    IconComponent: CancelFilledIcon,
  },
  unknown: {
    tooltip: 'Unknown',
    IconComponent: ErrorIcon,
  },
};

function createButton(
  classes,
  props,
  row,
  action,
  disabled,
  list,
  setList,
  showAlert,
) {
  const statusCode = statusCodes[action] ? statusCodes[action] : 'unknown';
  const buttonProps = {
    disabled: statusCode !== 'unknown' ? disabled : true,
    action: action,
    className: classes.buttonCell,
    onClick: () =>
      setRowStatuses([row], statusCode, list, setList, showAlert, props),
  };
  return <StatusButton {...buttonProps} />;
}

function matchesQuery(row, searchQuery) {
  if (searchQuery.length > 0) {
    // Only filter by search query when there is a search query
    const turnInNum = row.TURN_IN.toString();
    const plant = row.PLANT_CODE.toString();
    const supplierNumber = row.SUPPLIER_NUMBER.toString();
    const supplier = `${row.SUPPLIER_NUMBER} - ${row.SUPPLIER_NAME}`;
    const buyer = `${row.BUYER_NUMBER} - ${row.BUYER_NAME}`;
    const arrival = row.ARRIVAL_DATE.toString();
    const arrivalDate = `${arrival[1]}/${arrival[2]}/${arrival[0]}`;
    const killDate = row.KILL_DATE.split('-');
    const harvedstedDate = `${killDate[1]}/${killDate[2]}/${killDate[0]}`;
    const searchString = searchQuery.toUpperCase();
    if (
      turnInNum.includes(searchString.trim()) ||
      plant.includes(searchString.trim()) ||
      supplierNumber.includes(searchString.trim()) ||
      supplier.includes(searchString.trim()) ||
      buyer.includes(searchString.trim()) ||
      arrivalDate.includes(searchString.trim()) ||
      harvedstedDate.includes(searchString.trim())
    ) {
      return true;
    }
  } else {
    // Otherwise, do not filter by a search query
    return true;
  }
  return false;
}

function filterList(list, selectedTab, searchQuery) {
  const tab = tabList[selectedTab];
  return list.filter(row => {
    if (tab.name === 'all') {
      // Don't filter by status if we are on the all tab
      return matchesQuery(row, searchQuery);
    } else {
      // Otherwise filter by the status
      if (tab.statusCodes.includes(row.ADVANCE_STATUS)) {
        // If the selected status matches the status of the current row, filter
        // based on the search query
        return matchesQuery(row, searchQuery);
      } else {
        // Otherwise, do not display the current row
        return false;
      }
    }
  });
}

async function updateRows(rows, status) {
  const records = rows.map(row => ({
    turnIn: row.TURN_IN,
    plantCode: row.PLANT_CODE,
    supplierNumber: row.SUPPLIER_NUMBER,
  }));

  return postUpdate({
    advStatus: status,
    records: records,
  });
}

async function setRowStatuses(
  rows,
  newStatusCode,
  list,
  setList,
  showAlert,
  props,
) {
  try {
    const res = await updateRows(rows, newStatusCode);

    const successes = res.data.successfulTurnIns;
    const failures = res.data.unSuccessfulTurnIns;

    if (successes.length > 0) {
      // Update turn ins
      const newList = list.map(row => {
        if (successes.includes(row.TURN_IN)) {
          // If the current row was in the success list then we should update it
          row.ADVANCE_STATUS = newStatusCode;
          // Untick if ticked previously
          props.setIsCheck(props.isCheck.filter(obj => obj !== row.TURN_IN));
        }

        return row;
      });
      setList(newList);
    }

    if (failures.length > 0) {
      // Show a message when an update fails
      const failuresString = failures.join(', ');
      const failureMessage = `Failed to update the following turn ins: ${failuresString}`;
      showAlert('danger', 'Error', failureMessage);
    }
  } catch (err) {
    const errorMessage =
      'An error occurred while trying to update row statuses';
    showAlert('danger', 'Error', errorMessage);
    console.error(errorMessage, err);
  }
}

function TurnInListHeader(props) {
  const classes = useStyles();
  const { selectedTab } = props;

  const handleSelectAll = e => {
    //UpdateInc
    props.setIsCheckAll(!props.isCheckAll);
    //props.setIsCheck(props.list.map(li => li.TURN_IN));
    props.setIsCheck(props.list.map(li => {
      return {
        turnIn: li.TURN_IN,
        plantCode: li.PLANT_CODE,
        supplierNumber: li.SUPPLIER_NUMBER,
      }
    }));

    if (props.isCheckAll) {
      props.deselectAll();
    }
  };

  return (
    <TableHead>
      {selectedTab === 0 ? (
        <TableRow>
          <TableCell className={classes.header} align="center">
            <CheckBox
              type="checkbox"
              name="selectAll"
              id="selectAll"
              handleClick={handleSelectAll}
              isChecked={props.isCheckAll}
            />
          </TableCell>
          <TableCell className={classes.header} align="center">
            Select All
          </TableCell>
        </TableRow>
      ) : (
        <></>
      )}
      <TableRow>
        {headings.map((heading, key) => (
          <TableCell key={key} className={classes.header} align="center">
            {heading}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function NoRecordsMessage(props) {
  const classes = useStyles();
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={headings.length} className={classes.noRecordsMsg}>
          No records found.
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

function TurnInListBody(props) {
  const {
    list,
    filteredList,
    page,
    rowsPerPage,
    selectRow,
    setList,
    showAlert,
    isCheck,
    isCheckAll,
    setIsCheck,
    setIsCheckAll,
    arrivalWeek,
  } = props;
  const rows = filteredList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  if (rows.length > 0) {
    return (
      <TableBody>
        {rows.map((row, key) => (
          <TurnInListRow
            key={key}
            row={row}
            selectRow={selectRow}
            list={list}
            filteredList={filteredList}
            setList={setList}
            showAlert={showAlert}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            arrivalWeek={arrivalWeek}
          />
        ))}
      </TableBody>
    );
  } else {
    return <NoRecordsMessage />;
  }
}

function StatusButton(props) {
  const { action, disabled, className, onClick } = props;
  const { tooltip, enabledColor, disabledColor, IconComponent } = buttonTable[
    action
  ]
    ? buttonTable[action]
    : buttonTable['unknown'];
  const iconColor = disabled ? disabledColor : enabledColor;
  const iconStyle = { color: iconColor };
  return (
    <Tooltip title={tooltip} placement="top-start">
      <span>
        <IconButton disabled={disabled} className={className} onClick={onClick}>
          <IconComponent name={action} style={iconStyle} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

function TurnInListRow(props) {
  const { row, selectRow, list, setList, showAlert, arrivalWeek } = props;
  const classes = useStyles();
  const validStatus = ['A', 'D', 'P', 'C', 'U'];
  const plant = row.PLANT_CODE;
  const status = <StatusIcon status={row.ADVANCE_STATUS} />;
  const supplierNumber = row.SUPPLIER_NUMBER;
  const supplier = `${row.SUPPLIER_NUMBER} - ${row.SUPPLIER_NAME}`;
  const buyer = `${row.BUYER_NUMBER} - ${row.BUYER_NAME}`;
  const turnIn = row.TURN_IN;
  const arrival = getArrivalDate(row);
  const harvest = getHarvestDate(row);
  let actionApprove = '';
  let actionDecline = '';

  const disabled =
    row.ADVANCE_STATUS === 'U' ||
    (row.ADVANCE_STATUS === 'C' &&
      datetime.toISOString().split('T')[0] >=
      arrivalWeek.toISOString().split('T')[0]);
  let validStat = '';
  validStat = validStatus.includes(row.ADVANCE_STATUS)
    ? row.ADVANCE_STATUS
    : 'unknown';
  switch (validStat) {
    case 'D':
      actionApprove = 'approve';
      actionDecline = 'declineFilled';
      break;
    case 'A':
    case 'U':
    case 'C':
      actionApprove = 'approveFilled';
      actionDecline = 'decline';
      break;
    case 'I':
    case 'P':
      actionApprove = 'approve';
      actionDecline = 'decline';
      break;
    default:
      break;
  }
  const approve = createButton(
    classes,
    props,
    row,
    actionApprove,
    disabled,
    list,
    setList,
    showAlert,
  );
  const decline = createButton(
    classes,
    props,
    row,
    actionDecline,
    disabled,
    list,
    setList,
    showAlert,
  );
  const unknown = createButton(
    classes,
    props,
    row,
    actionDecline,
    disabled,
    list,
    setList,
    showAlert,
  );
  const handleSelect = e => {
    //UpdateInc
    const arraySelected = e.target.id.split("_");
    const selectedTurnIn = arraySelected[0];
    const selectedPlant = arraySelected[1];
    const selectedSupplier = arraySelected[2];

    props.list.forEach(list => {
      //if (parseInt(list.TURN_IN) === parseInt(e.target.id)) {
      if (
        parseInt(list.TURN_IN) === parseInt(selectedTurnIn) &&
        list.PLANT_CODE === selectedPlant &&
        list.SUPPLIER_NUMBER === selectedSupplier
      ) {
        if (e.target.checked) {
          list.TURN_IN = selectedTurnIn;
          props.setIsCheck([...props.isCheck, {
            turnIn: selectedTurnIn,
            plantCode: selectedPlant,
            supplierNumber: selectedSupplier
          }]);
        }
      }
    });
    if (!e.target.checked) {
      props.list.forEach(list => {
        //if (parseInt(list.TURN_IN) === parseInt(e.target.id)) {
        if (
          parseInt(list.TURN_IN) === parseInt(selectedTurnIn) &&
          list.PLANT_CODE === selectedPlant &&
          list.SUPPLIER_NUMBER === selectedSupplier
        ) {
          if (!e.target.checked) {
            for (var i = 0; i < props.isCheck.length; i++) {
              const newArr = props.isCheck.filter(
                //el => parseInt(selectedTurnIn) !== parseInt(el.turnIn),
                el => {
                  if (parseInt(selectedTurnIn) !== parseInt(el.turnIn) ||
                    selectedPlant !== el.plantCode ||
                    selectedSupplier !== el.supplierNumber) {
                    return el;
                  }

                },
              );
              props.setIsCheck(newArr);
            }
          }
        }
        //}
      });
    }
  };

  return (
    <>
      {row.ADVANCE_STATUS !== 'P' &&
        row.ADVANCE_STATUS !== 'A' &&
        row.ADVANCE_STATUS !== 'D' &&
        row.ADVANCE_STATUS !== 'C' &&
        row.ADVANCE_STATUS !== 'U' ? (
        <TableRow
          hover
          className={classes.tableRow}
          onClick={() => selectRow(row)}
        >
          <TableCell className={classes.header} />
          <TableCell className={classes.plantTableCell}>{plant}</TableCell>
          <TableCell>{status}</TableCell>
          <Tooltip title={supplier}>
            <TableCell className={classes.suppbuyCell}>{supplier}</TableCell>
          </Tooltip>
          <Tooltip title={buyer}>
            <TableCell className={classes.suppbuyCell}>{buyer}</TableCell>
          </Tooltip>
          <TableCell className={classes.tableCell}>{turnIn}</TableCell>
          <TableCell className={classes.tableCell}>{arrival}</TableCell>
          <TableCell className={classes.tableCell}>{harvest}</TableCell>
          <TableCell></TableCell>
          <TableCell>{unknown}</TableCell>
        </TableRow>
      ) : (
        <TableRow
          hover
          className={classes.tableRow}
          onClick={() => selectRow(row)}
        >
          {row.ADVANCE_STATUS !== 'A' &&
            row.ADVANCE_STATUS !== 'C' &&
            row.ADVANCE_STATUS !== 'U' ? (
            <TableCell className={classes.header}>
              <CheckBox
                key={turnIn}
                type="checkbox"
                id={`${turnIn}_${plant}_${supplierNumber}`}
                //id={turnIn}
                name={supplier}
                //isChecked={props.isCheck.includes(turnIn)}
                isChecked={
                  props.isCheck.some(f => f.turnIn === turnIn &&
                    f.plantCode === plant &&
                    f.supplierNumber === supplierNumber)
                }
                handleClick={handleSelect}
              />

            </TableCell>
          ) : (
            <TableCell className={classes.header} />
          )}
          <TableCell className={classes.plantTableCell}>{plant}</TableCell>
          <TableCell>{status}</TableCell>
          <Tooltip title={supplier}>
            <TableCell className={classes.suppbuyCell}>{supplier}</TableCell>
          </Tooltip>
          <Tooltip title={buyer}>
            <TableCell className={classes.suppbuyCell}>{buyer}</TableCell>
          </Tooltip>
          <TableCell className={classes.tableCell}>{turnIn}</TableCell>
          <TableCell className={classes.tableCell}>{arrival}</TableCell>
          <TableCell className={classes.tableCell}>{harvest}</TableCell>
          <TableCell>{approve}</TableCell>
          <TableCell>{decline}</TableCell>
        </TableRow>
      )}
    </>
  );
}

function TurnInListPagination(props) {
  const { list, page, rowsPerPage, setPage, setRowsPerPage } = props;

  const handlePageChange = (event, newPage) => setPage(newPage);

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={list.length}
      rowsPerPageOptions={[5, 15, 100]}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
}

function TabSwitcher(props) {
  const { selectedTab, selectTab, children, setPage } = props;
  const classes = useStyles();

  const tabs = tabList.map((tab, tabIndex) => {
    const tabProps = { key: tabIndex };
    const tabLabelProps = { className: classes.tabHeader };

    // Update tab styling if the current tab is selected
    if (tabIndex === selectedTab) {
      tabLabelProps.style = { color: 'white' };
      tabProps.className = classes.tabSelected;
    }
    tabProps.label = <span {...tabLabelProps}>{tab.label}</span>;

    return <Tab key={tabIndex} {...tabProps} />;
  });

  const tabPanel = <div role="tabpanel">{children}</div>;

  const handleTabChange = (e, tab) => {
    setPage(0);
    selectTab(tab);
    props.deselectAll();
  };

  return (
    <>
      <Tabs
        scrollButtons={'auto'}
        value={selectedTab}
        indicatorColor="primary"
        onChange={handleTabChange}
        className={classes.tabBorder}
        variant="scrollable"
      >
        {tabs}
      </Tabs>
      {tabPanel}
    </>
  );
}

export default function TurnInList(props) {
  const {
    list,
    selectRow,
    selectedTab,
    selectTab,
    searchQuery,
    setList,
    showAlert,
    isCheck,
    setIsCheck,
    isCheckAll,
    setIsCheckAll,
    arrivalWeek,
    deselectAll,
  } = props;
  const [filteredList, setFilteredList] = useState(list);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const classes = useStyles();
  /* 
  whenever seasrch query changes > reset list to first page
  */
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  useEffect(() => {
    const newFilteredList = filterList(list, selectedTab, searchQuery);
    setFilteredList(newFilteredList);
  }, [list, selectedTab, searchQuery]);

  return (
    <TabSwitcher
      setPage={setPage}
      selectedTab={selectedTab}
      selectTab={selectTab}
      deselectAll={deselectAll}
    >
      <TableContainer className={classes.turnInList} component={Paper}>
        <Table stickyHeader aria-label="sticky table" padding="none">
          <TurnInListHeader
            list={filteredList}
            page={page}
            rowsPerPage={rowsPerPage}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            selectedTab={selectedTab}
            arrivalWeek={arrivalWeek}
            selectTab={selectTab}
            deselectAll={deselectAll}
          />
          <TurnInListBody
            list={list}
            filteredList={filteredList}
            page={page}
            rowsPerPage={rowsPerPage}
            selectedTab={selectedTab}
            searchQuery={searchQuery}
            selectRow={selectRow}
            setList={setList}
            showAlert={showAlert}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            arrivalWeek={arrivalWeek}
          />
        </Table>
      </TableContainer>
      <TurnInListPagination
        list={filteredList}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </TabSwitcher>
  );
}