import React from 'react';
import { useSelector } from 'react-redux';
import TabNavLink from './TabNavLink';
import { tabListMobile } from '../../../components/TabsList';
import listItems from '../ToolbarAndDrawer/ListItems';
import { BottomAntTabs, BottomAntTab, useStylesBottom } from './Styles';
import './Home.css';
import config from '../../../config';

export default function BottomTabs(props) {
  const classes = useStylesBottom();
  const userRole = window.localStorage.getItem('userRole');

  function bottomIcon(tab, isActive) {
    if (tab.svg) {
      return (
        <tab.svgIcon
          fill={isActive ? 'white' : '#002554'}
          className={classes.tabImage}
        />
      );
    } else {
      return <img alt="" src={tab.icon} className={classes.tabImage} />;
    }
  }

  const favTabs = role => {
    const allowedTabs = [];
    if (localStorage.getItem('favorites') !== null) {
      const fav = JSON.parse(localStorage.getItem('favorites'));
      const arr = fav.filter(
        item =>
          item.user.toLowerCase() ===
          JSON.parse(
            window.localStorage.getItem('okta-token-storage'),
          ).idToken.claims.email.toLowerCase(),
      );
      if (arr.length > 0 && role.trim().length > 0) {
        arr[0].favTabs.sort((a, b) => a.category - b.category); // To arrange tabs in same order as in drawer
        arr[0].favTabs.forEach(obj => {
          let listItem = listItems.filter(ele => ele.id === obj.category);
          let filteredTabs = tabListMobile.filter(ele => {
            if (
              ele.label === listItem[0].name &&
              listItem[0].forMobile &&
              (listItem[0].allowedRoles.includes('all') ||
                listItem[0].allowedRoles.some(v =>
                  role.toLowerCase().includes(v),
                )) &&
              (listItem[0].env.includes('all') ||
                listItem[0].env.includes(config.env))
            ) {
              return true;
            }
            return false;
          });
          if (filteredTabs[0] !== undefined) {
            allowedTabs.push(filteredTabs[0]);
          }
        });
      } else {
        allowedTabs.push(tabListMobile[0]);
      }
    } else {
      allowedTabs.push(tabListMobile[0]);
    }
    return allowedTabs;
  };

  return (
    <BottomAntTabs value={0} className={classes.bottomTabRoot}>
      {favTabs(userRole).map((tab, index) => {
        return tab.finished ? (
          <TabNavLink
            to={`/${tab.label}`}
            activeClassName={classes.antTabActive}
            key={index}
          >
            {tab.finished
              ? ({ isActive }) => (
                  <BottomAntTab
                    className={classes.antTabEnabled}
                    label={
                      <span
                        className={classes.tabLabel}
                        style={
                          isActive ? { color: 'white' } : { color: '#65686B' }
                        }
                      >
                        {tab.label}
                      </span>
                    }
                    icon={bottomIcon(tab, isActive)}
                    key={index}
                  />
                )
              : null}
          </TabNavLink>
        ) : null;
      })}
    </BottomAntTabs>
  );
}
