import { makeStyles } from '@material-ui/core/styles';
const fontNova = 'Proxima Nova';
const border = '1px solid #A0A0A0';
const boderWith = '1.5px 1.5px 1.5px 0';
const useStyles = makeStyles(theme => ({
  displayText: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '19',
    lineHeight: '0',
    color: '#65686B',
    margin: '10px',
  },
  displayRB: {
    fontFamily: fontNova,
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: '19',
    lineHeight: '0',
    color: '#65686B',
  },
  buyer: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '19',
    lineHeight: '0',
    color: '#65686B',
  },
  gridTitle: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    background: '#fff',
    padding: '8px',
    border: border,
    borderWidth: boderWith,
  },
  gridWeekendTitle: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    position: 'sticky',
    background: '#fff',
    left: 0,
    //backgroundColor: '#65686B',
    paddingTop: '30px',
    border: border,
    borderTopLeftRadius: '8px',
    //borderWidth: boderWith,
    borderWidth: '1.5px 1.5px 1.5px 1.5px',
  },
  gridWeekendTitleMobile: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    //backgroundColor: '#65686B',
    paddingTop: '30px',
    border: border,
    borderTopLeftRadius: '8px',
    borderWidth: '1.5px 1.5px 1.5px 1.5px',
    height: '92.5px',
    width: '10.5px',
    position: 'sticky',
    background: '#fff',
    left: 0,
    '&:first-child': {
      padding: theme.spacing(2),
    },
  },
  gridSubTitle: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    padding: '10px',
    border: border,
    borderWidth: boderWith,
  },
  gridSubTitleOUTS: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    padding: '8px',
    border: border,
    borderWidth: boderWith,
  },
  gridSubTitleNum: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    padding: '12px 20px 12px 20px',
    border: border,
    borderWidth: boderWith,
  },
  fieldText: {
    fontFamily: fontNova,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    padding: '10px 0px 10px 0px',
    border: border,
    borderWidth: boderWith,
    transition: 'left 0.2s',
    padding: '10px 0px 10px 0px',
  },
  fieldTextMobile: {
    fontFamily: fontNova,
    fontSize: '12px',
    textAlign: 'center',
    color: '#65686B',
    fontWeight: 'bold',
    border: border,
    borderWidth: boderWith,
    height: '48px',
    width: '72px',
    transition: 'left 0.2s',
    padding: '10px 0px 10px 0px',
  },
  fieldTextDetails: {
    fontFamily: fontNova,
    fontSize: '12px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#65686B',
    padding: '13px 0px 13px 0px',
    border: border,
    borderWidth: boderWith,
    transition: 'left 0.2s',
    fontWeight: 'bold',
  },
  weekText: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    color: '#65686B',
    padding: '5px',
    position: 'sticky',
    background: '#fff',
    left: 0,
    border: border,
    borderWidth: '1.5px 1.5px 1.5px 1.5px',
  },
  weekTextMobile: {
    fontFamily: fontNova,
    fontWeight: 'bold',
    fontSize: '13px',
    textAlign: 'center',
    position: 'sticky',
    background: '#fff',
    left: 0,
    color: '#65686B',
    border: border,
    borderWidth: '1.5px 1.5px 1.5px 1.5px',
    height: '49px',
    width: '66px',
  },
  arrowButton: {
    backgroundColor: '#65686b',
    borderBottomRightRadius: '6px',
    borderTopRightRadius: '6px',
  },
  gridArrowRight: {
    backgroundColor: '#65686b',
    border: '1.5px solid #fff',
    borderWidth: '0px 2px 2px 0',
    marginBottom: '2px',
    marginLeft: '-5px',
    width: '10px',
    height: '10px',
    transform: 'rotate(315deg)',
    display: 'inline-block',
    opacity: 1,
  },
  exportButton: {
    height: '30px',
    width: '160px',
    position: 'sticky',
    marginBottom: '2px',
    background: '#65686b',
    border: '1px solid #707070',
    borderRadius: '10px',
    font: 'normal normal normal 16px/19px Proxima Nova',
    letterSpacing: '0px',
    color: '#ffffff',
    opacity: '1',
    overflow: 'hidden',
    textAlign: 'center',
  },
  exportDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'center',
  },
  select: { fontSize: 15, paddingLeft: 5, fontFamily: fontNova },
  inputTitle: { fontSize: 16, fontFamily: fontNova, color: '#65686b' },
}));

export { useStyles };
