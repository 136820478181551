import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { setAccessRole } from '../../../redux/actions/accessRole';

const ContractsBuyer = props => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    if (props.accessRole.length === 0) {
      const buyers = JSON.parse(window.localStorage.getItem('buyers'));
      props.setAccessRole(buyers);
    }
  });

  useEffect(() => {
    setRoles(props.accessRole);
  }, [props.accessRole]);

  return (
    <Grid item xs={10}>
      <Label className={'buyer'}> Buyer: </Label>
      <select className="search_categories_mobile">
        {roles.map((item, index) => {
          return (
            <option key={index}>
              {`${item.BUYER_NUMBER}-${item.BUYER_NAME}`}
            </option>
          );
        })}
      </select>
    </Grid>
  );
};
const mapStateToProps = state => ({
  accessRole: state.accessRole.accessRole,
});
const mapDispatchToProps = dispatch => ({
  setAccessRole: roles => dispatch(setAccessRole(roles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsBuyer);
