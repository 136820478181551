import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import NewsCard from '../components/Desktop/Home/AgNewsComp';
import WeatherComponent from '../components/Desktop/Home/WeatherComp';
import UpcomingComponent from '../components/Desktop/Home/UpcomingComp';
import '../components/Desktop/Home/Home.css';
// Temporary imports
import Photo1 from '../images/photo1.png';
import Photo2 from '../images/photo2.png';
import Photo3 from '../images/photo3.png';
import Photo4 from '../images/photo4.png';
import Photo5 from '../images/photo5.png';
import MainPhoto from '../images/bunk2.JPG';

export default function Home() {
  const showUnfinished = false;
  const usda = 'USDA Transparency';
  const fairnes = 'fairness key to cattle market success, say Iowa ag leaders';
  const homePaper = 'home-paper1';
  const homeRowTitle = 'home-row2Title';
  const moreArrow = 'more-arrow';

  const AgNewsArr = [
    {
      title: 'Successful Farming',
      body: 'U.S Farm Income May Suffer Due to Coronavirus Impact on … Livestock',
      date: '10/3/2020',
      photo: Photo1,
    },
    {
      title: 'ABC',
      body: 'ABC Fewer Sows Suggests Producers are Exiting Hog Business',
      date: '9/25/2020',
      photo: Photo2,
    },
    {
      title: 'XYZ Source',
      body: 'Deep love of animals, creating jobs key drivers for Kristin Peck as she leads Zoetis',
      date: '9/24/2020',
      photo: Photo3,
    },
    {
      title: 'Machinery Insider',
      body: 'The whispering giant of a tractor',
      date: '8/23/2020',
      photo: Photo4,
    },
    {
      title: usda,
      body: fairnes,
      date: '8/14/2020',
      photo: Photo5,
    },
    {
      title: usda,
      body: fairnes,
      date: '8/14/2020',
      photo: Photo1,
    },
    {
      title: usda,
      body: fairnes,
      date: '8/14/2020',
      photo: Photo2,
    },

    {
      title: 'Successful Farming',
      body: 'U.S Farm Income May Suffer Due to Coronavirus Impact on … Livestock',
      date: '10/3/2020',
      photo: Photo1,
    },
    {
      title: 'ABC',
      body: 'ABC Fewer Sows Suggests Producers are Exiting Hog Business',
      date: '9/25/2020',
      photo: Photo2,
    },
    {
      title: 'XYZ Source',
      body: 'Deep love of animals, creating jobs key drivers for Kristin Peck as she leads Zoetis',
      date: '9/24/2020',
      photo: Photo3,
    },
    {
      title: 'Machinery Insider',
      body: 'The whispering giant of a tractor',
      date: '8/23/2020',
      photo: Photo4,
    },
    {
      title: usda,
      body: fairnes,
      date: '8/14/2020',
      photo: Photo5,
    },
    {
      title: usda,
      body: fairnes,
      date: '8/14/2020',
      photo: Photo1,
    },
    {
      title: usda,
      body: fairnes,
      date: '8/14/2020',
      photo: Photo2,
    },
  ];
  const WeatherArr = [
    { date: '9/28', type: 'Rainy', moist: '51', temp: '73F' },
    { date: '9/29', type: 'Cloudy', moist: '48', temp: '70F' },
    { date: '9/30', type: 'Sunny', moist: '46', temp: '76F' },
    { date: '10/1', type: 'Rainy', moist: '51', temp: '73F' },
    { date: '10/2', type: 'Rainy', moist: '51', temp: '73F' },
  ];
  const UpcomingArr = [
    {
      date: '9/29',
      tomorrow: true,
      update: 'Lot #123 will be delivered at 012-Amerillo plant',
    },
    {
      date: '10/15',
      tomorrow: false,
      update: 'Lot #225 will be delivered at 012-Amerillo plant',
    },
  ];
  const Links = [{ name: 'DTN Insights' }, { name: 'USDA Reports' }];
  const preventDefault = event => event.preventDefault();

  const Content = {
    display: 'inline-flex',
    float: 'left',
    width: '100%',
    overflowX: 'scroll',
    img: {
      borderRadius: '10px',
    },
  };

  function RenderLinks(props) {
    return (
      <Grid item xs={12} sm={6}>
        <div className={'home-paper'}>
          <Link href="#" onClick={preventDefault}>
            <span className={'home-row1Title'}>{props.name}</span>
          </Link>
        </div>
      </Grid>
    );
  }

  return (
    <div className={'home-root'}>
      {showUnfinished ? (
        <Grid container spacing={1}>
          {Links.map((item, index) => (
            <RenderLinks {...item} key={index} />
          ))}
          <Grid item xs={12}>
            <div className={homePaper}>
              <Row>
                <Col xs={6}>
                  <Label className={homeRowTitle}>Ag News</Label>
                </Col>
                <Col>
                  <Label className={'More-text'}>
                    More
                    <span className={moreArrow}></span>
                  </Label>
                </Col>
              </Row>
              <Row>
                <div style={Content}>
                  {AgNewsArr.map((item, index) => (
                    <NewsCard {...item} key={index} />
                  ))}
                </div>
              </Row>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={homePaper}>
              <Row>
                <Col>
                  <Label className={homeRowTitle}>Weather</Label>
                </Col>
                <Col>
                  <Label className={'More-text'}>
                    More
                    <span className={moreArrow}></span>
                  </Label>
                </Col>
              </Row>
              <Row>
                {WeatherArr.map((item, index) => (
                  <WeatherComponent {...item} key={index} />
                ))}
              </Row>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={homePaper} style={{ marginBottom: 70 }}>
              <Row>
                <Col xs={6}>
                  <Label className={homeRowTitle}>Upcoming</Label>
                </Col>
                <Col>
                  <Label className={'More-text'}>
                    More
                    <span className={moreArrow}></span>
                  </Label>
                </Col>
              </Row>
              <Col>
                {UpcomingArr.map((item, index) => (
                  <UpcomingComponent {...item} key={index} />
                ))}
              </Col>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item className={'heading'} xs={12} md={6}>
            <h5> Welcome to the Cattle Buying Infomation System. </h5>
          </Grid>
          <Grid item xs={10} md={6}>
            <img
              alt=""
              src={MainPhoto}
              className="MainHome-img"
              style={{ width: '100%', height: '90%' }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
