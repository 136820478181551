import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  accessRole: [],
};

const accessRole = (state = INITIAL_STATE, action) => {
  if (action.type === actionTypes.ACCESS_ROLE_SET) {
    return {
      ...state,
      accessRole: action.payload,
    };
  } else {
    return state;
  }
};

export default accessRole;
