import currentSetting from './currentSetting';
import utils from './utils';
import contractManagement from './contractManagement';
import accessRole from './accessRole';
import suppliers from './supplierData';
import advPayment from './advPayment';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  currentSetting,
  utils,
  contractManagement,
  accessRole,
  suppliers,
  advPayment,
});

export default rootReducer;
