import React from 'react';
import ContractsDatatable from '../../Desktop/Contracts/ContractsDatatable';

export default function ContractsBody(props) {
  return (
    <div>
      {props.array.map((item, index) => (
        <ContractsDatatable
          data={item}
          value={props.value}
          key={index}
          clicked={props.clicked}
          changeClicked={props.changeClicked}
          toggleLoading={props.toggleLoading}
        />
      ))}
    </div>
  );
}
