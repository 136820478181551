import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { postUpdate } from '../../../services/api/advancedPayment';
import { findIndexInObject } from '../../../utils/helperFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function ApproveButton(props) {
  const [isDisabled, setDisable] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [titleAlert, settitleAlert] = useState('');

  const handleChange = async e => {
    settitleAlert(`Approving ${props.isCheck.length} Advances`);
    setshowAlert(true);
  };

  const onConfirm = async () => {
    setshowAlert(false);
    await setStatuses();
  };

  useEffect(() => {
    if (props.isCheck.length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [props.isCheck]);

  const updateRows = async () => {
    //UpdateInc
    const indices = [];
    const turnInObject = {
      advStatus: 'A',
      records: props.isCheck,
    };
    
    /*
    props.isCheck.forEach(item => {
      indices.push(findIndexInObject(props.list, 'TURN_IN', item));
    });
    */
    // indices.forEach(index => {
    //   turnInObject.records.push({
    //     turnIn: props.list[index].TURN_IN,
    //     plantCode: props.list[index].PLANT_CODE,
    //     supplierNumber: props.list[index].SUPPLIER_NUMBER,
    //   });
    // });

    // Call postUpdate
    return postUpdate(turnInObject);
  };

  const setStatuses = async () => {
    try {
      const res = await updateRows();
      const succeeded = res.data.successfulTurnIns;
      const failed = res.data.unSuccessfulTurnIns;

      if (succeeded.length > 0) {
        // Update turn ins
        const newList = props.list.map(row => {
          if (succeeded.includes(row.TURN_IN)) {
            row.ADVANCE_STATUS = 'A';
          }
          return row;
        });
        props.setList(newList);
        props.deselectAll();
      }
      if (failed.length > 0) {
        // Show a message when an update fails
        const failedString = failed.join(', ');
        const failureMessage = `Failed to update the following turn ins: ${failedString}`;
        props.showAlert('danger', 'Error', failureMessage);
      }
    } catch (error) {
      const errorMessage =
        'An error occurred while trying to update row statuses';
      props.showAlert('danger', 'Error', errorMessage);
      console.log(errorMessage, error);
    }
  };

  return (
    <div>
      <SweetAlert
        show={showAlert}
        showCancel
        reverseButtons
        confirmBtnText="Approve"
        type="warning"
        title={titleAlert}
        onConfirm={onConfirm}
        onCancel={() => setshowAlert(false)}
      >
        Are you sure you want to approve {props.isCheck.length} advances?
      </SweetAlert>
      <Button
        variant="contained"
        disabled={isDisabled}
        style={{
          backgroundColor: isDisabled ? '#e3e3e3' : ' green',
          color: isDisabled ? '#white' : 'white',
        }}
        onClick={e => {
          handleChange(e);
        }}
      >
        Approve Selected
      </Button>
    </div>
  );
}
