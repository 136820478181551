import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useStyles } from './Styles';
import ExcelExport from './ExcelExport';
import HarvestedDataTable from './HarvestedDataTable';
import { isMobile, BrowserView, isBrowser } from 'react-device-detect';
import '../../../../node_modules/primeicons/primeicons.css';
import { getDailyDetails } from '../../../services/api/harvested';

const HarvestedDetailDataTable = props => {
  const [height, setHeight] = useState(window.innerHeight);
  const columns = [
    { header: 'HIDDEN', field: 'hiddenCol' },
    { header: 'HVT DATE', field: 'hvtdate' },
    { header: 'PLT', field: 'plt' },
    { header: 'LOT', field: 'lot' },
    { header: 'BUYR', field: 'buyr' },
    { header: 'SUPL LOT', field: 'splot'},
    { header: 'PAY METH', field: 'paymeth' },
    { header: 'AVG LV WGT', field: 'avlw' },
    { header: 'EST DRS YLD%', field: 'estYld' },
    { header: 'ACT DRS YLD%', field: 'actYld' },
    { header: 'HEAD', field: 'head' },
    { header: 'PRM', field: 'prm' },
    { header: 'CAB', field: 'cab' },
    { header: 'CHO', field: 'cho' },
    { header: 'SEL', field: 'sel' },
    { header: 'NOR', field: 'nor' },
    { header: 'DKC', field: 'dkc' },
    { header: 'COM', field: 'com' },
    { header: 'OUTS', field: 'outs' },
    { header: '1', field: '1' },
    { header: '2', field: '2' },
    { header: '3', field: '3' },
    { header: '4', field: '4' },
    { header: '5', field: '5' },
    { header: '≤550', field: '550' },
    { header: '735', field: '735' },
    { header: '950', field: '950' },
    { header: '1000', field: '1000' },
    { header: '1050', field: '1050' },
    { header: '1075', field: '1075' },
    { header: '1100+', field: '1100' }
  ];

  const headers = [
    { title: 'LOT INFO', rowSpan: 1, colSpan: 10 },
    { title: 'GRADES', rowSpan: 1, colSpan: 8 },
    { title: 'YIELD GRADES', rowSpan: 1, colSpan: 5 },
    { title: 'WEIGHTS', rowSpan: 1, colSpan: 7 },
  ];

  const classes = useStyles();
  const [detailClicked] = useState(props.detailClicked);
  const [multiSortMeta, setMultiSortMeta] = useState([
    {
      field: 'weekEnding',
      order: -1,
    },
  ]);
  const [summaryInfo] = useState(props.selectedSummary);
  const [weekInfo, setWeekInfo] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [otherInfoPer, setOtherInfoPer] = useState([]);
  const [otherInfoBoth, setOtherInfoBoth] = useState([]);
  const [result, setResult] = useState('Loading..Please Wait.. ');
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const getMeDate = date => {
    const tempArr = date.split('-');
    return `${tempArr[1]}/${tempArr[2]}/${tempArr[0].slice(-2)}`;
  };

  const getMeFullDate = date => {
    const tempArr = date.split('/');
    if (tempArr[2].length === 2) {
      return `20${tempArr[2]}-${tempArr[0]}-${tempArr[1]}`;
    }
    return `${tempArr[2]}-${tempArr[0]}-${tempArr[1]}`;
  };

  const params = {
    plant: props.filter.Plants,
    supplierId: props.filter.Supplier,
    buyerId: props.filter.Buyer,
    weekendingDate: getMeFullDate(props.weekEnding),
  };

  const getMeAvg = (arr, field) => {
    const resMeAvg =
      arr.reduce((acc, curr) => acc + Number(curr[field]), 0) / arr.length;
    return resMeAvg.toFixed(1);
  };

  const newResArr = arr => {
    console.log('summInfo', summaryInfo);
    console.log('thearr', arr);
    const newObj = {
      WEEK_END_DATE: arr[0].WEEK_END_DATE,
      HARVEST_DATE: arr.every(
        (val, i, arra) => val.HARVEST_DATE === arra[0].HARVEST_DATE,
      )
        ? arr[0].HARVEST_DATE
        : 'Multi',
      BUYER: arr.every(
        (val, i, arra) => val.BUYER_NUMBER === arra[0].BUYER_NUMBER,
      )
        ? arr[0].BUYER_NUMBER
        : 'Multi',
      PLANT_CODE: arr.every(
        (val, i, arra) => val.PLANT_CODE === arra[0].PLANT_CODE,
      )
        ? arr[0].PLANT_CODE
        : 'Multi',
      LOT_NUMBER: arr.every(
        (val, i, arra) => val.LOT_NUMBER === arra[0].LOT_NUMBER,
      )
        ? arr[0].LOT_NUMBER
        : 'Multi',
      SUPL_LOT: arr.every(
        (val, i, arra) => val.SUPL_LOT === arra[0].SUPL_LOT,
      )
        ? arr[0].SUPL_LOT
        : 'Multi',
      PAY_METHOD: arr.every(
        (val, i, arra) => val.PAY_METHOD === arra[0].PAY_METHOD,
      )
        ? arr[0].PAY_METHOD
        : 'Multi',
      AVG_LIVE_WGT: summaryInfo.AVG_LIVE_WGT,
      EST_DRS_YLD: getMeAvg(arr, 'EST_DRS_YLD'),
      ACT_DRS_YLD: summaryInfo.DRESS_YIELD,
      CONTRACT_NUMBER: '       ',
      SPEC_CODE: '0 ',
      FORMULA_CODE: '          ',
      HEAD_CT: summaryInfo.HEAD_CT,
      PRIME_CT: summaryInfo.PRIME_CT,
      PRIME_PCT: summaryInfo.PRIME_PCT,
      CAB_CT: summaryInfo.CAB_CT,
      CAB_PCT: summaryInfo.CAB_PCT,
      CHOICE_CT: summaryInfo.CHOICE_CT,
      CHOICE_PCT: summaryInfo.CHOICE_PCT,
      NOROLL_CT: summaryInfo.NOROLL_CT,
      NOROLL_PCT: summaryInfo.NOROLL_PCT,
      SELECT_CT: summaryInfo.SELECT_CT,
      SELECT_PCT: summaryInfo.SELECT_PCT,
      DAR_CUT_CT: summaryInfo.DAR_CUT_CT,
      DAR_CUT_PCT: summaryInfo.DAR_CUT_PCT,
      COMM_CT: summaryInfo.COMM_CT,
      COMM_PCT: summaryInfo.COMM_PCT,
      OUT_CT: summaryInfo.OUT_CT,
      OUT_PCT: summaryInfo.OUT_PCT,
      Y1_CT: summaryInfo.Y1_CT,
      Y1_PCT: summaryInfo.Y1_PCT,
      Y2_CT: summaryInfo.Y2_CT,
      Y2_PCT: summaryInfo.Y2_PCT,
      Y3_CT: summaryInfo.Y3_CT,
      Y3_PCT: summaryInfo.Y3_PCT,
      Y4_CT: summaryInfo.Y4_CT,
      Y4_PCT: summaryInfo.Y4_PCT,
      Y5_CT: summaryInfo.Y5_CT,
      Y5_PCT: summaryInfo.Y5_PCT,
      W550_DN_CT: summaryInfo.W550DN_WGT,
      W550_DN_PCT: summaryInfo.W550DN_PCT,
      W735_CT: summaryInfo.W735_WGT,
      W735_PCT: summaryInfo.W735_PCT,
      W950_CT: summaryInfo.W950_WGT,
      W950_PCT: summaryInfo.W950N_PCT,
      W1000_CT: summaryInfo.W1000_WGT,
      W1000_PCT: summaryInfo.W1000_PCT,
      W1050_CT: summaryInfo.W1050_WGT,
      W1050_PCT: summaryInfo.W1050_PCT,
      W1075_CT: summaryInfo.W1075_WGT,
      W1075_PCT: summaryInfo.W1075_PCT,
      W1100_UP_CT: summaryInfo.W1100UP_WGT,
      W1100_UP_PCT: summaryInfo.W1100UP_PCT,
      BUYER_NUMBER: summaryInfo.BUYER_NUMBER,
      SUPPLIER_ID: summaryInfo.SUPPLIER_ID,
    };
    return [newObj].concat(arr);
  };

  const subHeaderClassName = name => {
    if (
      name === '1' ||
      name === '2' ||
      name === '3' ||
      name === '4' ||
      name === '5'
    ) {
      return classes.gridSubTitleNum;
    } else {
      return name === 'OUTS' ? classes.gridSubTitleOUTS : classes.gridSubTitle;
    }
  };

  useEffect(() => {
    props.loadingHandle('Loading... Please Wait...');
    getDailyDetails(params)
      .then(res => {
        const headArr = [];
        const otherInfoDetails = [];
        const otherInfoPerDetails = [];
        const otherInfoBothDetails = [];
        props.loadingHandle('');
        if (
          res.status === 200 &&
          Array.isArray(res.data) &&
          res.data.length > 0
        ) {
          console.log('details: ', res.data);
          setResult('');
          newResArr(res.data).map((obj, index) => {
            const headObj = {
              id: index,
              weekEnding: index === 0 ? `${getMeDate(obj.WEEK_END_DATE)}` : '',
              hvtdate:
                obj.HARVEST_DATE !== 'Multi'
                  ? getMeDate(obj.HARVEST_DATE)
                  : 'Multi',
              plt: obj.PLANT_CODE,
              lot: obj.LOT_NUMBER,
              buyr: obj.BUYER_NUMBER ? obj.BUYER_NUMBER : obj.BUYER,
              splot: obj.SUPL_LOT,
              paymeth: obj.PAY_METHOD,
              avlw: parseFloat(obj.AVG_LIVE_WGT).toFixed(0),
              estYld: parseFloat(obj.EST_DRS_YLD).toFixed(1),
              actYld: parseFloat(obj.ACT_DRS_YLD).toFixed(1),
              head: parseFloat(obj.HEAD_CT).toFixed(0),
              buyerNum: obj.BUYER_NUMBER ? obj.BUYER_NUMBER : obj.BUYER,
              supplierId: obj.SUPPLIER_ID,
            };
            const prmc_fixed = parseFloat(`${obj.PRIME_CT}`);
            const cabc_fixed = parseFloat(`${obj.CAB_CT}`);
            const choc_fixed = parseFloat(`${obj.CHOICE_CT}`);
            const selc_fixed = parseFloat(`${obj.SELECT_CT}`);
            const norc_fixed = parseFloat(`${obj.NOROLL_CT}`);
            const dkcc_fixed = parseFloat(`${obj.DAR_CUT_CT}`);
            const comc_fixed = parseFloat(`${obj.COMM_CT}`);
            const outsc_fixed = parseFloat(`${obj.OUT_CT}`);
            const y1c_fixed = parseFloat(`${obj.Y1_CT}`);
            const y2c_fixed = parseFloat(`${obj.Y2_CT}`);
            const y3c_fixed = parseFloat(`${obj.Y3_CT}`);
            const y4c_fixed = parseFloat(`${obj.Y4_CT}`);
            const y5c_fixed = parseFloat(`${obj.Y5_CT}`);
            const w550c_fixed = parseFloat(`${obj.W550_DN_CT}`);
            const w735c_fixed = parseFloat(`${obj.W735_CT}`);
            const w950c_fixed = parseFloat(`${obj.W950_CT}`);
            const w1000c_fixed = parseFloat(`${obj.W1000_CT}`);
            const w1050c_fixed = parseFloat(`${obj.W1050_CT}`);
            const w1075c_fixed = parseFloat(`${obj.W1075_CT}`);
            const w1100c_fixed = parseFloat(`${obj.W1100_UP_CT}`);

            const otherInfoObj = {
              id: index,
              prm: prmc_fixed.toFixed(0),
              cab: cabc_fixed.toFixed(0),
              cho: choc_fixed.toFixed(0),
              sel: selc_fixed.toFixed(0),
              nor: norc_fixed.toFixed(0),
              dkc: dkcc_fixed.toFixed(0),
              com: comc_fixed.toFixed(),
              outs: outsc_fixed.toFixed(0),
              1: y1c_fixed.toFixed(0),
              2: y2c_fixed.toFixed(0),
              3: y3c_fixed.toFixed(0),
              4: y4c_fixed.toFixed(0),
              5: y5c_fixed.toFixed(0),
              550: w550c_fixed.toFixed(0),
              735: w735c_fixed.toFixed(0),
              950: w950c_fixed.toFixed(0),
              1000: w1000c_fixed.toFixed(0),
              1050: w1050c_fixed.toFixed(0),
              1075: w1075c_fixed.toFixed(0),
              1100: w1100c_fixed.toFixed(0)
            };
            const primeFixed = parseFloat(obj.PRIME_PCT).toFixed(1);
            const cabFixed = parseFloat(obj.CAB_PCT).toFixed(1);
            const choFixed = parseFloat(obj.CHOICE_PCT).toFixed(1);
            const selFixed = parseFloat(obj.SELECT_PCT).toFixed(1);
            const norFixed = parseFloat(obj.NOROLL_PCT).toFixed(1);
            const dkcFixed = parseFloat(obj.DAR_CUT_PCT).toFixed(1);
            const comFixed = parseFloat(obj.COMM_PCT).toFixed(1);
            const outsFixed = parseFloat(obj.OUT_PCT).toFixed(1);
            const y1Fixed = parseFloat(obj.Y1_PCT).toFixed(1);
            const y2Fixed = parseFloat(obj.Y2_PCT).toFixed(1);
            const y3Fixed = parseFloat(obj.Y3_PCT).toFixed(1);
            const y4Fixed = parseFloat(obj.Y4_PCT).toFixed(1);
            const y5Fixed = parseFloat(obj.Y5_PCT).toFixed(1);
            const w550Fixed = parseFloat(obj.W550_DN_PCT).toFixed(1);
            const w735Fixed = parseFloat(obj.W735_PCT).toFixed(1);
            const w950Fixed = parseFloat(obj.W950_PCT).toFixed(1);
            const w1000Fixed = parseFloat(obj.W1000_PCT).toFixed(1);
            const w1050Fixed = parseFloat(obj.W1050_PCT).toFixed(1);
            const w1075Fixed = parseFloat(obj.W1075_PCT).toFixed(1);
            const w1100Fixed = parseFloat(obj.W1100_UP_PCT).toFixed(1);

            const otherInfoPerObj = {
              id: index,
              prm: `${primeFixed}%`,
              cab: `${cabFixed}%`,
              cho: `${choFixed}%`,
              sel: `${selFixed}%`,
              nor: `${norFixed}%`,
              dkc: `${dkcFixed}%`,
              com: `${comFixed}%`,
              outs: `${outsFixed}%`,
              1: `${y1Fixed}%`,
              2: `${y2Fixed}%`,
              3: `${y3Fixed}%`,
              4: `${y4Fixed}%`,
              5: `${y5Fixed}%`,
              550: `${w550Fixed}%`,
              735: `${w735Fixed}%`,
              950: `${w950Fixed}%`,
              1000: `${w1000Fixed}%`,
              1050: `${w1050Fixed}%`,
              1075: `${w1075Fixed}%`,
              1100: `${w1100Fixed}%`,
              buyerNum: obj.BUYER_NUMBER,
            };
            const otherInfoBothObj = {
              id: index,
              prm: [prmc_fixed.toFixed(0), <br />, `${primeFixed}%`],
              cab: [cabc_fixed.toFixed(0), <br />, `${cabFixed}%`],
              cho: [choc_fixed.toFixed(0), <br />, `${choFixed}%`],
              sel: [selc_fixed.toFixed(0), <br />, `${selFixed}%`],
              nor: [norc_fixed.toFixed(0), <br />, `${norFixed}%`],
              dkc: [dkcc_fixed.toFixed(0), <br />, `${dkcFixed}%`],
              com: [comc_fixed.toFixed(0), <br />, `${comFixed}%`],
              outs: [outsc_fixed.toFixed(0), <br />, `${outsFixed}%`],
              1: [y1c_fixed.toFixed(0), <br />, `${y1Fixed}%`],
              2: [y2c_fixed.toFixed(0), <br />, `${y2Fixed}%`],
              3: [y3c_fixed.toFixed(0), <br />, `${y3Fixed}%`],
              4: [y4c_fixed.toFixed(0), <br />, `${y4Fixed}%`],
              5: [y5c_fixed.toFixed(0), <br />, `${y5Fixed}%`],
              550: [w550c_fixed.toFixed(0), <br />, `${w550Fixed}%`],
              735: [w735c_fixed.toFixed(0), <br />, `${w735Fixed}%`],
              950: [w950c_fixed.toFixed(0), <br />, `${w950Fixed}%`],
              1000: [w1000c_fixed.toFixed(0), <br />, `${w1000Fixed}%`],
              1050: [w1050c_fixed.toFixed(0), <br />, `${w1050Fixed}%`],
              1075: [w1075c_fixed.toFixed(0), <br />, `${w1075Fixed}%`],
              1100: [w1100c_fixed.toFixed(0), <br />, `${w1100Fixed}%`]
            };
            headArr.push(headObj);
            otherInfoDetails.push(otherInfoObj);
            otherInfoPerDetails.push(otherInfoPerObj);
            otherInfoBothDetails.push(otherInfoBothObj);
            return true;
          });
          setWeekInfo(headArr);
          setOtherInfo(otherInfoDetails);
          setOtherInfoPer(otherInfoPerDetails);
          setOtherInfoBoth(otherInfoBothDetails);
        } else {
          setResult('error');
          if (res.status === 202) {
            props.errorHandle('There is currently no data for your selection.');
          } else {
            if (res.status !== undefined) {
              props.errorHandle(`Unable to fetch data. Error ${res.status}.`);
            } else {
              props.errorHandle('Unable to fetch data.');
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
        props.errorHandle(`Unable to fetch data. ${err}.`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dynamicHeaders = headers.map((col, i) => {
    return (
      <Column
        style={{ minWidth: '90px' }}
        header={col.title}
        key={col.title}
        rowSpan={col.rowSpan}
        colSpan={col.colSpan}
        className={classes.gridTitle}
      />
    );
  });

  const dynamicSubHeaders = columns.map((col, i) => {
    if (col.header !== 'WEEK ENDING' && col.header !== 'HIDDEN') {
      return (
        <Column
          style={{ minWidth: '90px', background: i % 2 ? 'white' : '#eaeaea'   }}
          header={col.header}
          key={col.header}
          field={col.field}
          sortable={
            col.header === 'HVT DATE' ||
            col.header === 'PLT' ||
            col.header === 'LOT' ||
            col.header == 'SUPL LOT' ||
            col.header === 'HEAD'
              ? true
              : false
          }
          className={subHeaderClassName(col.header)}
        />
      );
    } else {
      return (
        <Column
          header={col.header}
          key={col.field}
          field={col.field}
          style={
            isMobile
              ? { visibility: 'hidden', display: 'none' }
              : { display: 'none' }
          }
        />
      );
    }
  });

  const dynamicColumns = columns.map((col, i) => {
    if (col.field !== 'hiddenCol') {
      return (
        <Column
          headerStyle={isMobile ? '' : { width: '100px' }}
          key={col.field}
          field={col.field}
          style={
            i % 2
              ? { background: 'white', minWidth: '90px' }
              : { background: '#eaeaea', minWidth: '90px' }
          }
          className={
            isMobile ? classes.fieldTextMobile : classes.fieldTextDetails
          }
        />
      );
    } else {
      return (
        <Column key={col.field} field={col.field} style={{ display: 'none' }} />
      );
    }
  });

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="WEEK ENDING"
          field="weekEnding"
          key="weekEndingH"
          rowSpan={4}
          sortableDisabled
          style={{ minWidth: '90px', maxWidth: '90px' }}
          className={
            isMobile ? classes.gridWeekendTitleMobile : classes.gridWeekendTitle
          }
        />
        {dynamicHeaders}
      </Row>
      <Row>{dynamicSubHeaders}</Row>
    </ColumnGroup>
  );

  function displayValues() {
    console.log(weekInfo);
    if (props.display === '%') {
      return weekInfo.map(itm => ({
        ...otherInfoPer.find(item => item.id === itm.id && item),
        ...itm,
      }));
    } else if (props.display === 'Head') {
      return weekInfo.map(itm => ({
        ...otherInfo.find(item => item.id === itm.id && item),
        ...itm,
      }));
    } else if (props.display === 'Both') {
      return weekInfo.map(itm => ({
        ...otherInfoBoth.find(item => item.id === itm.id && item),
        ...itm,
      }));
    }
    return 0;
  }
  const rowClass = data => {
    return {
      'row-accessories-detail': data.id === 0,
    };
  };
  if (detailClicked) {
    return (
      result === '' && (
        <div
          className="datatable-style"
          style={
            isMobile
              ? {
                  maxWidth: '80%',
                  maxHeight: 0.6 * height,
                  overflow: 'scroll',
                }
              : null
          }
        >
          {displayValues().length === 0 || props.display === 'Both' ? null : (
            <div className={classes.exportDiv}>
              <BrowserView>
                <ExcelExport
                  className={classes.exportButton}
                  columns={columns}
                  data={displayValues()}
                  sheetName="Harvested Data"
                  fileName="History Daily Download"
                  display={props.display}
                  source={'daily'}
                />
              </BrowserView>
            </div>
          )}
          <DataTable
            value={displayValues()}
            headerColumnGroup={headerGroup}
            autoLayout={true}
            selectionMode="single"
            onSelectionChange={e => console.log(e.value.id)}
            dataKey="id"
            rowClassName={rowClass}
            sortMode="multiple"
            removableSort
            style={
              isMobile
                ? { marginBottom: 80 }
                : { marginBottom: 10, maxWidth: '100%' }
            }
            scrollable={true}
            scrollHeight="345px"
            multiSortMeta={multiSortMeta}
            onSort={e => setMultiSortMeta(e.multiSortMeta)}
          >
            <Column
              headerStyle={isMobile ? '' : { width: '90px' }}
              header="WEEK ENDING"
              field="weekEnding"
              key="weekEndingC"
              sortableDisabled
              className={isMobile ? classes.weekTextMobile : classes.weekText}
              style={{ minWidth: '90px' }}
            />
            {dynamicColumns}
          </DataTable>
        </div>
      )
    );
  } else {
    return <HarvestedDataTable display={props.display} />;
  }
};
export default HarvestedDetailDataTable;
