import * as actionTypes from './actionTypes';

import { getAccessRole } from '../../services/api/access';

export const setAccessRole = accessRole => {
  return {
    type: actionTypes.ACCESS_ROLE_SET,
    payload: accessRole,
  };
};

const getBuyers = () => {
  return dispatch => {
    getAccessRole()
      .then(res => {
        dispatch(setAccessRole(res['buyers']));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export default {
  getBuyers
};
