import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Row, Col, Label } from 'reactstrap';
import { Grid } from '@material-ui/core';
import MobilePrefilledInfo from './MobilePreffilledInfo';
import AdditionalInfo from './../../Desktop/Contracts/AdditionalInfo'; //'./MobileAdditionalInfo';
import { Prompt } from 'react-router';
import CancelIcon from '../../../images/Cancel.svg';
import { ReactComponent as ContractsIcon } from '../../../images/Contracts.svg';
import { styles } from './Styles';
import { postAdditionalInfo } from '../../../services/api/contractManagement';
import SweetAlert from 'react-bootstrap-sweetalert';

const ModalComponent = props => {
  const contractsUrl = '/Contracts/SignaturePending';
  const [page, setPage] = useState(1);
  const [isBlocking, setIsBlocking] = useState(false);
  const [showSignaturePending, setSignaturePending] = useState(false);
  const signatureOptions = ['Buyer', 'Supplier', 'Both'];
  const [disableElectronic, setDisableElectronic] = useState(true);
  const [validSubmit, setvalidSubmit] = useState(false);
  const [showAlertSubmit, setshowAlertSubmit] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [title, settitle] = useState('');
  const [confirm, setOnConfirm] = useState(() => () => {
    setshowAlert(false);
  });
  const [onCancel, setOnCancel] = useState(() => () => {
    setshowAlert(false);
  });
  const [contractDetails, setDetails] = useState({
    contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    contractType: 'S',
    email: '',
    buyerOnFile: true,
    alternateBuyerEmail: '',
    signatureMethod: 'E', // to be changed later based on discussion on CONT_PRINT_IND column
    feedlot: 'location for testing',
    comments: 'testing comments',
    currentUser: 1234, //(current buyerNumber from buyer dropdown)
    contractSpecs: [
      { optionType: 'A', optionVar: 'AS', optionVal: '345', uom: 'PCT' },
      { optionType: 'B', optionVar: 'BS', optionVal: '456', uom: 'PCT' },
      { optionType: 'C', optionVar: 'CS', optionVal: '678', uom: 'CNT' },
    ],
  });
  const [specOptions] = useState([
    { optionType: 0, optionChkd: false },
    { optionType: 1, optionChkd: false, varInput: 'CHOICE_PCT' },
    { optionType: 2, optionChkd: false },
    { optionType: 3, optionChkd: false, varInput: 'OFFGRADE_PCT' },
    { optionType: 4, optionChkd: false },
    { optionType: 5, optionChkd: false },
  ]);
  const windowPath = '/Contracts/ApprovalNeeded';
  const handleChange = (event, field) => {
    const newDetails = {
      ...contractDetails,
      [field]: event,
      contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    };
    setDetails(newDetails);
    if (!isBlocking) {
      setIsBlocking(contractDetails[field] !== event);
    }
  };
  const handleChangeSignature = (event, field) => {
    let newCopy = {};
    Object.assign(newCopy, contractDetails);
    newCopy = {
      ...newCopy,
      [field]: event,
      contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    };

    setDetails(newCopy);
  };
  function handleChangeSpecOption(event, option) {
    const index = specOptions.findIndex(item => item.optionType === option);
    const newitem = specOptions[index];
    newitem.optionChkd = event;
    specOptions.splice(index, 1, newitem);
  }

  useEffect(() => {
    if (isBlocking) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });
  function handleChangeBuyer(event, changed, field) {
    console.log('handleChangeBuyer', event, field, changed);
    setDetails({
      ...contractDetails,
      [field]: event,
      buyerOnFile: changed,
      contractNumber: props.selectedContractor.CONTRACT_NUMBER,
    });
    if (!isBlocking) {
      setIsBlocking(contractDetails[field] !== event);
    }
  }
  function validate() {
    setOnConfirm(() => () => {
      onConfirm();
    });
    setOnCancel(() => () => {
      setshowAlert(false);
    });
    setvalidSubmit(false);
    if (contractDetails.feedlot === '') {
      setshowCancel(false);
      setshowAlert(true);
      settitle('Please enter a feedlot description');
      return false;
    }
    if (
      contractDetails.buyerOnFile === false &&
      contractDetails.alternateBuyerEmail === ''
    ) {
      setshowCancel(false);
      setshowAlert(true);
      settitle('An alternate buyer is not specified');
      return false;
    }
    let optChecked = false;
    let optValue = true;
    specOptions.forEach((item, index) => {
      if (item.optionChkd) {
        optChecked = true;
        if (item.varInput) {
          if (document.getElementById(item.varInput).value === '') {
            optValue = false;
          }
        }
      }
    });
    if (!optChecked) {
      setshowCancel(true);
      setshowAlert(true);
      setvalidSubmit(true);
      settitle(
        'No Specification Option was selected.  Are you sure you want to continue?',
      );
      return false;
    }
    if (!optValue) {
      setshowCancel(false);
      setshowAlert(true);
      settitle('Please enter a value for the Spec Option Variable');
      return false;
    }
    if (contractDetails.comments === '') {
      setshowCancel(true);
      setshowAlert(true);
      setvalidSubmit(true);
      settitle(
        'No comments have been entered. Are you sure you want to continue?',
      );
      return false;
    }
    return true;
  }

  function onConfirm() {
    if (validSubmit) {
      setshowAlertSubmit(true);
    }
    setshowAlert(false);
  }

  function onConfirmSubmit() {
    console.log('Confirm Submit');
    postAdditionalInfo(contractDetails);
    props.toggle();
    setPage(1);
    setshowAlertSubmit(false);
    setIsBlocking(false);
  }

  function handleOnBackButtonPressed() {
    if (!isBlocking) {
      setPage(1);
    } else {
      setOnConfirm(() => () => {
        postAdditionalInfo(contractDetails);
        setshowAlert(false);
        setPage(1);
        setIsBlocking(false);
      });
      setOnCancel(() => () => {
        setshowAlert(false);
        setPage(1);
      });
      settitle(
        'Changes have been made since you last saved. Would you like to save them now?',
      );
      setshowCancel(true);
      setshowAlert(true);
    }
  }

  const RenderBody =
    window.location.pathname === windowPath ? (
      page === 1 ? (
        <MobilePrefilledInfo
          selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
        />
      ) : (
        <>
          <Label className="Contracts-right-title" style={{ marginBottom: 10 }}>
            {'Contract Specifications'}
            {console.log(props)}
          </Label>
          <AdditionalInfo
            value={props.value}
            tab={props.value === 0 ? false : true}
            disableElectronic={disableElectronic}
            setDisableElectronic={setDisableElectronic}
            selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
            email={'abc@cattlefarm.com'}
            signatureMethod={contractDetails.signatureMethod}
            handleChangeSignature={handleChangeSignature}
            handleChange={handleChange}
            handleChangeBuyer={handleChangeBuyer}
            handleChangeSpecOption={handleChangeSpecOption}
          />
        </>
      )
    ) : (
      <div style={{ display: 'block' }}>
        <div
          className={'divr'}
          style={
            showSignaturePending
              ? { justifyContent: 'center' }
              : { justifyContent: 'center', marginBottom: -15 }
          }
        >
          {window.location.pathname === contractsUrl && showSignaturePending ? (
            <div>
              <label
                className={'text-regular'}
                style={{
                  marginLeft: 5,
                  color: '#FF3B30',
                  float: 'left',
                }}
              >
                Signature pending on:
              </label>
              <Row>
                {signatureOptions.map(item => (
                  <Col>
                    <input
                      type="radio"
                      id="buyer"
                      defaultChecked={item === 'Buyer' ? true : false}
                      name="signature"
                      value={item}
                    />
                    <label
                      className={'text-regular'}
                      for={item}
                      style={{ marginLeft: 5 }}
                    >
                      {item}
                    </label>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Grid>
              <b style={styles.statusException}>
                {props.selectedContractor.description}
              </b>

              {props.selectedContractor.documentId === null ? (
                <Label className="Contracts-available">
                  {'Handwritten contract, no image available'}
                </Label>
              ) : (
                <button
                  className={'btn-signature-pdf'}
                  onClick={() =>
                    window.location.pathname === contractsUrl
                      ? setSignaturePending(true)
                      : null
                  }
                >
                  <ContractsIcon
                    fill="#fff"
                    stroke="#65686B"
                    className={styles.tabImage}
                  />
                  {window.location.pathname === contractsUrl
                    ? 'View Signature Pending'
                    : 'PDF'}
                </button>
              )}
            </Grid>
          )}
        </div>
        <MobilePrefilledInfo
          selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
        />
        <Label
          className="Contracts-right-title"
          style={{ marginTop: 30, marginBottom: 10 }}
        >
          {'Contract Specifications'}
        </Label>
        <AdditionalInfo
          value={props.value}
          tab={props.value === 0 ? false : true}
          disableElectronic={disableElectronic}
          setDisableElectronic={setDisableElectronic}
          selectedContractor={props.selectedContractor.CONTRACT_NUMBER}
          email={'abc@cattlefarm.com'}
          signatureMethod={contractDetails.signatureMethod}
          handleChangeSignature={handleChangeSignature}
          handleChange={handleChange}
          handleChangeBuyer={handleChangeBuyer}
          handleChangeSpecOption={handleChangeSpecOption}
        />
      </div>
    );

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} fade={true} centered>
        <ModalBody>
          <Label className="Contracts-right-title-mobile">
            Contract #{props.selectedContractor.CONTRACT_NUMBER}
            <img
              src={CancelIcon}
              alt="cancel"
              onClick={() => {
                if (!isBlocking) {
                  setPage(1);
                  setSignaturePending(false);
                  props.toggle();
                } else {
                  setOnConfirm(() => () => {
                    postAdditionalInfo(contractDetails);
                    setshowAlert(false);
                    setPage(1);
                    setIsBlocking(false);
                    setPage(1);
                    setSignaturePending(false);
                    props.toggle();
                  });
                  setOnCancel(() => () => {
                    setshowAlert(false);
                    setPage(1);
                    setIsBlocking(false);
                    setSignaturePending(false);
                    props.toggle();
                  });
                  settitle(
                    'Changes have been made since you last saved. Would you like to save them now?',
                  );
                  setshowCancel(true);
                  setshowAlert(true);
                }
              }}
              style={{ float: 'right', marginTop: -20 }}
            />
          </Label>
          <div
            style={{
              maxHeight: 0.8 * window.innerHeight,
              width: '100%',
              overflowY: 'scroll',
            }}
          >
            {RenderBody}
          </div>
          {window.location.pathname === windowPath ? (
            <div style={styles.prefilledFooter}>
              {page === 1 ? (
                <>
                  <label
                    style={{ marginLeft: 20 }}
                    className={'btn-confrm-next'}
                    onClick={() => setPage(2)}
                  >
                    Confirm and Next
                    <div className={'arrow-confirm-next'}></div>
                  </label>
                </>
              ) : (
                <>
                  <Prompt
                    when={isBlocking}
                    message={() =>
                      'You have unsaved/unsubmitted changes are you sure you want to leave the page?'
                    }
                  />
                  <label
                    style={{ marginRight: 20 }}
                    className={'btn-back'}
                    onClick={() => handleOnBackButtonPressed()}
                  >
                    <div className={'arrow-back'}></div>Back
                  </label>
                  <label
                    style={{ marginLeft: 20, marginRight: 20 }}
                    className={'btn-save'}
                    onClick={() => {
                      return;
                    }}
                  >
                    Save
                  </label>
                  <label
                    style={{ marginLeft: 20 }}
                    className={'btn-submit'}
                    onClick={() => {
                      if (validate()) {
                        setshowAlertSubmit(true);
                      }
                    }}
                  >
                    Submit
                  </label>
                  <SweetAlert
                    show={showAlert}
                    showCancel={showCancel}
                    type="warning"
                    title={title}
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    onConfirm={confirm}
                    onCancel={onCancel}
                  ></SweetAlert>
                  <SweetAlert
                    show={showAlertSubmit}
                    showCancel="true"
                    type="warning"
                    title="Please confirm you are ready to submit the contract for signature"
                    onConfirm={onConfirmSubmit}
                    onCancel={() => setshowAlertSubmit(false)}
                  ></SweetAlert>
                </>
              )}
            </div>
          ) : null}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalComponent;
