import React from 'react';
import { Tooltip } from '@material-ui/core';
import PendingIcon from '@material-ui/icons/Stop';
import ApprovedIcon from '@material-ui/icons/FiberManualRecord';
import SvgIcon from '@material-ui/core/SvgIcon';
import ErrorIcon from '@material-ui/icons/Error';
import { ReactComponent as DeclinedIcon } from '../../../images/rhombus_icon.svg';

export const iconTable = {
  P: {
    tooltip: 'Pending Approval',
    color: '#F3AF00',
    IconComponent: PendingIcon,
  },
  D: {
    tooltip: 'Declined',
    color: '#FF3B30',
    IconComponent: DeclinedIcon,
  },
  A: {
    tooltip: 'Approved',
    color: '#14C232',
    IconComponent: ApprovedIcon,
  },
  C: {
    tooltip: 'Approved - Paid',
    color: '#007bff',
    IconComponent: ApprovedIcon,
  },
  U: {
    tooltip: 'Paid in Final',
    color: '#800080',
    IconComponent: ApprovedIcon,
  },
  unknown:{
      tooltip:'Unknown',
      color: '#6c757d',
      IconComponent: ErrorIcon,
  },
};

export default function StatusIcon(props) {
  const { status } = props;
  if (Object.prototype.hasOwnProperty.call(iconTable, status)) {
    // Render the StatusIcon if we could find one for the current row's status
    const { tooltip, color, IconComponent } = iconTable[status];
    return (
      <Tooltip title={tooltip} placement="top-start">
        <SvgIcon>
          <IconComponent style={{ color: color }} />
        </SvgIcon>
      </Tooltip>
    );
  } else {
    // Render the status code as unknown if it is unrecognized

    const { tooltip, color, IconComponent } = iconTable['unknown'];
    return (
      <Tooltip title={tooltip} placement="top-start">
        <SvgIcon>
          <IconComponent style={{ color: color }} />
        </SvgIcon>
      </Tooltip>
    );
  }
}
