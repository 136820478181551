import React, { Component, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Appbar from './components/Desktop/ToolbarAndDrawer/AppBarInteraction';
import { getAccessRole } from './services/api/access';
import { loginWithRedirect } from './auth';
import './App.css';
import SweetAlert from 'react-bootstrap-sweetalert';


const App = props => {
  const { oktaAuth, authState } = useOktaAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState();

  const init = async () => {
    if (authState) {
      console.log('authState', authState);
      if (authState.isAuthenticated !== true) {
        // setOriginalUri();
        login();
        console.log('not authenticated');
      } else {
        console.log('authenticated');
        getAccessRole()
          .then(resp => {
            console.log(resp);
            window.localStorage.setItem(
              'userRole',
              JSON.stringify(resp.role),
              window.localStorage.setItem(
                'buyers',
                JSON.stringify(resp.buyers),
              ),
            );
          })
          .catch(err => {
            console.log('err.response', err.response);
            setError(err.response);
            throw err;
          });
      }
    }
  };

  useEffect(() => {
    console.log('init App.js');
    init()
      .then(() => {
        console.log('initialized');
      })
      .catch(err => {
        console.log('init error', err);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [authState]);

  const login = async () => {
    loginWithRedirect();
  };

  const logout = async () => {
    // Redirect to '/' after logout.
    oktaAuth.signOut();
  };

  if (!!error) {
    return (
      <SweetAlert
        show={!!error}
        showCancel={false}
        reverseButtons={true}
        confirmBtnText="Logout"
        type="danger"
        title={error.statusText}
        onConfirm={logout}
        onCancel={logout}
      >
        {error.data}
        <p>Contact FM Procurement for assistance</p>
      </SweetAlert>
    );
  }
  if (!authState?.isAuthenticated || !isLoaded) return null;
  return (
    <React.Fragment>
      <Appbar />
    </React.Fragment>
  );
};
export default App;
