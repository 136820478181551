import React from 'react';
import { searchicon } from './index';
import { styles } from '../Contracts/Styles';
import './AdvPayment.css';

export default function SearchBox(props) {
  const { value, onChange, ...extraProps } = props;
  return (
    <div className="search-turnin-number-container">
      <img src={searchicon} alt="lens" style={styles.searchIcon} />
      <input
        className={'search-turnin-number'}
        placeholder="Search"
        value={value}
        onChange={onChange}
        {...extraProps}
      ></input>
    </div>
  );
}
