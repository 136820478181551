import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { isBrowser } from 'react-device-detect';
import parsePrefilledInfo from '../../../utils/parsePrefilledInfo';
import ContractSection from './ContractSection';
import PropTypes from 'prop-types';
import isValidArray from '../../../utils/isValidArray';
import './Contracts.css';

import { getContractDetails } from '../../../services/api/contractManagement';

const PrefilledInfo = props => {
  const [details, setDetails] = useState([]);
  useEffect(() => {
    async function contractDataRetrevial(contractNumber) {
      try {
        if (typeof contractNumber === 'string') {
          props.setShowNextButton(false);
          const dataDetails = await getContractDetails(contractNumber);
          props.setShowNextButton(true);
          props.setShowErrorMessage(false);
          if (dataDetails.length > 0) {
            const detailData = dataDetails[0];
            Object.keys(detailData).map(function (key) {
              return isNaN(detailData[key])
                ? detailData[key].trim()
                : detailData[key];
            });
            const parsedData = parsePrefilledInfo(detailData);
            setDetails(parsedData);
          } else {
            props.setShowNextButton(false);
            props.setShowErrorMessage(true);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        props.toggleLoading(false);
      }
    }
    contractDataRetrevial(props.selectedContractor); // will need selectedContractor set from display grid to work
  }, [props.selectedContractor]);

  const data = details;
  return (
    <div
      className={'divr'}
      style={{
        justifyContent: 'center',
        width: isBrowser ? '95%' : '100%',
        margin: isBrowser ? '10px' : '0px',
      }}
    >
      {!props.showErrorMessage && (
        <Row style={{ width: isBrowser ? '77%' : '100%' }}>
          <Col xs={12}>
            {isValidArray(data)
              ? data.map((section, i) => {
                if (section.column === 0) {
                  return <ContractSection section={section} number={i + 1} />;
                }
                return null;
              })
              : null}
          </Col>
        </Row>
      )}
    </div>
  );
};
PrefilledInfo.propTypes = {
  data: PropTypes.shape({
    supplierInfo: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      address1: PropTypes.string.isRequired,
      address2: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    buyerInfo: PropTypes.shape({
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    contractInfo: PropTypes.shape({
      number: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      contractType: PropTypes.string.isRequired,
      futures: PropTypes.string.isRequired,
      delivery: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
      sex: PropTypes.string.isRequired,
      headCount: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
      plant: PropTypes.shape({
        number: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      basis: PropTypes.string.isRequired,
      dressYield: PropTypes.string.isRequired,
      insuredPrice: PropTypes.number.isRequired,
      deferred: PropTypes.bool.isRequired,
    }),
  }),
};
export default PrefilledInfo;
