import axios from 'axios';
import config from './../config';
import { authClient, loginWithRedirect } from '../auth';

const api = async () => {
  //if the user is not properly logged in okta it cannot complete the api call
  try {
    const token = await authClient.tokenManager.get('idToken');
    if (token && !authClient.tokenManager.hasExpired(token)) {
      const idToken = await token.idToken;
      return axios.create({
        baseURL: `${config.api.url}/api/v1/`,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          crossDomain: true,
          credentials: true,
          Authorization: 'Bearer ' + idToken,
        },
      });
    } else {
      // Token has been removed due to expiration or error while renewing
      loginWithRedirect();
    }
  } catch (err) {
    // Handle OAuthError/AuthSdkError
    console.error(err);
    return null;
  }
};

export default api;
