import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loader: 'none',
};

const utils = (state = initialState, action) => {
  if (action.type === actionTypes.GET_LOADER) {
    return {
      ...state,
      loader: action.loader,
    };
  } else {
    return state;
  }
};

export default utils;
