module.exports.api = {
  url: 'https://kb7bx7z0j0.execute-api.us-east-1.amazonaws.com/prod', //http://localhost:8080',
};

module.exports.okta = {
  clientId: '0oaaiu23bj0DfrM5u357',
  url: 'https://tyson.okta.com/',
  issuer: 'https://tyson.okta.com/oauth2/default',
  redirectUri: 'https://cattle-buying-info-system.tyson.com/callback', //http://localhost:3000',
};
module.exports.version = { number: '1.0' };

module.exports.env = 'prod';
