import React from 'react';
import {
  makeStyles,
  withStyles,
  Tab,
  Tabs,
  BottomNavigation,
} from '@material-ui/core';
const borderR = '10px 10px 0px 0px';
const fontNova = 'Proxima Nova';
const BottomAntTabs = withStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    // minHeight: 74.13,
    height: 64,
    // height: 36,
    backgroundColor: 'white',
    '&$selected': {
      backgroundColor: '#002554',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#002554',
    },
  },
})(BottomNavigation);

const BottomAntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#002554',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#002554',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      borderRadius: borderR,
      backgroundColor: '#002554',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    color: '#fff',
    borderRadius: borderR,
    backgroundColor: '#002554',
    fontWeight: theme.typography.fontWeightMedium,
  },
}))(props => <Tab disableRipple {...props} />);

const useStylesBottom = makeStyles(theme => ({
  tabImage: {
    height: 18,
  },
  tabLabel: {
    textAlign: 'center',
    marginBottom: 7,
    fontFamily: fontNova,
  },
  antTab: {
    flex: 7,
    fontFamily: fontNova,
  },
  antTabActive: {
    color: '#fff',
    borderRadius: borderR,
    backgroundColor: '#002554',
  },
  antTabEnabled: {
    fontSize: 12,
    color: '#002554',
  },
  antTabDisabled: {
    fontSize: 12,
    color: '#eaeaea',
  },
  bottomTabRoot: {
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: 10,
    bottom: 0,
    position: 'fixed',
  },
  bottomTabRoot2: {
    width: '110%',
    justifyContent: 'space-evenly',
    marginTop: 10,
    bottom: 0,
    left: -50,
    position: 'fixed',
  },
}));
const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #002554',
    backgroundColor: 'white',
    '&$selected': {
      backgroundColor: '#002554',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#002554',
    },
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#002554',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#002554',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      borderRadius: borderR,
      backgroundColor: '#002554',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: 'white',
    },
  },
  wrapper: {
    flexDirection: 'row',
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStylesTop = makeStyles(theme => ({
  tabImage: {
    marginRight: 10,
    height: 18,
  },
  tabLabel: {
    textAlign: 'center',
    marginBottom: 7,
    fontFamily: fontNova,
    whiteSpace: 'nowrap',
  },
  antTab: {
    flex: 7,
    marginLeft: 20,
    marginRight: 20,
    height: 55,
    fontFamily: fontNova,
  },
  antTabActive: {
    color: '#fff',
    borderRadius: borderR,
    backgroundColor: '#002554',
  },
  antTabEnabled: {
    flex: 1,
    paddingTop: 0,
    fontSize: 16,
    color: '#002554',
  },
  antTabDisabled: {
    flex: 1,
    paddingTop: 0,
    fontSize: 16,
    color: '#eaeaea',
  },
}));

export {
  BottomAntTabs,
  BottomAntTab,
  useStylesBottom,
  AntTab,
  AntTabs,
  useStylesTop,
};
