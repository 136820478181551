import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
const font = 'ProximaNova-Bold';
const fontNova = 'Proxima Nova';
const AdditionalInfoStyles = {
  category: {
    textAlign: 'start',
    fontFamily: font,
  },
  subCategory: {
    textAlign: 'end',
    fontFamily: fontNova,
  },
  rowMargin: {
    width: '82%',
    marginBottom: 40,
  },
  input: {
    marginLeft: 10,
  },
  label: {
    marginLeft: 30,
  },
  labelSm: {
    marginLeft: 10,
    fontSize: '14px',
  },
  leftFive: {
    marginLeft: 5,
  },
  textArea: {
    color: '#65686b',
    textAlign: 'left',
    marginTop: 2,
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: 50,
    marginBottom: 10,
  },
  textArea2: {
    color: '#65686b',
    textAlign: 'center',
    marginTop: 2,
    width: '100%',
    minHeight: 50,
    marginBottom: 10,
  },
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #002554',
    marginTop: 10,
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#002554',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: -3,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#002554',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      borderRadius: '10px 10px 0px 0px',
      backgroundColor: '#002554',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: 'white',
    },
  },
  wrapper: {
    flexDirection: 'row',
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const styles = {
  prefilledFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: '30px',
    marginBottom: 20,
  },
  additionalInfoFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: 20,
  },
  modal: {
    maxWidth: 1000,
    width: '100%',
    maxHeight: 500,
    height: '95%',
  },
  contentModal: {
    height: '100%',
  },
  editFilterLabel: {
    color: '#002554',
    fontFamily: font,
    marginLeft: 30,
  },
  mobileEditFilterLabel: {
    color: '#002554',
    fontFamily: font,
  },
  modalFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 30,
    marginBottom: 20,
  },
  searchIcon: {
    padding: '8px',
  },
  filterLabelContainer: {
    textDecoration: 'underline',
    fontFamily: fontNova,
    fontSize: 16,
  },
  rightComponentContainer: {
    marginTop: 10,
    marginRight: 20,
    marginBottom: 15,
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'scrollY',
    overflowX: 'hidden',
  },
  radioOptions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  showSuppliers: {
    marginTop: 11,
    width: '93%',
    overflowY: 'scroll',
    maxHeight: 300,
  },
  displayText: { marginTop: 18, marginRight: 10 },
  dateRange: { marginTop: 8, marginBottom: 8 },
  grid1Browsers: { justifyContent: 'center' },
  grid1Mobile: { marginLeft: 5 },
  selectBuyM: { marginTop: 8, width: '100%' },
  selectSuppM: { marginTop: 20, width: '100%' },
  selectBuyB: { width: 360, marginTop: 10 },
  selectSuppB: { width: 360, marginTop: 25 },
  selectPlant: { marginLeft: 50, marginRight: 65 },
  selectPlantM: { marginTop: 70 },
  PlantFormB: { marginTop: 8, width: '125%' },
  PlantFormM: { marginTop: 8, marginRight: 35 },
  rowStyle: { padding: 0 },
  contractNumber: {
    fontSize: 14,
    marginLeft: 8,
    marginTop: 8,
    fontFamily: font,
  },
  badgeStyle: { padding: 3, marginTop: 7, fontSize: 9 },
  containerLeft: { padding: 10 },
  mobileSize: { padding: 10 },
  contractContent: {
    fontFamily: 'Proxima Nova',
    marginLeft: '25px',
    fontSize: '10',
    maxLines: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  contractArrow: {
    marginRight: '10px',
    marginLeft: 'auto',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    width: '100%',
  },
  bottomTabRoot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    color: '#fff',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: '#002554',
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  tabImage: { marginRight: 5, height: 18 },
  tabLabel: { textAlign: 'center', marginTop: 5, fontFamily: fontNova },
  antTabEnabled: { paddingTop: 0, fontSize: 16, color: '#002554' },
  antTabDisabled: { paddingTop: 0, fontSize: 16, color: '#002554' },
}));

export { AdditionalInfoStyles, AntTabs, AntTab, useStyles, styles };
