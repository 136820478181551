import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Label } from 'reactstrap';
import StatusIcon, { iconTable } from './StatusIcon';
import { useStylesDetails } from './Styles';
import { getArrivalDate, getHarvestDate } from '../../../utils/helperFunctions';

function FieldListHeader(props) {
  const classes = useStylesDetails();
  const { row } = props;
  let header;
  const validStats = ['A', 'D', 'P', 'C', 'U'];
  const align = 'flex-start';
  if (row != null) {
    if (validStats.includes(row.ADVANCE_STATUS)) {
      const { ADVANCE_STATUS: statusCode, TURN_IN: turnInId } = row;
      const statusDescription = iconTable[statusCode].tooltip;
      header = (
        <Grid
          container
          item
          justifyContent="center"
          className={classes.detailGrid}
        >
          <Grid item>
            <Label className={classes.turnInField}>Turn In #{turnInId}</Label>
          </Grid>
          <Grid item className={classes.turnInStatus}>
            <StatusIcon status={statusCode} />
            <Label className={classes.detailLabel}>{statusDescription}</Label>
          </Grid>
        </Grid>
      );
    } else {
      const statusDescription = iconTable['unknown'].tooltip;
      const { TURN_IN: turnInId } = row;
      header = (
        <>
          <div className={classes.turnInStatus}>
            <StatusIcon status={'unknown'} />
            <Label className={classes.detailLabel}>{statusDescription}</Label>
          </div>
          <Grid
            container
            item
            justifyContent="center"
            style={{ alignSelf: align }}
            className={classes.detailGrid}
          >
            <Grid item>
              <Label className={classes.turnInField}>Turn In #{turnInId}</Label>
            </Grid>
          </Grid>
        </>
      );
    }
  } else {
    header = (
      <Grid
        container
        item
        justifyContent="center"
        style={{ alignSelf: align }}
        className={classes.detailGrid}
      >
        <Grid item>
          <Label className={classes.turnInField}>No turn-in selected.</Label>
        </Grid>
      </Grid>
    );
  }
  return header;
}

function FieldList(props) {
  const classes = useStylesDetails();

  const { row } = props;
  let fieldList;
  if (row != null) {
    const {
      SUPPLIER_NUMBER: supplierNumber,
      SUPPLIER_NAME: supplierName,
      BUYER_NUMBER: buyerNumber,
      BUYER_NAME: buyerName,
      PLANT_CODE: plantCode,
      LOT_NUMBER: lotNumber,
      HEAD_COUNT: headCount,
    } = row;

    const arrivalDate = getArrivalDate(row);
    const harvestDate = getHarvestDate(row);

    const fieldListTop = [
      { label: 'Supplier Number', value: supplierNumber },
      { label: 'Supplier Name', value: supplierName },
      { label: 'Buyer Number', value: buyerNumber },
      { label: 'Buyer Name', value: buyerName },
      { label: 'Plant Code', value: plantCode },
      { label: 'Arrival Date', value: arrivalDate },
      { label: 'Harvest Date', value: harvestDate },
      { label: 'Lot Number', value: lotNumber },
      { label: 'Head Count', value: headCount },
    ];

    const textFieldProps = {
      readOnly: true,
      className: classes.textField,
    };

    fieldList = (
      <Grid container item>
        {fieldListTop.map((item, index) => (
          <Grid container item className={classes.fieldRow} key={index}>
            <Grid item xs={4} className={classes.detailLabelContainer}>
              <Label className={classes.detailLabel}>{item.label}</Label>
            </Grid>
            <Grid item className={classes.detailFieldContainer}>
              <TextField
                id={`turn-in-details-field-${index}`}
                variant="filled"
                size="small"
                disabled
                className={classes.textFieldRoot}
                inputProps={textFieldProps}
                value={item.value}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  } else {
    fieldList = null;
  }

  return fieldList;
}

export default function TurnInDetails(props) {
  const classes = useStylesDetails();
  const { selectedItem: row } = props;
  return (
    <Grid container className={classes.gridDetails}>
      <Grid item xs={12}>
        <FieldListHeader row={row} />
      </Grid>
      <Grid item xs={12}>
        <FieldList row={row} />
      </Grid>
    </Grid>
  );
}
