const hostnameVal = `https://${window.location.hostname}`;
let envConfig = {};
//this switch function will verify in wich env is the app executing
//and will load all the necessary info for okta login.
console.log(hostnameVal);
switch (hostnameVal) {
  // Development
  case 'https://cattle-buying-info-system-dev.tyson.com':
    envConfig = require('./dev');
    break;
  // QA
  case 'https://cattle-buying-info-system-pre.tyson.com':
    envConfig = require('./qa');
    break;
  // Production
  case 'https://cattle-buying-info-system.tyson.com':
    envConfig = require('./prod');
    break;
  // Local
  default:
    envConfig = require('./test');
    break;
}
module.exports = envConfig;
