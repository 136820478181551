import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { Modal, ModalBody, Row, Col, Label } from 'reactstrap';
import SupplierDropdown from './SupplierDropdown';
import Calendar from './../Calendar/Calendar';
import { styles } from './Styles';
import { filtericon } from './';
import { getDefaultDelPeriod } from '../../../utils/helperFunctions';

export default function FilterModal(props) {
  const title = 'title-bold';
  const labelText = 'label-text';

  const {
    buyer,
    selectedDelPeriod,
    setDelPeriod,
    supplier,
    setSupplier,
    togglefilterModal,
  } = props;

  const [localSupplier, setLocalSupplier] = useState(supplier);
  const [localDelPeriod, setLocalDelPeriod] = useState(selectedDelPeriod);
  const [hideMonths, toggleMonths] = useState(false);

  const handleFilterUpdate = () => {
    setSupplier(localSupplier);
    setDelPeriod(localDelPeriod);
    togglefilterModal();
  };

  const handleFilterReset = () => {
    setLocalDelPeriod(getDefaultDelPeriod());
    setLocalSupplier('ALL');
    toggleMonths(false);
  };

  return (
    <Modal
      isOpen={props.filterModal}
      size="lg"
      toggle={props.togglefilterModal}
      centered={true}
      style={styles.modal}
    >
      <ModalBody>
        <Label className={title} style={styles.editFilterLabel}>
          <img src={filtericon} alt="filter" style={{ marginRight: 5 }} />
          Edit filters:
        </Label>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <Label className={title} style={{ marginTop: 10 }}>
              Delivery Period:
            </Label>
            <label className={labelText} style={{ width: '80%' }}>
              {localDelPeriod.length} selected :{' '}
              {localDelPeriod.map((item, index) => {
                return [index === 0 ? item : `, ${item}`];
              })}
            </label>
            <div style={{ display: hideMonths ? '' : 'none' }}>
              <Link
                className={title}
                color="gray"
                onClick={() => {
                  toggleMonths(false);
                }}
              >
                Edit
              </Link>
            </div>
            <div className="calendar-box-filter-completed">
              <Calendar
                selectedMonthYear={localDelPeriod}
                changeMonthYear={setLocalDelPeriod}
                hideMonths={hideMonths}
                toggleMonths={toggleMonths}
              />
            </div>
          </Col>
          <Col style={{ marginLeft: 40 }}>
            <Label className={title}>Supplier(s):</Label>
            <SupplierDropdown
              buyerNum={buyer}
              supplierNum={localSupplier}
              setSupplierNum={setLocalSupplier}
            />
          </Col>
        </Row>
      </ModalBody>
      <div style={styles.modalFooter}>
        <label className={'btn-reset-filter-modal'} onClick={handleFilterReset}>
          Reset To Default
        </label>
        <label className={'btn-done-filter-modal'} onClick={handleFilterUpdate}>
          Done
        </label>
      </div>
    </Modal>
  );
}
