import React from 'react';

export default function SuppliersDatatable(props) {
  const data = props.data;
  return (
    <div className={'suppliers-datatable-container'}>
      <span style={{ backgroundColor: 'red' }}>
        <input
          id={data.id}
          type="radio"
          name="singleSuppliers"
          value="single"
        />
        <label
          className={'label-text'}
          for={data.name}
          style={{
            fontFamily: 'Proxima Nova',
            marginLeft: 10,
            marginBottom: 5,
          }}
        >
          {data.name}
        </label>
      </span>
    </div>
  );
}
