import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import './Contracts.css';

const ContractsGroupTitle = props => (
  <Row>
    <Col>
      <Label className={isBrowser ? 'title-bold' : 'title-bold-sm'}>
        {props.title}
        {props.required ? <label style={{ color: '#FF3B30' }}>*</label> : null}
      </Label>
    </Col>
    {props.required ? null : <Col />}
  </Row>
);
ContractsGroupTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
export default ContractsGroupTitle;
