const listItems = [
  {
    id: 1,
    name: 'Home',
    expandable: false,
    linkTo: '/Home',
    onItemClick: 'Home',
    env: ['all'], // Either "all" or array of environments for which it should be available.
    allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
    addedToFavorites: false,
    forMobile: true,
    forDesktop: true,
  },
  {
    id: 2,
    name: 'Contracts',
    expandable: false,
    linkTo: '/Contracts',
    onItemClick: 'Contracts',
    env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
    allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
    addedToFavorites: false,
    forMobile: false,
    forDesktop: true,
  },
  {
    id: 3,
    name: 'Contracts',
    expandable: false,
    expanded: true,
    env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
    allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
    addedToFavorites: false,
    forMobile: true,
    forDesktop: false,
    pages: [
      {
        id: 1,
        name: 'Approval Needed',
        linkTo: '/Contracts/ApprovalNeeded',
        onItemClick: '',
        env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
        allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
        addedToFavorites: false,
        forMobile: true,
        forDesktop: false,
      },
      {
        id: 2,
        name: 'Signature Pending',
        linkTo: '/Contracts/SignaturePending',
        onItemClick: '',
        env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
        allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
        addedToFavorites: false,
        forMobile: true,
        forDesktop: false,
      },
      {
        id: 3,
        name: 'Completed',
        linkTo: '/Contracts/Completed',
        onItemClick: '',
        env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
        allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
        addedToFavorites: false,
        forMobile: true,
        forDesktop: false,
      },
      {
        id: 4,
        name: 'Exceptions',
        linkTo: '/Contracts/Exception',
        onItemClick: '',
        env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
        allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
        addedToFavorites: false,
        forMobile: true,
        forDesktop: false,
      },
    ],
  },
  {
    id: 4,
    name: 'Harvested',
    expandable: true,
    expanded: false,
    env: ['all'], // Either "all" or array of environments for which it should be available.
    allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
    addedToFavorites: false,
    forMobile: true,
    forDesktop: true,
    pages: [
      {
        id: 1,
        name: 'Historical',
        linkTo: '/Harvested',
        onItemClick: 'Harvested',
        env: ['all'], // Either "all" or array of environments for which it should be available.
        allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
        addedToFavorites: false,
        forMobile: true,
        forDesktop: true,
      },
      {
        id: 2,
        name: 'Current',
        linkTo: '/',
        onItemClick: '',
        env: ['dev', 'qa', 'test'], // Either "all" or array of environments for which it should be available.
        allowedRoles: ['all'], // Either "all" or array of roles for which it should be available.
        addedToFavorites: false,
        forMobile: false,
        forDesktop: false,
      },
    ],
  },
  {
    id: 5,
    name: 'Adv. Payment',
    expandable: false,
    linkTo: '/AdvPayment',
    onItemClick: 'Adv. Payment',
    env: ['all'], // Either "all" or array of environments for which it should be available.
    allowedRoles: ['admin', 'manager'], // Either "all" or array of roles for which it should be available.
    addedToFavorites: false,
    forMobile: false,
    forDesktop: true,
  },
];

export default listItems;
