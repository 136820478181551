// Override http config
module.exports.api = {
  url: 'http://localhost:8080',
};

module.exports.okta = {
  clientId: '0oas18ndswqCnOoto0h7',
  url: 'https://tyson-dev.oktapreview.com/',
  issuer: 'https://tyson-dev.oktapreview.com/oauth2/default',
  redirectUri: 'http://localhost:3000/callback',
  postLogoutRedirectUri: 'http://localhost:3000',
};

module.exports.version = { number: '1.0' };

module.exports.env = 'test';
