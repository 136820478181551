import api from './../index';
const getOktaToken = () => {
  if (window.localStorage.getItem('okta-token-storage')) {
    return JSON.parse(window.localStorage.getItem('okta-token-storage')).idToken
      .idToken;
  }
};

export const getHarvestedPlants = async () => {
  const res = await api();
  return res.get('Harvested/getPlants').then(res => {console.log(res.data);
    return res.data;
    
  });
};

export async function getWeeklySummary(eventBody) {
  const res = await api();
  let tokenInfo = getOktaToken();
  eventBody.Token = tokenInfo;
  const body = JSON.stringify(eventBody);
  console.log(body)
  return res.post('Harvested/getWeeklySummary', body).then(res => {
    return res;
  });
}
export async function getDailyDetails(eventBody) {
  const res = await api();
  let tokenInfo = getOktaToken();
  eventBody.token = tokenInfo;
  const body = JSON.stringify(eventBody);
  return res
    .post(`harvested/getDailyDetail`, body)
    .then(res => {
      return res;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}
