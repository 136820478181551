import * as actionTypes from './actionTypes';

//import { getSupplier } from '../../services/api/access';

export const setSuppliers = suppliers => {
  return {
    type: actionTypes.GET_SUPPLIERS,
    payload: suppliers,
  };
};

const getSuppliers = () => {
  return dispatch => {
    getSuppliers()
      .then(res => {
        dispatch(setSuppliers(res));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export default {
  getSuppliers,
};
