import React from 'react';
import { Row, Col, Badge } from 'reactstrap';
import './Contracts.css';
import { styles } from './Styles';

const ContractsDatatable = props => {
  return (
    <Row
      className={'datatable-container'}
      style={{
        backgroundColor:
          props.clicked.CONTRACT_NUMBER === props.data.CONTRACT_NUMBER
            ? '#eaeaea'
            : 'transparent',
      }}
      onClick={() => {
        props.changeClicked(props.data);
      }}
    >
      <Col xs={2} className={'customRow'} style={styles.rowStyle}>
        <label style={styles.contractNumber}>
          {props.data.CONTRACT_NUMBER}
        </label>
      </Col>
      <Col xs={props.value === 3 ? 7 : 8} className={'customRow'}>
        <div style={styles.contractContent}>
          {props.data.SUPNUMBER} <b> - </b>
          {props.data.SUPFIRSTNAME
            ? `${props.data.SUPFIRSTNAME} ${props.data.SUPLASTNAME}`
            : props.data.SUPPLIER_NAME}
        </div>
        <div style={styles.contractContent}>
          {`Del Period: ${props.data.DELIVERY_PERIOD}`}
        </div>
      </Col>
      {props.value === 3 ? (
        <Col xs={2} className={'customRow'} style={styles.rowStyle}>
          <Badge color="secondary" style={styles.badgeStyle}>
            {props.data.description}
          </Badge>
        </Col>
      ) : (
        ''
      )}
      <Col xs={1} className={'customRow'} style={styles.contractArrow}>
        <span className={'datatable-arrow'}></span>
      </Col>
    </Row>
  );
};

export default ContractsDatatable;
