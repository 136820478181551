import * as actionTypes from './actionTypes';
import { getVersion } from './../../services/api/version';

const getSetting = userSetting => {
  return {
    type: actionTypes.GET_SETTING,
    payload: userSetting,
  };
};

const setVersion = version => {
  return {
    type: actionTypes.GET_VERSION,
    version: version,
  };
};

const getVersionRedux = version => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      getVersion()
        .then(res => {
          dispatch(setVersion(res));
          resolve(version);
        })
        .catch(err => {
          console.log(err);
        });
    });
  };
};

const setSetting = setting => {
  return {
    type: actionTypes.SET_SETTING,
    payload: setting,
  };
};

export default {
  getSetting,
  setSetting,
  getVersionRedux,
};
