import api from './../index';

export const getAccessRole = async () => {
  const res = await api();
  return res.get(`access/getAccessRole`).then(res => {
    console.log('TSS and Okta Role:', res.data);
    return res.data;
  });
};

export const getBuyers = () => {
  const buyers = JSON.parse(localStorage.getItem('buyers'));
  return transformBuyers(buyers);
};

export const transformBuyers = buyers =>
  buyers.map(({ BUYER_NUMBER: buyerNum, BUYER_NAME: buyerName }) => {
    const id = buyerNum.trim();
    const label = `${id} - ${buyerName.trim()}`;

    return { id, label };
  });
