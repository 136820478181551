import { makeStyles } from '@material-ui/core/styles';
const fontNormal = 'Proxima Nova';
const styles = {
  searchIcon: {
    marginBottom: 5,
    marginLeft: 5,
  },
};
const fontBold = 'ProximaNova-Bold';
const paddingSize = '5px 0px 5px 0px';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  advPayContainer: {
    paddingTop: '8px',
  },
  tabBorder: {
    borderBottom: '1.5px solid #002554',
  },
  tabHeader: {
    textTransform: 'none',
    fontSize: '16px',
  },
  tabSelected: {
    backgroundColor: '#002554',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
  },
  turnInList: {
    maxHeight: 595,
  },
  header: {
    padding: '5px',
    textAlign: 'center',
    fontSize: 15,
    fontFamily: fontBold,
    color: '#6c757d',
  },
  plantTableCell: {
    padding: paddingSize,
    textAlign: 'center',
    fontSize: 16,
    fontFamily: fontBold,
    color: '#6c757d',
  },
  suppbuyCell: {
    padding: '5px 5px 5px 15px',
    textAlign: 'initial',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '215px',
    fontSize: '14px',
    color: '#6c757d',
    fontWeight: '600',
    fontFamily: fontNormal,
  },
  tableCell: {
    padding: paddingSize,
    textAlign: 'center',
    fontFamily: fontNormal,
    fontSize: '14px',
    fontWeight: '600',
    color: '#6c757d',
  },
  buttonCell: {
    padding: '5px',
  },
  tableRow: {
    '&$selected, &$selected:hover': {
      backgroundColor: '#eaeaea',
    },
  },
  noRecordsMsg: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:last-child': {
      padding: theme.spacing(2),
    },
  },
  detailsTitle: {
    color: '#65686b',
    fontFamily: fontBold,
    textAlign: 'center',
    marginTop: '20px',
  },
  hover: {},
  selected: {},
}));

const useStylesDetails = makeStyles(theme => ({
  gridDetails: {
    position: 'relative',
    minHeight: '500px',
    height: '92.5%',
    padding: '10px',
    border: '2px solid #eaeaea',
    borderRadius: '5px',
  },
  detailLabel: {
    fontFamily: fontNormal,
    color: '#65686b',
    marginBottom: '0px',
  },
  textFieldRoot: {
    width: '100%',
  },
  textField: {
    color: '#727171',
    padding: '5px',
  },
  turnInField: {
    marginBottom: 0,
    fontFamily: fontBold,
    color: '#6c757d',
  },
  turnInStatus: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  fieldRow: {
    justifyContent: 'center',
    margin: '10px',
  },
}));

export { useStyles, useStylesDetails, styles };
