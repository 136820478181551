import React, { useState } from 'react';
import { Grid, Link } from '@material-ui/core';
import FilterModal from './FilterModal';
import { styles } from '../../Desktop/Contracts/Styles';
import { filtericon, searchicon } from './';

export default function ContractList(props) {
  const [filterModal, setfilterModal] = useState(false);
  const togglefilterModal = () => setfilterModal(!filterModal);

  return (
    <Grid container direction="row" spacing={0}>
      <Grid item xs={12}>
        <div className="search-contract-number-container">
          <img src={searchicon} alt="lens" style={styles.searchIcon} />
          <input
            className={'search-contract-number'}
            placeholder="Search Contract Number"
          ></input>
        </div>
      </Grid>
      <Grid item xs={1}>
        <img src={filtericon} alt="filter" />
      </Grid>
      <Grid item xs={10}>
        <Link
          className="label-text"
          style={styles.filterLabelContainer}
          color="gray"
          onClick={togglefilterModal}
        >
          Filters:
          {props.selectedDelPeriod}, {props.selectedPlants},
          {props.selectedSuppliers}
        </Link>
        <FilterModal
          plantsArray={props.plantsArr}
          supplierArray={props.supplierArr}
          filterModal={filterModal}
          togglefilterModal={togglefilterModal}
        />
      </Grid>
      <Grid item>
        <label className={'label-completed-results'}>
          {`${props.lengthContract} results found`}
        </label>
      </Grid>
    </Grid>
  );
}
