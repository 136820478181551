module.exports.api = {
  url: 'https://qx9i0jeit8.execute-api.us-east-1.amazonaws.com/preprod', //http://localhost:8080',
};

module.exports.okta = {
  clientId: '0oasv1zp7hthRKEZD0h7',
  url: 'https://tyson.oktapreview.com/',
  issuer: 'https://tyson.oktapreview.com/oauth2/default',
  redirectUri: 'https://cattle-buying-info-system-pre.tyson.com', //http://localhost:3000',
};
module.exports.version = { number: '1.0' };

module.exports.env = 'qa';
