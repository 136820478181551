import settingActions from './userSetting';
import utilActions from './utils';
import contractManagementActions from './contractManagement';
import advPayment from './advPayment';

const allActions = {
  settingActions,
  utilActions,
  contractManagementActions,
  advPayment,
};

export default allActions;
