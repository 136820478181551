import * as actionTypes from './actionTypes';

import { getAccessRole } from '../../services/api/access';

export const setRole = role => {
  return {
    type: actionTypes.GET_ROLE,
    role: role,
  };
};

const getRole = () => {
  return dispatch => {
    getAccessRole()
      .then(res => {
        console.log(res.role);
        dispatch(setRole(res.role));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export default { getRole };
