import api from '../index';

export const getTurnInList = async weekEndDate => {
  const res = await api();
  return res
    .get(`advancePayment/getTurnIns/${weekEndDate}`)
    .then(resp => {
      return resp;
    })
    .catch(error => {
      return error;
    });
};

export async function postUpdate(body) {
  const res = await api();
  return res.post(`advancePayment/updateStatus`, body);
}
