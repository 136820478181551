import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import { advPayMaxDate, advPayMinDate } from '../../../utils/helperFunctions';

const maxDate = advPayMaxDate();
const minDate = advPayMinDate();

export default function AdvPayWeekPicker(props) {
  const [isWeekPickerOpen, setIsWeekPickerOpen] = useState(false);
  const { selectedWeek, setWeek } = props;

  const dateFormat = 'MM/dd/yyyy';
  const placeholderText = dateFormat.toLowerCase();

  const shouldDisableDate = date => {
    let shouldDisable = null;
    if (date > maxDate) {
      // Disable all dates greater than the max date
      shouldDisable = true;
    } else if (date.getDay() !== 6) {
      // Disable all dates that are not Saturdays
      shouldDisable = true;
    } else {
      shouldDisable = false;
    }
    return shouldDisable;
  };

  return (
    <div className="week-picker">
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <label className="title-nonbold weekending-label">Weekending:</label>
        </Grid>
        <Grid item>
          <div
            style={{ display: 'inline-block', cursor: 'pointer' }}
            className="input-bkgrdC"
            onClick={() => {
              if (!isWeekPickerOpen) setIsWeekPickerOpen(true);
            }}
          >
            <span className="pi pi-calendar" style={{ padding: 8 }}></span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                open={isWeekPickerOpen}
                onClose={() => {
                  setIsWeekPickerOpen(false);
                }}
                minDate={minDate}
                maxDate={maxDate}
                format={dateFormat}
                placeholder={placeholderText}
                value={selectedWeek}
                onChange={setWeek}
                shouldDisableDate={shouldDisableDate}
                InputProps={{ disableUnderline: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
