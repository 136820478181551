import React from 'react';
import TabNavLink from './TabNavLink';
import { AntTabs, AntTab, useStylesTop } from './Styles';

export default function TopTabs(props) {
  const classes = useStylesTop();
  return (
    <AntTabs
      value={0}
      scrollButtons="on"
      aria-label="ant example"
      className={classes.antTab}
    >
      {props.tabsList.map((tab, index) => (
        <TabNavLink
          to={`/${tab.url}`}
          activeClassName={classes.antTabActive}
          key={index}
        >
          {tab.finished
            ? ({ isActive }) => (
                <AntTab
                  className={classes.antTabEnabled}
                  label={
                    <span
                      className={classes.tabLabel}
                      style={
                        isActive ? { color: 'white' } : { color: '#002554' }
                      }
                    >
                      {tab.label}
                    </span>
                  }
                  icon={
                    tab.svg ? (
                      <tab.svgIcon
                        fill={isActive ? 'white' : '#002554'}
                        className={classes.tabImage}
                      />
                    ) : (
                      <img alt="" src={tab.icon} className={classes.tabImage} />
                    )
                  }
                  key={index}
                />
              )
            : null}
        </TabNavLink>
      ))}
    </AntTabs>
  );
}
