import base64url from 'base64url';

export function encodeBase64Json(obj) {
  const jsonStr = JSON.stringify(obj);
  return base64url.encode(jsonStr);
}

export function decodeBase64Json(base64str) {
  const jsonStr = base64url.decode(base64str);
  return JSON.parse(jsonStr);
}

export function setUrlParam(query, history, path, paramName, paramValue) {
  const encodedParam = encodeBase64Json(paramValue);
  query.set(paramName, encodedParam);
  history.push(`${path}?${query.toString()}`);
}

export function getUrlParam(query, paramName) {
  const encodedParam = query.get(paramName);
  if (encodedParam !== null) {
    return decodeBase64Json(encodedParam);
  } else {
    return null;
  }
}

export function reformatDate(input) {
  // converts dates from dd/mm/yy to dd/mm/yyyy
  const date = `${input.slice(0, 6)}20${input.slice(-2)}`;
  return date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2');
}

export function formatDate(date) {
  // converts dates from yyyy-mm-dd to dd/mm/yy
  let formattedDate = new Date(`${date}T12:00:00.000Z`);
  const formatMonth = `0${formattedDate.getMonth() + 1}`;
  const formatDay = `0${formattedDate.getDate()}`;
  formattedDate = `${
    formattedDate.getMonth() > 10 ? formattedDate.getMonth() + 1 : formatMonth
  }/${formattedDate.getDate() > 10 ? formattedDate.getDate() : formatDay}/${
    formattedDate.getFullYear() === 1
      ? '01'
      : String(formattedDate.getFullYear()).slice(2)
  }`;
  return formattedDate;
}
// Finds Index of value located in an object Array
export function findIndexInObject(obj, property, value) {
  return obj.findIndex(i => i[property] === value);
}

export function getDateFromRow(row, dateField) {
  const date = advPayDateConvert(row[dateField]);
  if (date === '1/1/01') {
    return '-';
  } else {
    return date;
  }
}

export function getHarvestDate(row) {
  return getDateFromRow(row, 'KILL_DATE');
}

export function getArrivalDate(row) {
  return getDateFromRow(row, 'ARRIVAL_DATE');
}

export function getSaturday() {
  const now = new Date();
  const today = now.getDay();
  const distToSaturday = 6 - today;
  return new Date(now.setDate(now.getDate() + distToSaturday));
}

export function advPayMaxDate() {
  const saturday = getSaturday();
  const numWeeks = 3;
  const numDays = 7 * numWeeks;
  return saturday.setDate(saturday.getDate() + numDays);
}

export function advPayMinDate() {
  const maxYearDepth = 5;
  const currentYear = new Date().getFullYear();
  const minDate = new Date();
  minDate.setFullYear(currentYear - maxYearDepth);
  return minDate;
}

export function advPayDateConvert(dateString) {
  const trimZero = number => number.replace(/^0+/, '');
  if (typeof dateString === 'string' && dateString.length === 10) {
    const [year, month, day] = dateString.split('-');
    return [trimZero(month), trimZero(day), year.slice(-2)].join('/');
  } else {
    return '1/1/01';
  }
}

function getUppercaseMonth(dateObj) {
  const locale = 'en-US';
  return dateObj.toLocaleString(locale, { month: 'short' }).toUpperCase();
}

function getTwoDigitYear(dateObj) {
  return dateObj.getFullYear().toString().slice(-2);
}

function getDeliveryPeriod(dateObj) {
  const month = getUppercaseMonth(dateObj);
  const year = getTwoDigitYear(dateObj);
  return `${month}${year}`;
}

function getNextMonth() {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date;
}

export function getDefaultDelPeriod() {
  const curDelPeriod = getDeliveryPeriod(new Date());
  const nextDelPeriod = getDeliveryPeriod(getNextMonth());

  return [curDelPeriod, nextDelPeriod];
}
