import * as actionTypes from './actionTypes';
import { getContracts } from './../../services/api/contractManagement';

const setCompletedContracts = completedContracts => {
  return {
    type: actionTypes.GET_COMPLETED_CONTRACTS,
    completedContracts: completedContracts,
  };
};

const setPendingContracts = pendingContracts => {
  return {
    type: actionTypes.GET_PENDING_CONTRACTS,
    pendingContracts: pendingContracts,
  };
};

const setApprovalContracts = approvalContracts => {
  return {
    type: actionTypes.GET_APPROVAL_CONTRACTS,
    approvalContracts: approvalContracts,
  };
};

const setExceptionContracts = exceptionContracts => {
  return {
    type: actionTypes.GET_EXCEPTION_CONTRACTS,
    exceptionContracts: exceptionContracts,
  };
};

const updateContracts = buyerNum => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      getContracts(buyerNum)
        .then(contracts => {
          dispatch(setApprovalContracts(contracts.approvalNeeded));
          dispatch(setPendingContracts(contracts.signaturePending));
          dispatch(setCompletedContracts(contracts.completed));
          dispatch(setExceptionContracts(contracts.exception));
          resolve(contracts);
        })
        .catch(err => {
          console.error(err);
        });
    });
  };
};

export default {
  updateContracts,
  setCompletedContracts,
  setPendingContracts,
  setExceptionContracts,
  setApprovalContracts,
};
