import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  role: '',
};

const role = (state = INITIAL_STATE, action) => {
  if (action.type === actionTypes.GET_ROLE) {
    return {
      ...state,
      role: action.role,
    };
  } else {
    return state;
  }
};

export default role;
