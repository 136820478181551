import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Link,
  Route,
} from 'react-router-dom';
import { Label } from 'reactstrap';
import {
  BrowserView,
  MobileView,
  isMobileOnly,
  isBrowser,
  MobileOnlyView,
  isTablet,
  TabletView,
} from 'react-device-detect';
import Home from '../../../pages/Home';
import Harvested from '../../../pages/Harvested';
import AdvPayment from '../../../pages/AdvPayment';
import Contracts from '../../../pages/Contract';
import TopTabs from '../Home/TopTabs';
import BottomTabs from '../Home/BottomTabs';
import { tabsList } from '../../TabsList';
import listItems from './ListItems';
import { mainDrawerStyles as styles, compStyles as style } from './Styles';
import { ReactComponent as SignOutSVG } from '../../../images/signout_arrow.svg';
import './ToolbarAndDrawer.css';
import config from '../../../config';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import { LoginCallback } from '@okta/okta-react';
import { some, isArray, isString } from 'lodash';

const showUnfinished = false;

const validater = (listItem, role) => {
  console.log(listItem);
  if (role == null) {
    role = window.localStorage.getItem('userRole');
  }
  if (
    (role != null && listItem.env.includes('all')) ||
    listItem.env.includes(config.env)
  ) {
    if (
      listItem.allowedRoles.includes('all') ||
      listItem.allowedRoles.some(v => role.toLowerCase().includes(v))
    ) {
      if (
        (listItem.forMobile && isMobileOnly) ||
        (listItem.forDesktop && isBrowser)
      ) {
        return true;
      }
    }
  }
  return false;
};

const favTabs = (role, handleAlert) => {
  if (role == null) {
    console.log(role, window.localStorage.getItem('userRole'));
    role = window.localStorage.getItem('userRole');
  }
  const allowedTabs = [];
  if (localStorage.getItem('favorites') !== null) {
    const fav = JSON.parse(localStorage.getItem('favorites'));
    const arr = fav.filter(
      item =>
        item.user.toLowerCase() ===
        JSON.parse(
          window.localStorage.getItem('okta-token-storage'),
        ).idToken.claims.email.toLowerCase(),
    );
    if (arr.length > 0 && role.trim().length > 0) {
      arr[0].favTabs.sort((a, b) => a.category - b.category); // To arrange tabs in same order as in drawer
      arr[0].favTabs.forEach(obj => {
        const listItem = listItems.filter(ele => ele.id === obj.category);
        const filteredTabs = tabsList.filter(ele => {
          if (
            ele.label === listItem[0].name &&
            listItem[0].forDesktop &&
            (listItem[0].allowedRoles.includes('all') ||
              listItem[0].allowedRoles.some(v =>
                role.toLowerCase().includes(v),
              )) &&
            (listItem[0].env.includes('all') ||
              listItem[0].env.includes(config.env))
          ) {
            return true;
          }
          return false;
        });
        if (filteredTabs[0] !== undefined) {
          allowedTabs.push(filteredTabs[0]);
        }
      });
    } else {
      allowedTabs.push(tabsList[0]);
    }
  } else {
    allowedTabs.push(tabsList[0]);
  }
  if (role.trim().length > 0) handleAlert(false);
  return allowedTabs;
};

const MyDrawer = withStyles(styles)(
  ({
    classes,
    variant,
    open,
    onClose,
    onItemClick,
    role,
    handleClick,
    listItems,
    handleAlert,
  }) => {
    const isAdmin = useMemo(() => {
      const r = /admin/i;
      if (isArray(role)) {
        return some(role, el => r.test(el));
      } else if (isString(role)) {
        return r.test(role);
      } else {
        return false;
      }
    }, [role]);
    const isManager = useMemo(() => {
      const r = /manager/i;
      if (isArray(role)) {
        return some(role, el => r.test(el));
      } else if (isString(role)) {
        return r.test(role);
      } else {
        return false;
      }
    }, [role]);
    console.log(role);
    console.log('isAdmin', isAdmin);
    console.log('isManager', isManager);
    return (
      <Router>
        <Drawer
          variant={variant}
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            className={clsx({
              [classes.toolbarMargin]: variant === 'persistent',
            })}
          />
          <List style={{ position: 'relative' }}>
            <ListItem style={style.settingsListItem}>
              <Label className={classes.GMText}>Welcome!</Label>
            </ListItem>
          </List>
          <List component="nav">
            {listItems.map(
              listItem =>
                validater(listItem, role) &&
                (listItem.expandable || listItem.expanded ? (
                  <>
                    <ListItem
                      tag="a"
                      onClick={() => {
                        if (listItem.expandable) {
                          handleClick(listItem, 'expansion');
                        }
                      }}
                    >
                      {!listItem.expandable &&
                        (listItem.addedToFavorites ? (
                          <IconButton
                            onClick={() =>
                              handleClick(listItem, 'star', null, true)
                            }
                          >
                            <Star style={{ color: '#65686b' }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              handleClick(listItem, 'star', null, true)
                            }
                          >
                            <StarBorder style={{ color: '#65686b' }} />
                          </IconButton>
                        ))}
                      {listItem.expandable &&
                        (listItem.expanded ? (
                          <IconButton>
                            <ExpandLess />{' '}
                          </IconButton>
                        ) : (
                          <IconButton>
                            {' '}
                            <ExpandMore />
                          </IconButton>
                        ))}
                      <ListItemText
                        primary={listItem.name.toUpperCase()}
                        style={{
                          color: '#65686b',
                          fontSize: '16px',
                        }}
                      />
                    </ListItem>
                    <Collapse
                      in={listItem.expanded}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" style={{ marginLeft: 30 }}>
                        {listItem.pages.map(
                          page =>
                            validater(page, role) && (
                              <ListItem tag="a">
                                {listItem.expandable &&
                                  (page.addedToFavorites ? (
                                    <IconButton
                                      onClick={() =>
                                        handleClick(
                                          listItem,
                                          'starPage',
                                          page,
                                          true,
                                        )
                                      }
                                    >
                                      <Star style={{ color: '#65686b' }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() =>
                                        handleClick(
                                          listItem,
                                          'starPage',
                                          page,
                                          true,
                                        )
                                      }
                                    >
                                      <StarBorder
                                        style={{ color: '#65686b' }}
                                      />
                                    </IconButton>
                                  ))}
                                <Link
                                  to={page.linkTo}
                                  onClick={onItemClick(page.onItemClick)}
                                  style={{
                                    color: '#65686b',
                                    fontSize: '16px',
                                  }}
                                >
                                  {page.name.toUpperCase()}
                                </Link>
                              </ListItem>
                            ),
                        )}
                      </List>
                    </Collapse>
                    <Divider style={style.dividerLine} />
                  </>
                ) : (
                  <>
                    <ListItem tag="a">
                      {listItem.name.toUpperCase() !== 'HOME' &&
                        (listItem.addedToFavorites ? (
                          <IconButton
                            onClick={() =>
                              handleClick(listItem, 'star', null, true)
                            }
                          >
                            <Star style={{ color: '#65686b' }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              handleClick(listItem, 'star', null, true)
                            }
                          >
                            <StarBorder style={{ color: '#65686b' }} />
                          </IconButton>
                        ))}
                      <Link
                        to={listItem.linkTo}
                        onClick={onItemClick(listItem.onItemClick)}
                        style={{
                          color: '#65686b',
                          fontSize: '16px',
                          marginLeft:
                            listItem.name.toUpperCase() === 'HOME' ? 50 : null,
                        }}
                      >
                        {listItem.name.toUpperCase()}
                      </Link>
                    </ListItem>
                  </>
                )),
            )}
          </List>
          <div style={style.footerDivider}>
            {showUnfinished && (
              <>
                <ListItem button={false} onClick={onItemClick('SETTING')}>
                  <Label className={classes.mainMenuListItemBottom}>
                    SETTING
                  </Label>
                </ListItem>
                <ListItem button={false} onClick={onItemClick('SIGN_OUT')}>
                  <Label className={classes.mainMenuListItemBottom}>
                    <SignOutSVG style={{ marginRight: 10 }} />
                    SIGN OUT
                  </Label>
                </ListItem>
              </>
            )}
            <Divider style={style.dividerLine} />
            <Label style={style.footerLabel}>Tyson CBIS Version 2.0.1</Label>
          </div>
        </Drawer>
        {isBrowser || isTablet ? (
          <div>
            <TopTabs tabsList={favTabs(role, handleAlert)} role={role} />
            <div
              style={{ width: '100%', height: 2, backgroundColor: '#002554' }}
            />
          </div>
        ) : (
          <div></div>
        )}
        <Switch>
          <Route exact path="/Home" component={Home} />
          <Route exact path="/Harvested" component={Harvested} />
          <Route exact path="/AdvPayment" component={AdvPayment} />
          {config.env !== 'prod' && (
            <Route path="/Contracts" component={Contracts} />
          )}
          {/* <Route exact path="/AdvPayment" component={AdvPayment} /> */}
          {isAdmin || isManager ? (
            <Route exact path="/AdvPayment" component={AdvPayment} />
          ) : (
            ''
          )}
          <Redirect
            exact
            from="/"
            to={
              window.localStorage.getItem('redirect_url') != null
                ? window.localStorage.getItem('redirect_url')
                : '/Home'
            }
          />
          <Route path="*">
            <Label>Work in progress...</Label>
          </Route>
          <Route path="/callback" component={LoginCallback} />
        </Switch>
        <MobileOnlyView>
          <div style={{ marginTop: 96 }}>
            <BottomTabs />
          </div>
        </MobileOnlyView>
      </Router>
    );
  },
);

export default withStyles(styles)(MyDrawer);
