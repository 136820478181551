import React from 'react';
import PropTypes from 'prop-types';
import AsyncDropdown from '../../common/AsyncDropdown';
import { getSupplierOptions } from '../../../services/api/supplier';

function SupplierDropdown({ buyerNum, supplierNum, setSupplierNum }) {
  return (
    <AsyncDropdown
      selectedOption={supplierNum}
      setSelectedOption={setSupplierNum}
      fetchOptions={getSupplierOptions}
      fetchOptionsParams={[buyerNum]}
    />
  );
}

SupplierDropdown.propTypes = {
  buyerNum: PropTypes.string.isRequired,
  supplierNum: PropTypes.string.isRequired,
  setSupplierNum: PropTypes.func.isRequired,
};

export default SupplierDropdown;
