import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function withAlert(WrappedComponent) {
  return props => {
    const [isAlertVisible, setAlertVisibility] = useState(false);

    const hideAlert = () => setAlertVisibility(false);

    const [alertProps, setAlertProps] = useState({
      title: '',
      show: false,
      onConfirm: hideAlert,
      onCancel: hideAlert,
    });

    const [alertMessage, setAlertMessage] = useState('');

    const showAlert = (type, title, message, extraProps) => {
      setAlertVisibility(true);
      setAlertMessage(message);
      setAlertProps({
        [type]: true,
        closeOnClickOutside: false,
        style: { fontSize: 16 },
        title: title,
        onConfirm: hideAlert,
        onCancel: hideAlert,
        ...extraProps,
      });
    };

    return (
      <>
        <WrappedComponent
          hideAlert={hideAlert}
          showAlert={showAlert}
          {...props}
        />
        <SweetAlert show={isAlertVisible} title="" {...alertProps}>
          {alertMessage}
        </SweetAlert>
      </>
    );
  };
}