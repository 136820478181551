import React from 'react';
import PropTypes from 'prop-types';
import AsyncDropdown from '../../common/AsyncDropdown';
import { getBuyers } from '../../../services/api/access';

function BuyerDropdown({ buyerNum, setBuyerNum }) {
  return (
    <AsyncDropdown
      selectedOption={buyerNum}
      setSelectedOption={setBuyerNum}
      fetchOptions={getBuyers}
    />
  );
}

BuyerDropdown.propTypes = {
  buyerNum: PropTypes.string.isRequired,
  setBuyerNum: PropTypes.func.isRequired,
};

export default BuyerDropdown;
