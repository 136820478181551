import React from 'react';
import {
  makeStyles,
  withStyles,
  Tab,
  BottomNavigation,
} from '@material-ui/core';
const fontNormal = 'Proxima Nova';
const radius = '10px 10px 0px 0px';

const AdditionalInfoStyles = {
  category: {
    textAlign: 'start',
    fontFamily: 'ProximaNova-Bold',
  },
  subCategory: {
    textAlign: 'end',
    fontFamily: fontNormal,
  },
  rowMargin: {
    marginTop: 3,
  },
  input: {
    marginLeft: 10,
  },
  label: {
    marginLeft: 30,
  },
  leftFive: {
    marginLeft: 5,
  },
  textArea: {
    textAlign: 'center',
    marginTop: 2,
    width: '100%',
    height: 50,
    marginBottom: 10,
  },
};
const BottomAntTabs = withStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    minHeight: 74.13,
    backgroundColor: 'white',
    '&$selected': {
      backgroundColor: '#002554',
    },
  },
})(BottomNavigation);

const BottomAntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#002554',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#002554',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      borderRadius: radius,
      backgroundColor: '#002554',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    color: '#fff',
    borderRadius: radius,
    backgroundColor: '#002554',
    fontWeight: theme.typography.fontWeightMedium,
  },
}))(props => <Tab disableRipple {...props} />);

const useStylesBottom = makeStyles(theme => ({
  tabImage: {
    height: 18,
  },
  buyerLabel: {
    fontFamily: fontNormal,
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: '1',
    color: '#65686b',
  },
  tabLabel: {
    fontFamily: fontNormal,
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '0px',
    lineHeight: '1',
  },
  tabLabelCompleted: {
    fontFamily: fontNormal,
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '0px',
    lineHeight: '1',
  },
  tabLabelHome: {
    fontFamily: fontNormal,
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '0px',
    lineHeight: '1',
    marginLeft: '19%',
  },
  antTabActive: {
    color: '#fff',
    borderRadius: radius,
    backgroundColor: '#002554',
  },
  antTabInactive: {
    color: '#fff',
    borderRadius: radius,
    backgroundColor: 'white',
  },
  antTabEnabled: {
    fontSize: 12,
    color: '#002554',
  },
  antTabDisabled: {
    fontSize: 12,
    color: '#eaeaea',
  },
}));
const styles = {
  prefilledFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 20,
  },
  tabImage: { marginRight: 5, height: 18 },
  statusException: {
    fontSize: 12,
    color: '#002554',
  },
};

export {
  AdditionalInfoStyles,
  BottomAntTabs,
  BottomAntTab,
  useStylesBottom,
  styles,
};
