/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AutocompleteDropdown from './AutocompleteDropdown';

function AsyncDropdown({
  selectedOption,
  setSelectedOption,
  fetchOptions,
  fetchOptionsParams,
}) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    (async () => {
      let dropdownOptions = [];
      try {
        dropdownOptions = await fetchOptions(...fetchOptionsParams);
        setOptions(dropdownOptions);
        if (!selectedOption) {
          setSelectedOption(dropdownOptions[0].id);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, fetchOptionsParams);

  const value = options.find(({ id }) => selectedOption === id) || null;

  return (
    <AutocompleteDropdown
      noOptionsText="Loading..."
      value={value}
      onChange={(_, { id }) => setSelectedOption(id)}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      options={options}
    />
  );
}

AsyncDropdown.defaultProps = {
  fetchOptionsParams: [],
};

AsyncDropdown.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  fetchOptionsParams: PropTypes.array,
  fetchOptions: PropTypes.func.isRequired,
};

export default AsyncDropdown;
