import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../redux/actions';
import { ContractList, ContractDetails } from '../components/Desktop/Contracts';
import '../components/Desktop/Contracts/Contracts.css';
import {
  MobileContractList,
  ContractsBuyer,
  ContractsBody,
  ModalComponent,
  BottomTabsContract,
} from '../components/Mobile/Contracts';
import { styles } from '../components/Desktop/Contracts/Styles';
import { getDefaultDelPeriod } from '../utils/helperFunctions';
import withAlert from '../components/Desktop/AdvPayment/withAlert';

const { updateContracts } = allActions.contractManagementActions;

export default withAlert(function Contract(props) {
  const [buyer, setBuyer] = useState();
  const [supplier, setSupplier] = useState('ALL');
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState(0);
  const [selectedDelPeriod, setDelPeriod] = useState(getDefaultDelPeriod());
  const [showSignaturePending, setSignaturePending] = useState(false);
  const toggle = () => setModal(!modal);
  const { showAlert, hideAlert } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
    setSelectedContractor(0);
    setSignaturePending(false);
  };
  const changeClicked = (value = 'value') => {
    setPage(1);
    setSelectedContractor(value);
    setSignaturePending(false);
    toggleLoading(true);
  };
  const toggleLoading = (val = false) => {
    if (val) {
      showAlert('info', 'Please wait', 'Loading Contract...', {
        showConfirm: false,
      });
    } else {
      hideAlert();
    }
  };

  const contractManagement = useSelector(state => state.contractManagement);
  const dispatch = useDispatch();
  const contractLength = contractManagement.completedContracts
    ? contractManagement.completedContracts.length
    : 0;

  const contractsException =
    window.location.pathname === '/Contracts/Exception'
      ? contractManagement.exceptionContracts
      : contractManagement.approvalContracts;
  const contractsPending =
    window.location.pathname === '/Contracts/SignaturePending'
      ? contractManagement.pendingContracts
      : contractsException;

  // Update pages whenever the buyer number changes
  useEffect(() => {
    if (buyer?.length) {
      dispatch(updateContracts(buyer));
    }
  }, [dispatch, buyer]);

  return (
    <>
      <BrowserView>
        <Grid container>
          <Grid style={styles.containerLeft} item xs={4}>
            <ContractList
              approvalContracts={contractManagement.approvalContracts}
              pendingContracts={contractManagement.pendingContracts}
              completedContracts={contractManagement.completedContracts}
              exceptionContracts={contractManagement.exceptionContracts}
              buyer={buyer}
              setBuyer={setBuyer}
              supplier={supplier}
              setSupplier={setSupplier}
              value={value}
              handleChange={handleChange}
              selectedContractor={selectedContractor}
              selectedDelPeriod={selectedDelPeriod}
              setDelPeriod={setDelPeriod}
              changeClicked={changeClicked}
              toggleLoading={toggleLoading}
              allActions={allActions.contractManagementActions}
            />
          </Grid>
          <Grid item xs={8}>
            <div
              className={'Rectangle215'}
              style={styles.rightComponentContainer}
            >
              <ContractDetails
                value={value}
                page={page}
                selectedContractor={selectedContractor}
                setPage={setPage}
                modal={modal}
                toggle={toggle}
                toggleLoading={toggleLoading}
                showSignaturePending={showSignaturePending}
                setSignaturePending={setSignaturePending}
              />
            </div>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid style={styles.mobileSize}>
          <Grid
            id={'shobena'}
            container
            alignItems="flex-end"
            justify="flex-start"
            spacing={1}
          >
            <ContractsBuyer />
            {window.location.pathname === '/Contracts/Completed' ||
              window.location.pathname === '/Contracts/Exception' ? (
              <Grid item style={{ marginLeft: '12px' }} xs={10}>
                <MobileContractList
                  supplierArr={contractManagement.supplierArr}
                  selectedDelPeriod={selectedDelPeriod}
                  selectedContractor={selectedContractor}
                  lengthContract={contractLength}
                />
              </Grid>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item style={{ minHeight: '700px' }}>
            <ContractsBody
              array={
                window.location.pathname === '/Contracts/Completed'
                  ? contractManagement.completedContracts
                  : contractsPending
              }
              clicked={selectedContractor}
              changeClicked={item => {
                changeClicked(item);
                toggle();
              }}
              toggleLoading={toggleLoading}
            />
            <ModalComponent
              value={value}
              isOpen={modal}
              toggle={toggle}
              modalBod={props.tabValue}
              modTitle={selectedContractor}
              selectedContractor={selectedContractor}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ width: '100%', position: 'fixed', bottom: 0 }}>
              <BottomTabsContract
                arraySize={[
                  0,
                  contractManagement?.approvalContracts?.length,
                  contractManagement?.pendingContracts?.length,
                  // contractManagement.exceptions.length,
                  0,
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </MobileView>
    </>
  );
});
