const styles = {
  calendarFilterFooter: {
    fontFamily: 'Proxima Nova',
    marginBottom: 10,
  },
  calendarDoneFooter: {
    color: '#fff',
    fontFamily: 'Proxima Nova',
    marginBottom: 10,
    background: '#65686b 0% 0% no-repeat padding-box',
    marginLeft: '100px',
    width: '58px',
    height: '30px',
    textAlign: 'center',
    padding: '3px',
    borderRadius: '10px',
    borderStyle: 'none',
  },
};

export { styles };
