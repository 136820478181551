import React from 'react';
import { ReactComponent as ContractsIcon } from '../../../images/Contracts.svg';
import { useStyles } from './Styles';
import { getPdf } from '../../../services/api/document';

const openPdf = async (docId, dispatch, allActions) => {
  dispatch(allActions.utilActions.getLoaderRedux('flex'));
  try {
    const res = await getPdf(docId);
    if (res.length) {
      const document64 = res[0].document; // https://www.pdftron.com/documentation/web/guides/basics/open/base64/
      console.log(typeof document64);
      const binaryString = window.atob(document64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const documentBlob = new Blob([bytes], { type: 'application/pdf' });
      //Build a URL from the file
      const documentURL = URL.createObjectURL(documentBlob);
      //Open the URL on new Window
      window.open(documentURL);
    } else {
      console.log('This is the error:', res);
    }
  } catch (err) {
    console.log('This is the error:', err);
  }

  dispatch(allActions.utilActions.getLoaderRedux('none'));
};
const Pdf = ({ docId, dispatch, allActions, status }) => {
  const classes = useStyles();
  return (
    <div className={'divr'} style={{ justifyContent: 'flex-end' }}>
      <button
        onClick={() => {
          openPdf(docId, dispatch, allActions);
        }}
        className={'btn-signature-pdf'}
      >
        <ContractsIcon
          fill="#fff"
          stroke="#65686B"
          className={classes.tabImage}
        />
        PDF
      </button>
    </div>
  );
};

export default Pdf;
