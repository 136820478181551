import { createBrowserHistory } from 'history';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config';
export const scopes = [
  'profile',
  'email',
  'openid',
  'CattleBuyingInformationSystem',
];
const history = createBrowserHistory();
const oktaConfig = {
  tokenManager: {
    storage: 'localStorage',
    secure: true,
    autoRenew: true,
    // expireEarlySeconds: 3540, // causes token to expire after 1 minute after issued
  },
  history,
  clientId: config.okta.clientId,
  issuer: config.okta.issuer,
  redirectUri: window.location.origin + '/callback',
  scopes,
  postLogoutRedirectUri: config.okta.postLogoutRedirectUri,
};
export const oktaAuth = new OktaAuth(oktaConfig);
export const authClient = new OktaAuth(oktaConfig);
authClient.start();

const getOriginalUri = () => {
  if (!window.location.href.includes('/callback')) {
    const originallUri = window.location.href.includes('localhost')
      ? window.location.href
      : window.location.href.replace('http://', 'https://');

    return originallUri;
  } else {
    return window.location.href;
  }
};
export const loginWithRedirect = () => {
  // window.localStorage.setItem('loginRedirect', window.location.href);

  // authClient.token.getWithRedirect(scopes);
  const originalUri = getOriginalUri();
  console.log('loginWithRedirect', originalUri);
  oktaAuth.setOriginalUri(originalUri);
  oktaAuth.signInWithRedirect();
};

authClient.tokenManager.on('expired', function (e) {
  console.log('token expired ', e);
});

authClient.tokenManager.on('renewed', function (e) {
  console.log('token renewed ', e);
});

export default authClient;
