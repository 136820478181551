import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const disableClearable = true;

const getOptionLabel = ({ label }) => label;

const renderInput = params => (
  <TextField
    {...params}
    variant="standard"
    size="small"
    style={{
      paddingTop: '5px',
    }}
  />
);

const className = 'input-bkgrd';

const style = {
  width: '100%',
  fontSize: 15,
  paddingLeft: 5,
  fontFamily: 'Proxima Nova',
};

const defaultProps = {
  getOptionLabel,
  disableClearable,
  renderInput,
  className,
  style,
};

export default props => <Autocomplete {...{ ...defaultProps, ...props }} />;
