import React, { useState } from 'react';
import { styles } from './Styles';

export default function Calendar(props) {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const [year, setYear] = useState(new Date().getFullYear());
  const { selectedMonthYear, changeMonthYear } = props;

  const changeYear = type => {
    if (type === 'increment') {
      setYear(year + 1);
    } else if (type === 'decrement') {
      setYear(year - 1);
    } else if (type === 'reset') {
      setYear(new Date().getFullYear());
    }
  };

  const selectDeselect = month => {
    const string = `${month}${[year % 100]}`;
    const arr = [...selectedMonthYear];
    if (arr.includes(string)) {
      arr.splice(arr.indexOf(string), 1);
    } else {
      arr.push(string);
    }
    changeMonthYear(arr);
  };

  const checkSelectedOrNot = month => {
    return selectedMonthYear.includes(`${month}${[year % 100]}`);
  };

  const unselected = 'calendar-box-unselected';

  function RenderMonths(row) {
    const lines = [];
    for (let i = 0 + 4 * row; i < 4 + 4 * row; i++) {
      lines.push(
        <button
          className={
            checkSelectedOrNot(months[i]) ? 'calendar-box-selected' : unselected
          }
          style={{ fontFamily: 'Proxima Nova' }}
          onClick={() => selectDeselect(months[i])}
        >
          {months[i]}
        </button>,
      );
    }
    return lines;
  }

  function RenderCalendar() {
    const lines = [];
    for (let i = 0; i < 3; i++) {
      lines.push(
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            marginTop: 10,
          }}
        >
          {RenderMonths(i)}
        </div>,
      );
    }
    return lines;
  }

  return (
    <div
      style={{
        margin: 10,
        paddingTop: 0.1,
        display: props.hideMonths ? 'none' : 'block',
      }}
    >
      <div
        style={{
          width: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          marginTop: 10,
        }}
      >
        <button className={unselected} onClick={() => changeYear('decrement')}>
          <div className={'arrow-left'}></div>
        </button>
        <button className={unselected}>{year}</button>
        <button className={unselected} onClick={() => changeYear('increment')}>
          <div className={'arrow-right'}></div>
        </button>
      </div>
      {RenderCalendar()}
      <div
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          marginTop: 10,
        }}
      >
        <button
          className={unselected}
          style={styles.calendarFilterFooter}
          onClick={() => changeMonthYear([])}
        >
          Clear
        </button>
        <button
          style={styles.calendarDoneFooter}
          onClick={() => {
            props.toggleMonths(true);
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
}
