import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import Divider from '@material-ui/core/Divider';
import { isMobile } from 'react-device-detect';
import './Home.css';
const UpcomingComp = props => {
  return (
    <Row style={{ marginTop: 14 }}>
      <Col xs={2} style={{ marginLeft: 20 }}>
        <Label
          className={
            isMobile ? 'upcoming-date-mobile' : 'upcoming-date-browser'
          }
        >
          {props.date}
        </Label>
        <Label
          className={
            props.tomorrow
              ? 'upcoming-tomorrow-text'
              : 'upcoming-tomorrow-text-hidden'
          }
        >
          Tomorrow
        </Label>
      </Col>
      <Col xs={1} style={{ margin: 2 }}>
        <Divider orientation="vertical" />
      </Col>
      <Col
        style={{
          alignItems: 'center',
          justifyContent: 'left',
          display: 'flex',
        }}
      >
        <Label className={'upcoming-update-text'}>{props.update}</Label>
      </Col>
    </Row>
  );
};

export default UpcomingComp;
