const drawerWidth = 240;
const fontNova = 'Proxima Nova';
const fontNovaBold = 'ProximaNova-Semibold';
const mainDrawerStyles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  toolbarMargin: theme.mixins.toolbar,
  GMText: {
    fontFamily: fontNova,
    fontSize: 13,
    color: '#002554',
    marginLeft: 10,
    marginTop: 50,
    textAlign: 'end',
    width: '100%',
    marginRight: 15,
  },
  mainMenuListItemBottom: {
    fontFamily: fontNova,
    fontSize: 13,
    color: '#65686B',
    marginLeft: 10,
    textAlign: 'end',
    width: '100%',
    marginRight: 15,
    marginTop: -5,
  },
  mainMenuListItem: {
    fontFamily: fontNova,
    fontSize: 13,
    color: '#65686B',
    textAlign: 'end',
    width: '100%',
    marginRight: 35,
  },
});

const settingsDrawerStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginRight: 0,
  },
  menuButtonMobile: {
    marginTop: 20,
    marginLeft: 10,
  },
  title: {
    flexGrow: 1,
    fontSize: 13,
    marginLeft: 5,
    marginTop: 15,
    fontFamily: fontNova,
    color: '#65686B',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  formControl: {
    margin: theme.spacing(5),
  },
  toolbarMargin: theme.mixins.toolbar,
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1,
  },
  label: { fontFamily: fontNova, fontSize: 16, color: '#65686B' },
  checkbox: { marginLeft: 10, marginRight: 10 },
  homeTitle: {
    fontFamily: fontNova,
    fontSize: 16,
    color: '#65686B',
    marginLeft: 20,
    width: '100%',
  },
  drawerTitles: {
    fontFamily: fontNova,
    fontSize: 16,
    color: '#65686B',
    width: '100%',
  },
  location: {
    fontSize: 13,
    color: '#65686B',
    textDecoration: 'underline',
    marginLeft: 10,
    marginTop: 15,
    fontFamily: fontNovaBold,
  },
  GMText: {
    fontFamily: fontNova,
    fontSize: 13,
    color: '#002554',
    marginLeft: 10,
    marginTop: 50,
    textAlign: 'end',
    width: '100%',
    marginRight: 15,
  },
  divider: { fontSize: 13, color: '#65686B', marginLeft: 10, marginTop: 15 },
  location_mobile: {
    fontSize: 13,
    color: '#65686B',
    textDecoration: 'underline',
    textAlign: 'center',
    fontFamily: fontNovaBold,
  },
  mainMenuListItemBottom: {
    fontFamily: fontNova,
    fontSize: 13,
    color: '#65686B',
    marginLeft: 10,
    textAlign: 'end',
    width: '100%',
    marginRight: 15,
  },
  mainMenuListItem: {
    fontFamily: fontNova,
    fontSize: 13,
    color: '#65686B',
    textAlign: 'end',
    width: '100%',
    marginRight: 35,
  },
});

const compStyles = {
  settingsLabel: {
    fontFamily: fontNovaBold,
    fontWeight: 'bold',
    fontSize: 19,
    color: '#002554',
    marginLeft: 10,
    marginTop: 50,
    textAlign: 'end',
    width: '100%',
    marginRight: 15,
  },
  footerDivider: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    marginBottom: 15,
    marginTop: 50,
  },
  dividerLine: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 5,
  },
  footerLabel: {
    fontFamily: fontNova,
    fontSize: 10,
    color: '#65686B',
    textAlign: 'center',
    width: '100%',
  },
  list: {
    position: 'relative',
  },
  settingsListItem: {
    height: 82,
    backgroundColor: '#eaeaea',
  },
  backIcon: {
    color: '#65686B',
    marginTop: 50,
  },
  homeTitle: {
    marginLeft: 20,
    marginTop: 15,
  },
  checkboxDiv: {
    marginLeft: 40,
    width: '100%',
  },
};

const toolbarStyles = theme => ({
  menuButton: {
    marginRight: 0,
  },
  title: {
    flexGrow: 1,
    fontSize: 13,
    marginLeft: 5,
    marginTop: 15,
    fontFamily: fontNova,
    color: '#65686B',
  },
  location: {
    fontSize: 13,
    color: '#65686B',
    textDecoration: 'underline',
    marginLeft: 10,
    marginTop: 15,
    fontFamily: fontNovaBold,
  },
  divider: {
    fontSize: 13,
    color: '#65686B',
    marginLeft: 10,
    marginTop: 15,
  },
});

export { mainDrawerStyles, settingsDrawerStyles, toolbarStyles, compStyles };
