import React from 'react';
import HomeIcon from '../../../images/Home.png';
import { Grid } from '@material-ui/core';
import { ReactComponent as HomeSVG } from '../../../images/Home.svg';
import { BottomAntTabs, BottomAntTab, useStylesBottom } from './Styles';
import '../../Desktop/Home/Home.css';
import TabNavLink from '../../Desktop/Home/TabNavLink';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useDispatch } from 'react-redux';
import allActions from '../../../redux/actions';
import auth from '../../../auth';
import { Security } from '@okta/okta-react';

export default function BottomTabsContract(props) {
  const classes = useStylesBottom();
  const dispatch = useDispatch();

  const tabsListContracts = [
    {
      label: 'Home',
      route: 'Home',
      icon: HomeIcon,
      svg: true,
      svgIcon: HomeSVG,
    },
    {
      label: 'Approval Needed',
      route: 'Contracts/ApprovalNeeded',
      svg: false,
    },
    {
      label: 'Signature Pending',
      route: 'Contracts/SignaturePending',
      svg: false,
    },
    {
      label: 'Completed',
      route: 'Contracts/Completed',
      svg: false,
    },
  ];

  function classesTab(label) {
    if (label === 'Home') {
      return classes.tabLabelHome;
    } else {
      return label === 'Completed'
        ? classes.tabLabelCompleted
        : classes.tabLabel;
    }
  }

  return (
    <BottomAntTabs
      value={0}
      showLabels
      style={{ justifyContent: 'space-evenly' }}
    >
      {tabsListContracts.map((tab, index) => {
        return (
          <Security auth={auth}>
            <TabNavLink
              to={`/${tab.route}`}
              activeClassName={classes.antTabActive}
              inactiveClassName={classes.antTabInactive}
              key={index}
              style={{ maxWidth: '25%' }}
            >
              {({ isActive }) => (
                <BottomAntTab
                  className={classes.antTabEnabled}
                  label={
                    <Grid container>
                      <span
                        className={classesTab(tab.label)}
                        style={
                          isActive ? { color: 'white' } : { color: '#002554' }
                        }
                      >
                        {tab.label}
                      </span>
                      <div
                        className={
                          props.arraySize[index] > 0 ? 'red-dot-mobile' : null
                        }
                      />
                    </Grid>
                  }
                  icon={
                    tab.svg ? (
                      <tab.svgIcon
                        fill={'#002554'}
                        className={classes.tabImage}
                      />
                    ) : null
                  }
                />
              )}
            </TabNavLink>
          </Security>
        );
      })}
      <BottomAntTab
        className={classes.antTabEnabled}
        label={
          <span className={classes.tabLabel} style={{ color: '#002554' }}>
            More
          </span>
        }
        onClick={() =>
          dispatch(
            allActions.settingActions.setSetting({
              MainDrawerOpen: true,
            }),
          )
        }
        icon={<MoreHoriz className={classes.tabImage} />}
      />
    </BottomAntTabs>
  );
}
