import React from 'react';
import { Row } from 'reactstrap';
import ContractField from './ContractField';
import ContractsSubSectionTitle from './ContractsGroupTitle';
import PropTypes from 'prop-types';
import isValidArray from '../../../utils/isValidArray';
import './Contracts.css';
const ContractSection = ({ section, number }) => (
  <div className="contract-section">
    <ContractsSubSectionTitle
      title={(number ? `${number}. ` : '') + section.header}
      required={false}
    />
    {isValidArray(section.fields)
      ? section.fields.map(field => (
          <Row>
            <ContractField field={field} />
          </Row>
        ))
      : null}
  </div>
);
ContractSection.propTypes = {
  section: PropTypes.shape({
    header: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf({
      name: PropTypes.string.isRequired,
      textField: PropTypes.bool,
      radioOptions: PropTypes.bool,
      value: PropTypes.string,
      options: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    }),
  }),
  number: PropTypes.number,
};
export default ContractSection;
