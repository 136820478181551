import * as actionTypes from './actionTypes';

const setLoader = loader => {
  return {
    type: actionTypes.GET_LOADER,
    loader: loader,
  };
};

const getLoaderRedux = bool => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoader(bool));
      resolve(bool);
    });
  };
};

export default {
  getLoaderRedux,
};
