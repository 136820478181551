import * as actionTypes from '../actions/actionTypes';
const initialState = {
  supplierArr: [],
  completedContracts: [],
  pendingContracts: [],
  approvalContracts: [],
  exceptionContracts: [],
};

const contractManagement = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPLETED_CONTRACTS:
      return {
        ...state,
        completedContracts: action.completedContracts,
      };
    case actionTypes.GET_PENDING_CONTRACTS:
      return {
        ...state,
        pendingContracts: action.pendingContracts,
      };
    case actionTypes.GET_APPROVAL_CONTRACTS:
      return {
        ...state,
        approvalContracts: action.approvalContracts,
      };
    case actionTypes.GET_EXCEPTION_CONTRACTS:
      return {
        ...state,
        exceptionContracts: action.exceptionContracts,
      };
    default:
      return state;
  }
};

export default contractManagement;
