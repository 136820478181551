import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { Row, Col, Label, Modal, ModalBody } from 'reactstrap';
import Calendar from '../../Desktop/Calendar/Calendar';
import SuppliersDatatable from '../../Desktop/Contracts/SuppliersDatatable';
import { styles } from '../../Desktop/Contracts/Styles';
import { cancelicon, filtericon, removeicon, searchicon } from './';

export default function FilterModal(props) {
  const allSuppliersText = 'All Suppliers';
  const currAndNextDelPeriodText = 'Current & Next Del Period';
  const [selectedDelPeriod, setDelPeriod] = useState(currAndNextDelPeriodText);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState([]);
  const [showSuppliers, setShowSuppliers] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState(allSuppliersText);
  const [hideMonths, toggleMonths] = useState(false);
  const classNameTitle = 'title-bold';

  return (
    <Modal
      isOpen={props.filterModal}
      toggle={props.togglefilterModal}
      size="lg"
      centered={true}
      style={styles.modal}
      contentClassName={styles.contentModal}
    >
      <ModalBody>
        <Label className={classNameTitle} style={styles.mobileEditFilterLabel}>
          <img src={filtericon} alt="filter" style={{ marginRight: 5 }} />
          Edit Filters:
        </Label>
        <img
          src={cancelicon}
          alt="cancel"
          onClick={() => {
            props.togglefilterModal();
          }}
          style={{ float: 'right', marginTop: -15 }}
        />
        <div>
          <Row>
            <Col>
              <div>
                <Label className={classNameTitle}>Buyer:</Label>
                <select
                  className="search_categories-mobile"
                  id="search_categories"
                >
                  <option value="1" selected="selected">
                    120-Ellis, Jarod
                  </option>
                  <option value="2">120-Ellis, Jarod 2</option>
                  <option value="3">120-Ellis, Jarod 3</option>
                  <option value="4">120-Ellis, Jarod 4</option>
                </select>
              </div>
              <Label className={classNameTitle} style={{ paddingRight: 10 }}>
                Del Period:
              </Label>
              <input
                type="radio"
                id="Current & Next Del Period"
                name="delPeriod"
                value="Current & Next Del Period"
                checked={selectedDelPeriod === currAndNextDelPeriodText}
                onChange={() => {
                  setShowCalendar(false);
                  toggleMonths(false);
                  setDelPeriod(currAndNextDelPeriodText);
                }}
              />
              <label for="Current & Next Del Period">
                Current & Next Del Period
              </label>
              <div style={{ paddingLeft: 88 }}>
                <input
                  style={{ paddingLeft: 5 }}
                  id="Select Month"
                  type="radio"
                  name="delPeriod"
                  value="Select Month"
                  checked={selectedDelPeriod !== currAndNextDelPeriodText}
                  onChange={() => {
                    setShowCalendar(true);
                    setDelPeriod('Selected Months');
                  }}
                />
                <label for="Select Month">Select Month</label>
              </div>
              <div style={{ paddingLeft: '105px' }}>
                <label
                  style={{ display: showCalendar ? 'contents' : 'none' }} //asdasd
                  className={'text-regular'}
                >
                  {selectedMonthYear.length} selected :{' '}
                  {selectedMonthYear.map((item, index) => {
                    return [index === 0 ? item : `, ${item}`];
                  })}
                </label>
                <div style={{ display: hideMonths ? '' : 'none' }}>
                  <Link
                    className={classNameTitle}
                    color="gray"
                    onClick={() => {
                      toggleMonths(false);
                    }}
                  >
                    Edit
                  </Link>
                </div>
              </div>
              <div style={{ paddingLeft: 80 }}>
                <div
                  className="calendar-box-filter-completed"
                  style={showCalendar ? null : { display: 'none' }}
                >
                  <Calendar
                    selectedMonthYear={selectedMonthYear}
                    changeMonthYear={item => {
                      setSelectedMonthYear(item);
                      setDelPeriod(`${item.length} selected`);
                    }}
                    hideMonths={hideMonths}
                    toggleMonths={toggleMonths}
                  />
                </div>
              </div>
              <Label className={classNameTitle} style={{ paddingRight: 5 }}>
                Supplier(s):
              </Label>
              <input
                style={{ paddingLeft: 5 }}
                type="radio"
                id="allSuppliers"
                name="suppliers"
                value="All Suppliers"
                checked={selectedSuppliers === allSuppliersText}
                onChange={() => {
                  setShowSuppliers(false);
                  setSelectedSuppliers(allSuppliersText);
                }}
              />
              <label for="allSuppliers">All Suppliers</label>
              <div style={{ paddingLeft: 85 }}>
                <input
                  style={{ marginRight: 5 }}
                  id="singleSupplier"
                  type="radio"
                  name="suppliers"
                  value="Single Supplier"
                  checked={selectedSuppliers !== allSuppliersText}
                  onChange={() => {
                    setShowSuppliers(true);
                    setSelectedSuppliers('Single Supplier');
                  }}
                />
                <label for="singleSupplier">Single Supplier</label>
                <div
                  className="search-supplier-filter-modal"
                  style={
                    showSuppliers
                      ? { width: '93%', display: 'flex' }
                      : { display: 'none' }
                  }
                >
                  <img src={searchicon} alt="lens" style={styles.searchIcon} />
                  <input
                    className={'search-supplier-filter-modal'}
                    placeholder="0000000-Supplier Name XYZ"
                  ></input>
                  <img src={removeicon} alt="remove" />
                </div>
                <div
                  style={
                    showSuppliers
                      ? {
                          marginTop: 5,
                          width: '93%',
                          overflowY: 'scroll',
                          maxHeight: 300,
                        }
                      : { display: 'none' }
                  }
                >
                  {props.supplierArray.map(item => (
                    <SuppliersDatatable data={item} />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
      <div style={styles.modalFooter}>
        <label
          className={'btn-reset-filter-modal'}
          onClick={() => {
            setDelPeriod(currAndNextDelPeriodText);
            setSelectedSuppliers(allSuppliersText);
            setShowSuppliers(false);
            setShowCalendar(false);
            setSelectedMonthYear([]);
            toggleMonths(false);
            setSelectedMonthYear([]);
          }}
        >
          Reset To Default
        </label>
        <label
          className={'btn-done-filter-modal'}
          onClick={() => {
            setShowCalendar(
              selectedDelPeriod !== currAndNextDelPeriodText ? true : false,
            );
            setShowSuppliers(false);
            props.togglefilterModal();
          }}
        >
          Done
        </label>
      </div>
    </Modal>
  );
}
