import * as actionTypes from '../actions/actionTypes';
const initialSettings = {
  DTNInsights: true,
  USDAReports: true,
  AgNews: true,
  Weather: true,
  Upcoming: true,
  MainDrawerOpen: false,
  version: '',
};

const currentSetting = (state = initialSettings, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTING:
      return {
        ...state,
      };
    case actionTypes.SET_SETTING:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_VERSION:
      return {
        ...state,
        version: action.version,
      };
    default:
      return state;
  }
};

export default currentSetting;
