import api from './../index';

export const getSupplier = async buyerNum => {
  const res = await api();
  const tokenInfo = JSON.parse(
    window.localStorage.getItem('okta-token-storage'),
  ).idToken.idToken;
  return res
    .get(`harvested/getHarvestedSuppliers/${buyerNum},${tokenInfo} `)
    .then(res => {
      return res.data;
    });
};

export const allSuppliers = { id: 'ALL', label: 'All Suppliers' };

export const getSupplierOptions = async buyerNum => {
  const suppliers = await getSupplier(buyerNum);
  const options = suppliers.map(supplier => {
    const {
      SUPNUMBER: supplierNumber,
      SUPFIRSTNAME: firstName,
      SUPLASTNAME: lastName,
    } = supplier;

    const id = supplierNumber.trim();
    const label = `${id} - ${firstName.trim()} ${lastName.trim()}`;

    return { id, label };
  });
  return [{ id: 'ALL', label: 'All Suppliers' }, ...options];
};
