import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import isValidArray from '../../../utils/isValidArray';
import { isBrowser } from 'react-device-detect';
import './Contracts.css';

const ContractField = ({ field }) => {
  return (
    <>
      <Col style={{ textAlign: 'end' }} xs={isBrowser ? 4 : 6}>
        <span className={isBrowser ? 'sub-category' : 'sub-category-sm'}>
          {field.name}
        </span>
      </Col>
      {field.textField ? (
        <Col xs={isBrowser ? 8 : 6}>
          <div
            className={
              isBrowser
                ? 'contract-field-textarea'
                : 'contract-field-textarea-sm'
            }
          >
            {field.value === ''
              ? '\u00A0' // adds no-break space character
              : field.value === undefined
              ? '\u00A0' //adds no-break space character
              : field.value}
          </div>
        </Col>
      ) : field.radioOptions ? (
        <Col>
          <Row className="row-margin">
            {isValidArray(field.options)
              ? field.options.map((option, j) => (
                  <Col xs={12}>
                    <input
                      type="radio"
                      defaultChecked={field.value === option.id}
                      id={option.id}
                      value={option.name}
                      name="contractType"
                      disabled={true}
                    />
                    <label
                      className={'text-regular'}
                      htmlFor={option.name}
                      style={{ marginLeft: 5 }}
                    >
                      {option.name}
                    </label>
                  </Col>
                ))
              : null}
          </Row>
        </Col>
      ) : null}
    </>
  );
};
ContractField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    textField: PropTypes.bool,
    radioOptions: PropTypes.bool,
    value: PropTypes.string.isRequired,
    options: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default ContractField;
