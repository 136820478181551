import React from 'react';

import loading from '../../../images/loading.gif';
import './loader.css';

const Loader = ({ dispatch, loader }) => {
  return (
    <div className="loaderStyle" style={{ display: loader.loader }}>
      <img alt="" src={loading} height="100vh" />
    </div>
  );
};

export default Loader;
