import React, { Fragment } from 'react';
import {
  Grid,
  withStyles,
  Badge,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { isMobile } from 'react-device-detect';
import logo from '../../../images/logo.png';
import logoMobile from '../../../images/TysonMobile.png';
import Cloudy from '../../../images/cloudy.png';
import { Label } from 'reactstrap';
import { toolbarStyles as styles } from './Styles';
import { MdPerson } from 'react-icons/md';
import './ToolbarAndDrawer.css';
const showUnfinished = false;

function showUnfinishedContent(classes, userName) {
  return showUnfinished ? (
    <>
      <Label color="inherit" className={classes.location}>
        {/* To be changed later based on user location */}
        Dakota City, IA{' '}
      </Label>
      <Label className={classes.divider}>|</Label>
      <img alt="" src={Cloudy} className={'icon-weather-toolbar'} />
      <Label className={classes.title}>67F</Label>
      <IconButton>
        <Badge badgeContent={4} color="default">
          <NotificationsIcon style={{ float: 'right' }} />
        </Badge>
      </IconButton>
    </>
  ) : (
    <Grid
      style={{
        flexDirection: 'row',
        justifyContent: 'flexEnd',
        marginLeft: 'auto',
      }}
    >
      <span>
        <MdPerson></MdPerson>
      </span>
      <Label>{userName}</Label>
    </Grid>
  );
}

const MyToolbar = withStyles(styles)(
  ({ classes, title, onMenuClick, userName }) => (
    <Fragment>
      <Toolbar className={isMobile ? 'toolbar-mobile' : 'toolbar-desktop'}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
        {isMobile ? (
          <div className="toolbar-mobile-div">
            <Label className={'CBIS-title-mobile'}>
              {'TYSON CBIS '}
              <img src={logoMobile} alt="Logo" className="logo-mobile" />
            </Label>
          </div>
        ) : (
          <>
            <Label className={'CBIS-title'}>CBIS</Label>
            <img src={logo} alt="Logo" className={'CBIS-logo'} />
            {showUnfinishedContent(classes, userName)}
          </>
        )}
      </Toolbar>
    </Fragment>
  ),
);

export default withStyles(styles)(MyToolbar);
